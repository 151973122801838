import React, { useContext } from 'react';
import { TextField } from '@material-ui/core';
import _ from 'lodash';

import { FieldProps } from './FieldProps';
import FormContext from '../FormContext';

const Text = (props: FieldProps) => {
  const { id, entryField, disabled, metadata } = props;
  const { formData, setFormData } = useContext(FormContext);

  const doChange = _.debounce((value: string) => {
    setFormData!({
      ...formData,
      [id]: {
        valid: value.length > 0,
        value,
      },
    });
  }, 300);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    doChange(value);
  };

  return (
    <TextField
      required={false}
      onChange={handleChange}
      defaultValue={entryField ? entryField.value : ''}
      disabled={disabled}
      placeholder={metadata.placeholder || ''}
      type={metadata.type || 'text'}
      fullWidth
    />
  );
};

export default Text;
