import React, { ReactNode, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Theme,
} from '@material-ui/core';
import { colors } from '../config/theme';

interface StyledAccordionProps {
  id: string;
  summary: ReactNode;
  summaryClassName?: string;
  children: ReactNode;
  expanded?: boolean;
  expandable?: boolean;
  onChange?: (event: React.ChangeEvent<{}>, expanded: boolean) => void;
  chevron?: boolean;
  noPadding?: boolean;
  noShadow?: boolean;
  size?: 'md' | 'sm';
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    '&:not(:last-child)': {
      marginBottom: theme.spacing(2),
    },
  },
  sizeSmall: {
    '& $summary': {
      padding: `0 ${theme.spacing(1)}px`,
      minHeight: 32,
    },
    '& .MuiAccordionSummary-content': {
      margin: `${theme.spacing(1)}px 0`,
    },
  },
  summary: {
    background: colors.primary.grey,
    '&[aria-expanded="true"]': {
      color: theme.palette.primary.contrastText,
      background: theme.palette.primary.main,
      '& $chevron': {
        transform: 'rotate(-180deg)',
      },
      '& $finishedIcon': {
        color: theme.palette.primary.contrastText,
      },
      '& $levelBarContainer': {
        bottom: -20,
      },
      '& .MuiButtonBase-root, .MuiButtonBase-root.Mui-disabled': {
        color: theme.palette.primary.contrastText,
      },
      '& $levelBar': {
        background: theme.palette.common.white,
      },
      '& $icon': {
        color: theme.palette.primary.contrastText,
      },
    },
  },
  summaryNoExpand: {
    '&:hover': {
      cursor: 'default !important',
    },
  },
  chevronContainer: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: theme.spacing(1),
  },
  chevron: {
    transition: theme.transitions.create('transform'),
  },
  chevronInactive: {
    opacity: 0.3,
  },
  icon: {
    marginLeft: theme.spacing(1),
  },
  defaultPadding: {
    padding: theme.spacing(2),
  },
  noPadding: {
    padding: 0,
  },
  noShadow: {
    boxShadow: 'none',
  },
}));

const StyledAccordion = (props: StyledAccordionProps) => {
  const classes = useStyles();
  const {
    id,
    summary,
    children,
    summaryClassName,
    expanded,
    onChange,
    chevron,
    expandable,
    noPadding,
    noShadow,
    size,
  } = props;

  const [expandedState, setExpandedState] = useState<boolean>(false);

  const rootClasses = [classes.root];

  if (size === 'sm') {
    rootClasses.push(classes.sizeSmall);
  }
  if (noShadow) {
    rootClasses.push(classes.noShadow);
  }

  const handleChange = (event: React.ChangeEvent<{}>, expanded: boolean) => {
    if (expandable === undefined ? true : expandable) {
      setExpandedState(!expandedState);

      if (onChange) {
        onChange(event, expanded);
      }
    }
  };

  return (
    <Accordion
      expanded={expanded !== undefined ? expanded : expandedState}
      onChange={handleChange}
      className={rootClasses.join(' ')}
    >
      <AccordionSummary
        aria-controls={id}
        className={`${classes.summary} ${summaryClassName || ''} ${
          !expandable ? classes.summaryNoExpand : ''
        }`}
      >
        {summary}
        {chevron && (
          <div className={classes.chevronContainer}>
            <FontAwesomeIcon
              icon={['fal', 'chevron-down']}
              className={`${classes.icon} ${classes.chevron} ${
                (expandable === undefined ? false : !expandable) &&
                classes.chevronInactive
              }`}
            />
          </div>
        )}
      </AccordionSummary>
      <AccordionDetails
        className={noPadding ? classes.noPadding : classes.defaultPadding}
      >
        {children}
      </AccordionDetails>
    </Accordion>
  );
};

export default StyledAccordion;
