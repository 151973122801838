import React, { useContext, useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Chip,
  Theme,
} from '@material-ui/core';
import { getFileURL, stripHtmlTags } from '../../../utils/common';

import { colors } from '../../../config/theme';
import { File } from '../../../types';
import AppContext from '../../../AppContext';
import SafeHtml from '../../../components/SafeHtml';

export type NewsItemProps = {
  content: string;
  id: string;
  image: File | null;
  onClick: (id: string) => void;
  published: boolean;
  publishedStart: string;
  title: string;
  read: boolean;
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(5),
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
    },
  },
  contentBox: {
    wordBreak: 'break-word',
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(2),
    },
  },
  chip: {
    borderRadius: 3,
    color: theme.palette.common.white,
  },
  date: {
    textTransform: 'uppercase',
    marginBottom: 5,
  },
  title: {
    fontWeight: 'bold',
    fontSize: '1.2rem',
    marginTop: 0,
    [theme.breakpoints.up('md')]: {
      fontSize: '1.5rem',
    },
  },
  media: {
    maxHeight: 150,
    [theme.breakpoints.up('md')]: {
      maxHeight: 300,
      order: 1,
      width: '100%',
      maxWidth: '40%',
    },
  },
}));

const NewsItem = (props: NewsItemProps) => {
  const classes = useStyles();
  const { roleViewManager } = useContext(AppContext);
  const {
    id,
    title,
    content,
    image,
    onClick,
    published,
    publishedStart,
    read,
  } = props;

  /**
   * TODO: get proper excerpt from the api
   */

  // Create a short plain text excerpt from the HTML content.
  const excerpt = useMemo(() => {
    let text = content
      .replaceAll('&nbsp;', ' ') // Replace non-breaking spaces.
      .replaceAll('<br>', ' ') // Replace HTML line breaks.
      .replaceAll('><', '> <'); // Add spaces between HTML tags.

    text = stripHtmlTags(text) // Remove HTML tags.
      .replace(/ +/g, ' ') // Remove consecutive spaces.
      .substring(0, 130);

    return `${text}...`;
  }, [content]);

  return (
    <Card onClick={() => onClick(id)} className={classes.root}>
      {image && (
        <CardMedia
          className={classes.media}
          component="img"
          alt=""
          image={getFileURL(image)}
          title=""
        />
      )}
      <CardContent>
        <Box className={classes.contentBox}>
          {roleViewManager.hasRole([
            'ROLE_ADMIN',
            'ROLE_PROGRAM_ASSISTANT',
          ]) && (
            <Chip
              className={classes.chip}
              size="small"
              style={{
                backgroundColor: published
                  ? colors.secondary.green
                  : colors.secondary.orange,
              }}
              label={published ? 'Gepubliceerd' : 'Concept'}
            />
          )}
          {!read &&
            !roleViewManager.hasRole([
              'ROLE_ADMIN',
              'ROLE_PROGRAM_ASSISTANT',
            ]) && (
              <Chip
                className={classes.chip}
                size="small"
                style={{
                  backgroundColor: colors.secondary.orange,
                }}
                label="Nieuw"
              />
            )}
          <p className={classes.date}>
            {' '}
            {moment(publishedStart).format('D MMMM')}
          </p>
          <h2 className={classes.title}>{title}</h2>
          <SafeHtml html={excerpt} />
        </Box>
      </CardContent>
    </Card>
  );
};

export default NewsItem;
