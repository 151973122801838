import React from 'react';
import TextMediaHeader from '../../components/pageheader/TextMedia';

const ELearningOverview = () => {
  return (
    <div>
      <TextMediaHeader name="Gratis e-learnings">
        <p>
          Welkom bij MijnNSPOH! Gebruik de link in het menu om aan de slag te
          gaan met de gratis e-learnings.
        </p>
      </TextMediaHeader>
    </div>
  );
};

export default ELearningOverview;
