import React from 'react';
import { Dialog, DialogContent, DialogTitle, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import ProfessionalActivityLevels from './ProfessionalActivityLevels';
import ProfessionalActivityForm from './ProfessionalActivityForm';
import { EPAModule } from '../../../types';
import DialogCloseButton from '../../../components/DialogCloseButton';

interface ProfessionalActivityDialogProps {
  module: EPAModule;
  onClose: () => void;
  onSubmit: () => void;
  open: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  formContainer: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1),
    padding: theme.spacing(2),
    background: theme.palette.common.white,
  },
  dialogTitle: {
    paddingRight: 65,
  },
}));

const ProfessionalActivityDialog = (props: ProfessionalActivityDialogProps) => {
  const classes = useStyles();
  const { onClose, onSubmit, open, module } = props;

  return (
    <Dialog
      onClose={onClose}
      aria-labelledby="professional-activities-form"
      open={open}
      maxWidth="lg"
      disableBackdropClick
      disableEscapeKeyDown
    >
      <DialogTitle
        id="professional-activities-form"
        className={classes.dialogTitle}
      >
        {module.publishedName}
        <DialogCloseButton onClose={onClose} />
      </DialogTitle>
      <DialogContent>
        <ProfessionalActivityLevels module={module} />
        <div className={classes.formContainer}>
          <ProfessionalActivityForm module={module} onSubmit={onSubmit} />
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default ProfessionalActivityDialog;
