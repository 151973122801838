import React from 'react';
import { Box, Theme, Typography } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { makeStyles } from '@material-ui/styles';
import { red } from '@material-ui/core/colors';

import fields from '../fields';
import { FormFieldInterface } from '../FormField';
import FormFieldRoles from './FormFieldRoles';
import SafeHtml from '../../../components/SafeHtml';
import { FormDataViolation } from '../../../types';

interface FieldStartProps {
  formField: FormFieldInterface;
  violation?: FormDataViolation;
}

const useStyles = makeStyles((theme: Theme) => ({
  violationBox: {
    color: red[300],
  },
  violationMessage: {
    marginLeft: theme.spacing(1),
  },
  fieldDescription: {
    fontStyle: 'italic',
    overflowX: 'auto',
    maxWidth: '100%',
    '& > ul': {
      paddingLeft: theme.spacing(2),
    },
  },
}));

const FieldStart = (props: FieldStartProps) => {
  const { formField, violation } = props;
  const classes = useStyles();
  const field = fields.find((f) => f.type === formField.type);

  if (!field) {
    return <div />;
  }

  return (
    <>
      {violation && (
        <div className={classes.violationBox}>
          <Box display="flex" alignItems="center">
            <FontAwesomeIcon icon={['fal', 'exclamation-circle']} />
            <Typography className={classes.violationMessage}>
              {violation.message}
            </Typography>
          </Box>
        </div>
      )}
      <FormFieldRoles field={formField} />
      {(field.hasDescription !== undefined ? field.hasDescription : true) &&
        formField.description.length > 0 && (
          <SafeHtml
            html={formField.description}
            className={classes.fieldDescription}
          />
        )}
    </>
  );
};

export default FieldStart;
