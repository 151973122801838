import React, { useCallback, useEffect, useState } from 'react';
import { Button, Grid, InputLabel, Paper, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { colors } from '../../config/theme';
import placeholder from '../../assets/images/image_library.png';
import ImageSuggestSearch from './ImageSuggestSearch';

interface ImageSuggestProps {
  onChangeManual: (files: File) => void;
  onChangeUrl: (imageUrl: string) => void;
  imageUrl: string | null;
}

const useStyles = makeStyles((theme: Theme) => ({
  description: {
    marginBottom: theme.spacing(2),
    fontSize: 12,
  },
  input: {
    cursor: 'pointer',
    position: 'relative',
    zIndex: 1,
    opacity: 0,
    height: '100%',
    width: '100%',
    padding: 0,
    textAlign: 'center',
    background: 'none',
    border: 'none',
  },
  image: {
    position: 'relative',
    width: '100%',
    height: 150,
    marginBottom: theme.spacing(1),
    border: `2px solid transparent`,
    borderRadius: 3,
    background: theme.palette.common.white,
    overflow: 'hidden',

    '&:hover .image-overlay': {
      color: theme.palette.primary.light,
      borderColor: theme.palette.primary.light,
    },
  },
  imageActive: {
    borderColor: theme.palette.primary.main,
  },
  imageUnsplash: {
    cursor: 'pointer',
    height: '100%',
    width: '100%',
    background: `url(${placeholder})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    opacity: 0.7,

    '&:hover': {
      opacity: 1,
    },
  },
  imageIcon: {
    marginRight: theme.spacing(1),
  },
  imageOverlay: {
    position: 'absolute',
    top: 10,
    left: 10,
    right: 10,
    bottom: 10,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    border: '1px dashed #e6e6e6',
    borderColor: colors.secondary.grey,
  },
  imageDescription: {
    fontSize: 12,
  },
  imageContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  spacer: {
    fontWeight: 'bold',
    textAlign: 'center',
    alignSelf: 'center',
    display: 'flex',
    justifyContent: 'center',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  title: {
    marginBottom: 0,
    fontWeight: 'bold',
  },
  wrapper: {
    display: 'flex',
    alignItems: 'center',
  },
}));

const ImageSuggest = (props: ImageSuggestProps) => {
  const { onChangeManual, onChangeUrl, imageUrl } = props;
  const classes = useStyles();
  const [value, setValue] = useState('');
  const [image, setImage] = useState<File>();
  const [activeChoice, setActiveChoice] = useState<string>('');

  useEffect(() => {
    if (image) {
      onChangeManual(image);
    }
    onChangeUrl('');
  }, [image]);

  /**
   * Handle removing manual image.
   */
  const handleRemoveManual = useCallback(() => {
    setValue('');
    setImage(undefined);
  }, [setValue, setImage]);

  /**
   * Handle changing manual image.
   */
  const handleChangeManual = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target.files?.length && e.target.files[0]) {
        setValue(e.target.files[0].name);
        setImage(e.target.files[0]);
      }
    },
    [setValue, setImage],
  );

  /**
   * Handle changing image url.
   */
  const handleChangeImageUrl = (imageUrl: string) => {
    onChangeUrl(imageUrl);
    handleRemoveManual();
  };

  return (
    <>
      <p className={classes.title}>Afbeelding:</p>
      <div className={classes.description}>
        Deze foto wordt gebruikt als achtergrond van je post en maakt deze
        mooier en prettiger leesbaar. Bij zelf uploaden geldt: Breedte: 760
        pixels, formaat: jpg / jpeg / gif / png (max. 10 MB) en let op! foto’s
        die je van het internet haalt zijn vaak auteursrechtelijk beschermd.
        Deze foto&apos;s mogen niet op deze website geplaatst worden.
      </div>
      <div className={classes.wrapper}>
        <Grid container alignItems="flex-start" spacing={0}>
          <Grid item xs={12} md={5} className={classes.imageContainer}>
            <Paper
              onClick={() => setActiveChoice('unsplash')}
              className={`${classes.image} ${
                activeChoice === 'unsplash' ? classes.imageActive : ''
              } `}
            >
              {imageUrl ? (
                <img src={imageUrl} alt="" className={classes.imageUnsplash} />
              ) : (
                <div className={classes.imageUnsplash} />
              )}
            </Paper>
            <span className={classes.imageDescription}>
              Zoeken in de fotobibliotheek
            </span>
          </Grid>
          <Grid item xs={12} md={2} className={classes.spacer}>
            of
          </Grid>
          <Grid item xs={12} md={5} className={classes.imageContainer}>
            <Paper
              onClick={() => setActiveChoice('manual')}
              className={`${classes.image} ${
                activeChoice === 'manual' ? classes.imageActive : ''
              } `}
            >
              <div
                className={['image-overlay', classes.imageOverlay].join(' ')}
              >
                {!image && (
                  <InputLabel htmlFor="photo-manual">
                    <FontAwesomeIcon
                      className={classes.imageIcon}
                      icon="upload"
                    />
                    Foto uploaden
                  </InputLabel>
                )}
                {value && (
                  <div>
                    <div>{value}</div>
                    <Button onClick={handleRemoveManual} color="secondary">
                      Verwijderen
                    </Button>
                  </div>
                )}
              </div>
              {!image && (
                <input
                  accept="image/*"
                  className={classes.input}
                  type="file"
                  name="photo-manual"
                  id="photo-manual"
                  onChange={handleChangeManual}
                />
              )}
            </Paper>
            <span className={classes.imageDescription}>
              Upload zelf een foto
            </span>
          </Grid>
          <Grid item xs={12}>
            {activeChoice === 'unsplash' && (
              <ImageSuggestSearch onChange={handleChangeImageUrl} />
            )}
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default ImageSuggest;
