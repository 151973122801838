import React, {
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useHistory } from 'react-router-dom';
import { Box, Button, MenuItem, Select, Theme } from '@material-ui/core';
import { red } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/styles';
import { useSelector } from 'react-redux';
import moment from 'moment';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Form, FormEntry as FormEntryInterface } from './types';
import fields from './fields';
import { CompleteUser, FormDataViolation } from '../../types';
import FormContext from './FormContext';
import { FormContextFilesState, FormContextState } from './Form';
import { FieldState } from './fields/FieldProps';
import { FormFieldInterface } from './FormField';
import { filterFormData, getInitialFormDataForEntry } from './helpers';
import FieldStart from './components/FieldStart';
import Loader from '../../components/Loader';
import StyledAccordion from '../../components/StyledAccordion';
import AppContext from '../../AppContext';
import useExpandableFormFields from './hooks/useExpandableFormFields';

interface FormEntryProps {
  form: Form;
  entries: FormEntryInterface[];
  readOnly?: boolean;
  onFormDataChange?: (
    formData: { [key: string]: FieldState },
    files: FormContextFilesState,
  ) => void;
  onFormEntryChange?: (
    entry: FormEntryInterface,
    mostRecentEntry: boolean,
  ) => void;
  violations?: FormDataViolation[];
}

const useStyles = makeStyles((theme: Theme) => ({
  field: {
    '&:not(:last-child)': {
      marginBottom: theme.spacing(2),
    },
    width: '100%',
  },
  fieldTitle: {
    fontWeight: 700,
    fontSize: 22,
  },
  entrySelect: {
    marginBottom: theme.spacing(2),
  },
  violationMessage: {
    backgroundColor: red[300],
  },
}));

const FormEntry = (props: FormEntryProps) => {
  const history = useHistory();
  const classes = useStyles();
  const {
    form,
    entries,
    readOnly = false,
    onFormDataChange,
    onFormEntryChange,
    violations,
  } = props;
  const account = useSelector(
    (selector: {
      user: {
        account: CompleteUser;
      };
    }) => selector.user.account,
  );
  const [loading, setLoading] = useState<boolean>(true);
  const [formData, setFormData] = useState<FormContextState>({});
  const [files, setFiles] = useState<FormContextFilesState>({});
  const [entry, setEntry] = useState<FormEntryInterface | null>(null);
  const { roleViewManager } = useContext(AppContext);

  const {
    expandedFieldIds,
    setExpandedFieldIds,
    atLeastOneFieldExpanded,
    determineExpandedFieldIds,
    toggleExpanded,
    toggleAllExpanded,
  } = useExpandableFormFields(form);

  const refreshPage = () => {
    history.go(0);
  };

  const formContextValue = useMemo(
    () => ({ formData, setFormData, files, setFiles }),
    [formData, setFormData, files, setFiles],
  );

  const mostRecentEntry = useMemo(
    () =>
      entries.sort((a, b) =>
        new Date(a.created) > new Date(b.created) ? -1 : 1,
      )[0],
    [entry],
  );

  // Initialize form data from entry.
  useEffect(() => {
    if (!mostRecentEntry) {
      setLoading(false);
      return;
    }

    const formData = getInitialFormDataForEntry(
      mostRecentEntry,
      account,
      roleViewManager.isParticipantView(),
    );
    setFormData(formData);

    setExpandedFieldIds(determineExpandedFieldIds(formData));

    setLoading(false);
  }, [mostRecentEntry, form.fields]);

  const handleEntryChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const entry = entries.find((entry) => entry.id === event.target.value)!;
    setEntry(entry);
  };

  useEffect(() => {
    if (!entry) {
      return;
    }

    if (onFormEntryChange) {
      onFormEntryChange(entry, entry.id === mostRecentEntry.id);
    }
  }, [entry]);

  useEffect(() => {
    if (onFormDataChange && entry === mostRecentEntry) {
      onFormDataChange(
        filterFormData(
          form,
          formData,
          account,
          roleViewManager.isParticipantView(),
        ),
        files,
      );
    }
  }, [formData]);

  useEffect(() => {
    setEntry(mostRecentEntry);
  }, [entries]);

  if (loading) {
    return <Loader />;
  }

  const refreshIcon = <FontAwesomeIcon icon={['fal', 'sync']} />;

  const chevronIcon = (
    <FontAwesomeIcon
      icon={['fal', atLeastOneFieldExpanded ? 'chevron-up' : 'chevron-down']}
    />
  );

  return (
    <FormContext.Provider value={formContextValue}>
      {entry && entries.length > 1 && (
        <Select
          value={entry.id}
          onChange={handleEntryChange}
          className={classes.entrySelect}
        >
          {entries
            .sort((a, b) =>
              new Date(a.created) > new Date(b.created) ? -1 : 1,
            )
            .map((entry, index) => (
              <MenuItem key={entry.id} value={entry.id}>
                {moment(entry.created).format('DD-MM-YYYY HH:mm')}
                {index === 0 && ' (Meest recent)'}
              </MenuItem>
            ))}
        </Select>
      )}
      <Box mb={2} display="flex">
        <Button
          onClick={toggleAllExpanded}
          size="small"
          startIcon={chevronIcon}
        >
          {atLeastOneFieldExpanded
            ? 'Alle velden inklappen'
            : 'Alle velden uitklappen'}
        </Button>
        <Button onClick={refreshPage} size="small" startIcon={refreshIcon}>
          Ga terug naar de standaardinstelling
        </Button>
      </Box>
      {entry && (
        <div>
          {(entry ? entry.form : form).fields.map(
            (formField: FormFieldInterface) => {
              const field = fields.find((f) => f.type === formField.type);

              if (!field || !field.field) {
                return <div key={formField.id} />;
              }

              const Field = field.field;
              const entryField =
                entry &&
                entry.fields?.find(
                  (formEntryField) => formEntryField.field.id === formField.id,
                );

              const canWrite =
                !readOnly &&
                Boolean(
                  onFormDataChange &&
                    entry.id === mostRecentEntry.id &&
                    formField.roles &&
                    formField.roles.filter(
                      (role) =>
                        role !== 'ROLE_PARTICIPANT' &&
                        account.roles.includes(role),
                    ).length > 0,
                );

              let fieldDisplay: ReactNode;

              if (canWrite && Field !== undefined) {
                fieldDisplay = (
                  <Field
                    id={formField.id}
                    label={formField.label}
                    description={formField.description}
                    options={formField.options || []}
                    metadata={formField.metadata}
                    entryField={entryField}
                  />
                );
              } else {
                const Display = field.entryDisplay;

                fieldDisplay = (
                  <>
                    {Display !== undefined && entryField && (
                      <Display
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...entryField}
                      />
                    )}
                    {Display === undefined && entryField && entryField.value}
                    {!entryField && Field !== undefined && (
                      <Field
                        id={formField.id}
                        label={formField.label}
                        description={formField.description}
                        options={formField.options || []}
                        metadata={formField.metadata}
                        disabled
                      />
                    )}
                  </>
                );
              }

              const fieldClasses = [classes.field];

              const violation =
                violations &&
                violations.find(
                  (violation) => violation.parameters.fieldId === formField.id,
                );

              const fieldLabel = `${formField.label}${
                formField.required ? '*' : ''
              }`;

              return (
                <StyledAccordion
                  id={`accordion-${formField.id}`}
                  summary={<Box width="100%">{fieldLabel}</Box>}
                  summaryClassName={
                    violation ? classes.violationMessage : undefined
                  }
                  expanded={expandedFieldIds.includes(formField.id)}
                  expandable
                  onChange={() => toggleExpanded(formField.id)}
                  chevron
                  size="sm"
                >
                  <div key={formField.id} className={fieldClasses.join(' ')}>
                    <FieldStart formField={formField} violation={violation} />
                    {fieldDisplay}
                  </div>
                </StyledAccordion>
              );
            },
          )}
        </div>
      )}
    </FormContext.Provider>
  );
};

export default FormEntry;
