import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { makeStyles } from '@material-ui/styles';
import { Theme, Tooltip } from '@material-ui/core';
import {
  AssignmentContainer,
  EducationModule,
  PracticalModule,
} from '../../types';

interface StatusFlagIconProps {
  module?: EducationModule | PracticalModule;
  container?: AssignmentContainer;
}

const useStyles = makeStyles((theme: Theme) => ({
  icon: {
    color: theme.palette.primary.main,
  },
  minus: {
    fontSize: 9,
    top: '-1px !important',
    left: '1px !important',
    color: theme.palette.common.white,
  },
  vs: {
    color: theme.palette.primary.main,
    fontWeight: 600,
  },
}));

const StatusFlagIcon = (props: StatusFlagIconProps) => {
  const { module, container } = props;
  const classes = useStyles();

  let status;

  if (module) {
    status = module.status;
  } else if (container) {
    status = container.status;
  }

  if (status === 'Afgerond' || status === 'finished') {
    return (
      <Tooltip title="Afgerond">
        <span>
          <FontAwesomeIcon icon="flag" className={classes.icon} />
        </span>
      </Tooltip>
    );
  }

  if (
    status === 'Afgerond met minder punten' ||
    status === 'finished_less_points'
  ) {
    return (
      <Tooltip title="Afgerond met minder punten">
        <span className="fa-layers fa-fw">
          <FontAwesomeIcon icon="flag" className={classes.icon} />
          <FontAwesomeIcon icon="minus" size="sm" className={classes.minus} />
        </span>
      </Tooltip>
    );
  }

  if (status === 'Vrijstelling' || status === 'excemption') {
    return (
      <Tooltip title="Vrijstelling">
        <span className={classes.vs}>VS</span>
      </Tooltip>
    );
  }

  return (
    <Tooltip title="Definitief geplaatst">
      <span>
        <FontAwesomeIcon icon={['fal', 'flag']} className={classes.icon} />
      </span>
    </Tooltip>
  );
};

export default StatusFlagIcon;
