import React, { useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { Theme, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useSnackbar } from 'notistack';

import Home from '../../Home';
import ApiClient from '../../api/ApiClient';

const useStyles = makeStyles((theme: Theme) => ({
  description: {
    color: theme.palette.text.primary,
  },
  passwordField: {
    marginTop: theme.spacing(2),
  },
  field: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  form: {
    color: theme.palette.text.primary,
  },
}));

const Reset2FAMethod = () => {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const notifications = useSnackbar();
  const token = new URLSearchParams(location.search).get('token') || '';

  useEffect(() => {
    ApiClient.resetTwoFactorMethod(token)
      .then(() => {
        notifications.enqueueSnackbar(
          'Je verificatiemethode is verwijderd. Log opnieuw in om een nieuwe verificatiemethode te kiezen.',
          { variant: 'success' },
        );
      })
      .catch(() => {
        notifications.enqueueSnackbar(
          'Er is iets mis gegaan bij het verwijderen van de verificatiemethode. Vraag een nieuwe link aan.',
          { variant: 'error' },
        );
      })
      .finally(() => {
        history.push('/');
      });
  });

  return (
    <Home title="Verificatiemethode opnieuw instellen">
      <Typography variant="body1" className={classes.description}>
        Een moment geduld alstublieft.
      </Typography>
    </Home>
  );
};

export default Reset2FAMethod;
