import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core';
import { colors } from '../config/theme';

interface StatusIndicatorProps {
  indicator: 'green' | 'orange' | 'red';
  variant?: 'circle' | 'table';
  className?: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    opacity: 0.8,
  },
  circleVariant: {
    display: 'inline-block',
    height: 12,
    width: 12,
    marginRight: theme.spacing(1),
    borderRadius: '50%',
    transform: 'translateY(1px)',
  },
  tableVariant: {
    position: 'absolute',
    left: 0,
    top: 0,
    width: 4,
    height: '100%',
    borderRadius: 0,
  },
  green: {
    background: colors.secondary.green,
  },
  orange: {
    background: colors.secondary.orange,
  },
  red: {
    background: colors.secondary.red,
  },
}));

const StatusIndicator = (props: StatusIndicatorProps) => {
  const { indicator, className } = props;
  const classes = useStyles();

  const variant = props.variant || 'table';
  const variantClass = `${variant}Variant` as 'tableVariant' | 'circleVariant';

  return (
    <div
      className={`${classes.root} ${className} ${classes[variantClass]} ${classes[indicator]}`}
    />
  );
};

export default StatusIndicator;
