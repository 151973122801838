import { createTheme } from '@material-ui/core/styles';
import { Shadows } from '@material-ui/core/styles/shadows';
import { grey } from '@material-ui/core/colors';

export const colors = {
  primary: {
    blue: '#043775',
    orange: '#ff6034',
    grey: '#f0f3f8',
  },
  secondary: {
    yellow: '#ffde64',
    green: '#1aaf94',
    pink: '#ff98b0',
    blue: '#78c8cc',
    red: '#eb4111',
    orange: '#ff7b56',
    grey: '#a4abca',
  },
};

export const gradients = {
  primaryLight:
    'linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(236,241,245,1) 100%)',
  primaryUltraLight:
    'linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(245,248,250,1) 100%)',
};

/*
 * Used to make colors lighter or darker:
 * https://mdigi.tools/lighten-color
 * https://mdigi.tools/darken-color
 */
const theme = createTheme({
  palette: {
    primary: {
      main: '#043775',
      dark: '#033066',
      contrastText: '#fff',
    },
    secondary: {
      main: '#ff6034',
      dark: '#f05930',
      contrastText: '#fff',
    },
  },

  shadows: [
    'none',
    '1px 2px 7px -1px rgba(0,0,0,0.05), 0px 1px 1px 0px rgba(0,0,0,0.09), 0px 2px 4px 0px rgba(0,0,0,0.10)',
    '1px 2px 7px -1px rgba(0,0,0,0.05), 0px 1px 1px 0px rgba(0,0,0,0.09), 0px 2px 4px 0px rgba(0,0,0,0.10)',
    ...Array(22).fill('none'),
  ] as Shadows,

  typography: {
    fontFamily: ['Open Sans', 'sans-serif'].join(','),
    fontWeightBold: 600,
    h1: {
      fontFamily: 'Raleway, sans-serif',
      fontSize: 35,
      fontWeight: 600,
    },
    h2: {
      fontFamily: 'Raleway, sans-serif',
    },
    h3: {
      fontFamily: 'Raleway, sans-serif',
    },
    h4: {
      fontFamily: 'Raleway, sans-serif',
    },
    h5: {
      fontFamily: 'Raleway, sans-serif',
    },
    h6: {
      fontFamily: 'Raleway, sans-serif',
    },
  },

  overrides: {
    MuiButton: {
      root: {
        textTransform: 'none',
      },
      contained: {
        backgroundColor: '#ecf1f4',
        '&:hover': {
          backgroundColor: '#dce5eb',
        },
      },
    },
    MuiChip: {
      root: {
        borderRadius: 0,
      },
    },
    MuiTabs: {
      indicator: {
        display: 'none',
      },
    },
    MuiTab: {
      root: {
        marginRight: 8,
        background: grey[100],
        transition: 'all 0.3s ease',
        '&[aria-selected="true"]': {
          background: '#fff',
          boxShadow: '1px 1px 3px 1px rgba(0, 0, 0, 0.09)',
        },
        textTransform: 'none',
        fontWeight: 600,
      },
    },
  },
});

export default theme;
