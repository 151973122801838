import React, { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import { Box, IconButton, Paper, TextField, Theme } from '@material-ui/core';
import moment from 'moment';
import { grey } from '@material-ui/core/colors';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Avatar from '../../users/Avatar';
import SafeHtml from '../../../components/SafeHtml';
import { CompleteUser, MinimalUser, User } from '../../../types';
import AppContext from '../../../AppContext';

export interface DialogMessage {
  id: string;
  user: MinimalUser | User;
  content: string;
  created: string;
}

interface AssignmentDialogMessageProps {
  message: DialogMessage;
  onDelete?: (message: DialogMessage) => void;
  onEdit?: (message: DialogMessage, newContent: string) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: 'relative',
    display: 'flex',
    width: '100%',
    minWidth: '50vw',
    '&:not(:last-child)': {
      marginBottom: theme.spacing(3),
    },
    '&:hover > $actions': {
      visibility: 'visible',
    },
  },
  container: {
    maxWidth: '60%',
  },
  isCurrentUser: {
    justifyContent: 'flex-end',
    '& $content': {
      background: theme.palette.common.white,
    },
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(1),
  },
  content: {
    background: grey[100],
    padding: theme.spacing(2),
  },
  avatar: {
    marginRight: theme.spacing(1),
  },
  name: {
    fontWeight: theme.typography.fontWeightBold,
    marginRight: theme.spacing(1),
    fontSize: 16,
  },
  date: {
    color: grey[500],
    fontSize: 12,
  },
  actions: {
    position: 'absolute',
    zIndex: 1,
    top: 0,
    right: 0,
    visibility: 'hidden',
  },
  editor: {
    background: theme.palette.common.white,
  },
}));

const AssignmentDialogMessage = (props: AssignmentDialogMessageProps) => {
  const { message, onDelete, onEdit } = props;
  const { user, created } = message;
  const [content, setContent] = useState<string>(message.content);
  const [changedContent, setChangedContent] = useState<string>(message.content);
  const [editMode, setEditMode] = useState<boolean>(false);
  const { roleViewManager } = useContext(AppContext);

  const account = useSelector(
    (selector: {
      user: {
        account: CompleteUser;
      };
    }) => selector.user.account,
  );
  const classes = useStyles();

  const toggleEditMode = () => setEditMode(!editMode);

  const handleEditorKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      setEditMode(false);
      setContent(changedContent);

      if (onEdit) {
        onEdit(message, changedContent);
      }
    }
  };

  const handleContentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChangedContent(event.target.value);
  };

  const handleDelete = () => {
    if (onDelete) {
      onDelete(message);
    }
  };

  useEffect(() => {
    setContent(message.content);
    setChangedContent(message.content);
  }, [message]);

  return (
    <div
      className={`${classes.root} ${
        account.id === user.id ? classes.isCurrentUser : ''
      }`}
    >
      {message.user.id === roleViewManager.getUser().id && (
        <Paper className={classes.actions}>
          <Box p={1}>
            <IconButton size="small" onClick={toggleEditMode}>
              <FontAwesomeIcon icon={['fal', 'edit']} />
            </IconButton>
            <IconButton size="small" onClick={handleDelete}>
              <FontAwesomeIcon icon={['fal', 'trash']} />
            </IconButton>
          </Box>
        </Paper>
      )}
      <div className={classes.container}>
        <div className={classes.header}>
          <Avatar user={user} className={classes.avatar} />
          <div className={classes.name}>{user.fullName}</div>
          <div className={classes.date}>
            {moment(created).locale('nl').fromNow()}
          </div>
        </div>
        <Paper className={classes.content}>
          <Box display={editMode ? 'block' : 'none'}>
            <TextField
              multiline
              minRows={3}
              variant="outlined"
              value={changedContent}
              className={classes.editor}
              onKeyPress={handleEditorKeyPress}
              onChange={handleContentChange}
              fullWidth
            />
          </Box>
          <Box display={editMode ? 'none' : 'block'}>
            <SafeHtml html={content} />
          </Box>
        </Paper>
      </div>
    </div>
  );
};

export default AssignmentDialogMessage;
