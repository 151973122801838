import React, { useEffect, useState } from 'react';
import { Box, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Education, InternshipModule } from '../../../types';
import { colors } from '../../../config/theme';
import { hoursToSummary } from '../common';
import EducationRepository from '../EducationRepository';
import InternshipTypes, { InternshipTypeLabels } from '../InternshipTypes';
import WidgetColorLabel from './WidgetColorLabel';
import { round } from '../../../utils/common';

interface InternshipsWidgetProps {
  education: Education;
}

const colorOpacity = 0.2;

const useStyles = makeStyles((theme: Theme) => ({
  bar: {
    position: 'relative',
    display: 'block',
    width: '100%',
    height: 15,
    borderRadius: theme.shape.borderRadius,
  },
  barAchieved: {
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
  },
  barNotYetAchieved: {
    position: 'absolute',
    top: 0,
    left: 0,
    opacity: (1 + colorOpacity) / 2,
    height: '100%',
  },
  barBackground: {
    position: 'absolute',
    top: 0,
    left: 0,
    opacity: colorOpacity,
    width: '100%',
    height: '100%',
  },
  labels: {
    minWidth: 180,
  },
}));

const InternshipsWidget = (props: InternshipsWidgetProps) => {
  const { education } = props;
  const classes = useStyles();

  const [modules, setModules] = useState<InternshipModule[] | undefined>(
    undefined,
  );

  const colorList = [
    colors.secondary.blue,
    colors.secondary.orange,
    colors.secondary.green,
    colors.secondary.yellow,
    colors.secondary.pink,
    colors.secondary.red,
    colors.secondary.grey,
  ];

  const internships = Object.entries(education.internshipHours).filter(
    ([, hours]) => hours !== null,
  );

  useEffect(() => {
    new EducationRepository()
      .getModules(education.id, ['STA'])
      .then((response) => setModules(response.data as InternshipModule[]));
  }, [education.id]);

  if (!modules) {
    return null;
  }

  return (
    <Box>
      {internships.map(([idStr, hours], index) => {
        const id = parseInt(idStr, 10) as keyof InternshipTypeLabels;

        const pointsSummary = hoursToSummary(
          hours,
          modules.filter((m) => m.internshipType === id),
        );

        const color = colorList[index];

        return (
          <Box
            mb={index === internships.length - 1 ? 0 : 2}
            display="flex"
            alignItems="center"
          >
            <Box width="100%" mr={2}>
              {InternshipTypes[id]}
              <div className={classes.bar}>
                <div
                  className={classes.barAchieved}
                  style={{
                    width: `${pointsSummary.percentageAchieved}%`,
                    background: color,
                  }}
                />
                <div
                  className={classes.barNotYetAchieved}
                  style={{
                    width: `${Math.min(
                      pointsSummary.percentageAchieved +
                        pointsSummary.percentageNotYetAchieved,
                      100,
                    )}%`,
                    background: color,
                  }}
                />
                <div
                  className={classes.barBackground}
                  style={{ background: color }}
                />
              </div>
            </Box>
            <Box className={classes.labels}>
              <WidgetColorLabel
                label={`${round(pointsSummary.achieved, 2)} afgerond`}
                color={color}
              />
              <WidgetColorLabel
                label={`${round(
                  pointsSummary.notYetAchieved,
                  2,
                )} nog niet afgerond`}
                color={color}
                opacity={(1 + colorOpacity) / 2}
              />
              <WidgetColorLabel
                label={`${round(pointsSummary.todo, 2)} in te schrijven`}
                color={color}
                opacity={colorOpacity}
              />
            </Box>
          </Box>
        );
      })}
    </Box>
  );
};

export default InternshipsWidget;
