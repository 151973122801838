import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Box,
  Theme,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import moment from 'moment';

import { colors } from '../../../config/theme';
import { RegisterEventSession, ScheduleItem } from '../../../types';

interface EventSessionListProps {
  sessions: RegisterEventSession[];
  scheduleItems: ScheduleItem[];
}

const useStyles = makeStyles((theme: Theme) => ({
  row: {
    '& > th': {
      fontWeight: 600,
    },
    '& > td': {
      border: 0,
      borderTop: `1px solid ${colors.secondary.grey}`,
    },
    '& > th, & > td': {
      padding: theme.spacing(1),
    },
  },
}));

const EventSessionList = (props: EventSessionListProps) => {
  const { sessions, scheduleItems } = props;

  const classes = useStyles();

  const filterScheduleItemsByDate = (date: Date) => {
    const formattedDate = moment(date).format('YYYYMMDD');

    return scheduleItems
      .filter((item) => moment(item.date).format('YYYYMMDD') === formattedDate)
      .sort((a, b) => {
        const aTime = moment(a.startTime).format('HHmm');
        const bTime = moment(b.startTime).format('HHmm');

        return aTime < bTime ? -1 : 1;
      });
  };

  const makeItemTitle = (item: ScheduleItem) => {
    const startTime = moment(item.startTime).format('HH:mm');
    const endTime = moment(item.endTime).format('HH:mm');

    return `${item.event} - ${item.sessionName} van ${startTime} tot ${endTime} uur`;
  };

  return (
    <Box width="100%">
      <Table>
        <TableHead>
          <TableRow className={classes.row}>
            <TableCell>Bijeenkomst</TableCell>
            <TableCell>Begintijd</TableCell>
            <TableCell>Eindtijd</TableCell>
            <TableCell>Locatie</TableCell>
            <TableCell>Onderwerp</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {sessions.map((session) => {
            const date = moment(session.date).format('DD-MM-YYYY');
            const startTime = moment(session.startTime).format('HH:mm');
            const endTime = moment(session.endTime).format('HH:mm');

            const scheduleItemsAtDate = filterScheduleItemsByDate(
              new Date(session.date),
            );

            const title = (
              <>
                {scheduleItemsAtDate.map((item) => (
                  <Box>{makeItemTitle(item)}</Box>
                ))}
              </>
            );

            return (
              <TableRow key={session.id} className={classes.row}>
                <TableCell>
                  {scheduleItemsAtDate.length >= 1 ? (
                    <Box display="flex">
                      <Box>{date}</Box>
                      <Tooltip title={title}>
                        <Box ml={1}>
                          <FontAwesomeIcon
                            icon={['fal', 'exclamation-circle']}
                            style={{
                              color: 'red',
                              width: 20,
                              height: 20,
                              padding: 0,
                            }}
                          />
                        </Box>
                      </Tooltip>
                    </Box>
                  ) : (
                    date
                  )}
                </TableCell>
                <TableCell>{startTime}</TableCell>
                <TableCell>{endTime}</TableCell>
                <TableCell>{session.location || '-'}</TableCell>
                <TableCell>{session.title}</TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </Box>
  );
};

export default EventSessionList;
