import { Badge, createStyles, withStyles } from '@material-ui/core';
import { colors } from '../config/theme';

const StyledBadge = withStyles(() =>
  createStyles({
    badge: {
      right: -3,
      top: 5,
      minWidth: 21,
      height: 21,
      fontSize: 10,
      background: colors.secondary.red,
      padding: '0 4px',
    },
  }),
)(Badge);

export default StyledBadge;
