import React, { useContext, useMemo, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Box, Button, Tab, Tabs, Theme } from '@material-ui/core';
import { Link } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import NewsOverview from './news/NewsOverview';
import TextMedia from '../../components/pageheader/TextMedia';
import URLHashParameters from '../../utils/URLHashParameters';
import TabPanel from '../education/TabPanel';
import AppContext from '../../AppContext';
import {
  ROUTE_FAQ_MANAGEMENT,
  ROUTE_NEWS_MANAGEMENT,
  FAQ_WRITE_ROLES,
} from './index';
import FaqOverview from './faq/FaqOverview';
import SearchInput from '../../components/search/SearchInput';
import SearchContext from '../../components/search/SearchContext';

const useStyles = makeStyles((theme: Theme) => ({
  tabs: {
    '& button': {
      padding: `0 0 ${theme.spacing(1)}px 0`,
      marginRight: theme.spacing(2),
      fontWeight: theme.typography.fontWeightBold,
      fontSize: '1.05rem',
      textTransform: 'none',
      borderBottom: `2px solid ${theme.palette.grey[400]}`,
      '&:hover': {
        outline: 'none',
      },
    },
    '& .MuiTabs-indicator': {
      display: 'block',
    },
  },
  tab: {
    background: 'none',
    '&[aria-selected="true"]': {
      boxShadow: 'none',
      background: 'none',
    },
  },
}));

const InfoOverview = () => {
  const classes = useStyles();
  const { roleViewManager } = useContext(AppContext);
  const [activeTab, setActiveTab] = useState(
    URLHashParameters.get('tab', 'nieuws') as string,
  );
  const [query, setQuery] = useState<string>('');

  const searchContextValue = useMemo(
    () => ({ query, setQuery }),
    [query, setQuery],
  );

  const handleTabChange = (tab: string) => {
    setActiveTab(tab);
    URLHashParameters.set('tab', tab);
  };

  return (
    <SearchContext.Provider value={searchContextValue}>
      <TextMedia name="Informatie" fullWidth>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          width="100%"
        >
          <Tabs
            value={activeTab}
            onChange={(event, value: string) => handleTabChange(value)}
            className={classes.tabs}
          >
            <Tab value="nieuws" label="Nieuws" className={classes.tab} />
            <Tab
              value="faq"
              label="Veelgestelde vragen"
              className={classes.tab}
            />
          </Tabs>
          <Box display="flex" alignItems="center">
            {activeTab === 'nieuws' &&
              roleViewManager.hasPermission('MANAGE_NEWS') && (
                <Button
                  color="secondary"
                  startIcon={<FontAwesomeIcon icon={['fal', 'cog']} />}
                  to={ROUTE_NEWS_MANAGEMENT}
                  component={Link}
                  variant="contained"
                >
                  Beheer nieuws
                </Button>
              )}
            {activeTab === 'faq' && roleViewManager.hasRole(FAQ_WRITE_ROLES) && (
              <Button
                color="secondary"
                startIcon={<FontAwesomeIcon icon={['fal', 'cog']} />}
                to={ROUTE_FAQ_MANAGEMENT}
                component={Link}
                variant="contained"
              >
                Beheer FAQ
              </Button>
            )}
            {activeTab === 'faq' && (
              <Box ml={2}>
                <SearchInput />
              </Box>
            )}
          </Box>
        </Box>
      </TextMedia>
      <TabPanel value={activeTab} index="nieuws">
        <NewsOverview />
      </TabPanel>
      <TabPanel value={activeTab} index="faq">
        <FaqOverview />
      </TabPanel>
    </SearchContext.Provider>
  );
};

export default InfoOverview;
