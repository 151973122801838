import React, { useEffect, useState } from 'react';

import {
  Box,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Theme,
  Tooltip,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import moment from 'moment';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { colors } from '../../../config/theme';
import { RegisterEventSession, ScheduleItem } from '../../../types';
import Loader from '../../../components/Loader';
import { EventWorkshopSelection } from './EventRegisterWizard';

interface EventSessionListWorkshopsProps {
  sessions: RegisterEventSession[];
  scheduleItems: ScheduleItem[];
  onWorkshopsUpdate: (workshopSelection: EventWorkshopSelection) => void;
  mode: 'register' | 'update';
  readOnly: boolean;
}

// stores information on the selected item in the working group
interface WorkshopSessionGroup {
  // the ids of the items in the radio group
  ids: number[];
  // the ids of the items in the radio group that have places left
  availableIds: number[];
  // the selected item in the radio group
  selected: number | null;
}

// represents a session plus some metadata that is needed for UI purposes
interface RegisterEventSessionItem {
  session: RegisterEventSession;
  // the id of the radio group
  radioGroupIndex: number | null;
  // the index within the radio group
  indexWithinGroup: number | null;
}

const useStyles = makeStyles((theme: Theme) => ({
  row: {
    '& > th': {
      fontWeight: 600,
    },
    '& > td': {
      border: 0,
      borderTop: `1px solid ${colors.secondary.grey}`,
      verticalAlign: 'top',
    },
    '& > th, & > td': {
      padding: theme.spacing(1),
    },
  },
  rowForSameGroup: {
    '& > td': {
      border: 0,
    },
  },
  center: {
    textAlign: 'center',
  },
  hide: {
    display: 'none',
  },
  iconBox: {
    padding: '2px',
  },
  infoTitle: {
    whiteSpace: 'pre-wrap',
  },
  checkbox: {
    padding: '0',
  },
}));

const EventSessionListWorkshops = (props: EventSessionListWorkshopsProps) => {
  const { sessions, scheduleItems, mode, onWorkshopsUpdate } = props;
  const readOnly = props.readOnly !== undefined ? props.readOnly : false;

  const classes = useStyles();

  const [loaded, setLoaded] = useState<boolean>(false);
  const [sessionItems, setSessionItems] = useState<RegisterEventSessionItem[]>(
    [],
  );
  const [workshopSessionGroups, setWorkshopSessionGroups] = useState<
    WorkshopSessionGroup[]
  >([]);

  const filterScheduleItemsByDate = (date: Date) => {
    const formattedDate = moment(date).format('YYYYMMDD');

    return scheduleItems
      .filter((item) => moment(item.date).format('YYYYMMDD') === formattedDate)
      .sort((a, b) => {
        const aTime = moment(a.startTime).format('HHmm');
        const bTime = moment(b.startTime).format('HHmm');

        return aTime < bTime ? -1 : 1;
      });
  };

  const makeItemTitle = (item: ScheduleItem) => {
    const startTime = moment(item.startTime).format('HH:mm');
    const endTime = moment(item.endTime).format('HH:mm');

    return `${item.event} - ${item.sessionName} van ${startTime} tot ${endTime} uur`;
  };

  // this function does a couple of things while processes items:
  // - wraps all sessions inside a session item that contains metadata that is needed for UI purposes
  // - calculate radio group data for workshop items
  // - prepares a data structure that stores which workshop session is selected in each group
  // - auto-selects workshop elements as selected if they're the only one in the group
  const preprocess = (sessions: RegisterEventSession[]) => {
    const items = [] as RegisterEventSessionItem[];

    let currentRadioGroupIndex = 0;
    let currentIndexWithinGroup = 0;
    let previousItem = null as RegisterEventSessionItem | null;
    const groups = [] as WorkshopSessionGroup[];
    sessions.forEach((session: RegisterEventSession) => {
      const item = {
        session,
        radioGroupIndex: null,
        indexWithinGroup: null,
      } as RegisterEventSessionItem;

      if (session.workshop) {
        let newGroup = false;

        // if this is the second workshop session in a row,
        // we must check whether the session must be placed in the same radio group
        if (previousItem) {
          if (
            moment(previousItem.session.date).format('YYYYMMDD') ===
              moment(session.date).format('YYYYMMDD') &&
            moment(previousItem.session.startTime).format('HHmm') ===
              moment(session.startTime).format('HHmm') &&
            moment(previousItem.session.endTime).format('HHmm') ===
              moment(session.endTime).format('HHmm')
          ) {
            // timeslot equals that of the previous session -> same radio group
            currentIndexWithinGroup += 1;
          } else {
            // timeslot differs from that of the previous session -> new radio group
            newGroup = true;
            currentRadioGroupIndex += 1;
            currentIndexWithinGroup = 0;
          }
        } else {
          // no previous item -> new radio group
          newGroup = true;
        }

        item.radioGroupIndex = currentRadioGroupIndex;
        item.indexWithinGroup = currentIndexWithinGroup;

        let group;
        if (newGroup) {
          group = {
            ids: [] as number[],
            availableIds: [] as number[],
            selected: null,
          } as WorkshopSessionGroup;

          groups.push(group);
        } else {
          group = groups[currentRadioGroupIndex];
        }

        group.ids.push(session.id);
        if (
          session.workshopRegisteredFor ||
          (session.workshopRegistrationCount || 0) <
            (session.workshopMaxParticipants || 0)
        ) {
          group.availableIds.push(session.id);
        }

        if (session.workshopRegisteredFor) {
          group.selected = session.id;
        }

        previousItem = item;
      } else if (previousItem && previousItem.session.workshop) {
        // this session is not a workshop, but the previous session in the loop was,
        // therefore we must prepare for a possible new set of workshops later in the loop
        currentRadioGroupIndex += 1;
        currentIndexWithinGroup = 0;

        // the previous item is only relevant for workshop items,
        // because sessions are only grouped together if they're workshops on the same timeslot
        previousItem = null;
      }

      items.push(item);
    });

    setSessionItems(items);
    setWorkshopSessionGroups(groups);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setWorkshopSessionGroups((prevGroups) =>
      prevGroups.map((group: WorkshopSessionGroup, index: number) => {
        const newGroup = { ...group };

        if (event.target.name === `group${index}`) {
          const targetValue = parseInt(event.target.value, 10);

          if (newGroup.selected === targetValue) {
            // clear the currently selected value if the user clicks the selected value again
            newGroup.selected = null;
          } else {
            newGroup.selected = targetValue;
          }
        }

        return newGroup;
      }),
    );
  };

  const makeInfoElement = (sessionItem: RegisterEventSessionItem) => {
    const { session } = sessionItem;

    if (!session.info) {
      return null;
    }

    const title = <Box className={classes.infoTitle}>{session.info}</Box>;

    return (
      <Tooltip title={title}>
        <Box className={classes.iconBox}>
          <FontAwesomeIcon
            icon={['fal', 'info-circle']}
            style={{ width: 20, height: 20 }}
          />
        </Box>
      </Tooltip>
    );
  };

  const makeParticipationElement = (sessionItem: RegisterEventSessionItem) => {
    const { session } = sessionItem;

    if (!session.workshop) {
      return 'plenair';
    }

    if (
      sessionItem.radioGroupIndex === null ||
      workshopSessionGroups[sessionItem.radioGroupIndex] === undefined
    ) {
      // this code should be unreachable
      return 'ERROR';
    }

    const available = workshopSessionGroups[
      sessionItem.radioGroupIndex
    ].availableIds.includes(session.id);
    if (!available) {
      return 'VOL';
    }

    const selected =
      workshopSessionGroups[sessionItem.radioGroupIndex].selected ===
      session.id;

    return (
      <Checkbox
        className={classes.checkbox}
        name={`group${sessionItem.radioGroupIndex}`}
        checked={selected}
        onChange={handleChange}
        value={session.id}
        disabled={readOnly}
      />
    );
  };

  useEffect(() => {
    preprocess(sessions);
    setLoaded(true);
  }, [sessions]);

  useEffect(
    () => {
      if (!loaded) {
        return;
      }

      // fetch a list of selected workshop ids, possibly containing null values
      const selectedIds = workshopSessionGroups
        .map((group) => group.selected)
        .filter((id) => id);

      // fetch a list of unselected workshop ids by combining the ids of each group and removing the selected ids
      const unselectedIds = workshopSessionGroups
        .flatMap((group) => group.ids)
        .filter((id) => !selectedIds.includes(id));

      onWorkshopsUpdate({
        selectedIds,
        unselectedIds,
      } as EventWorkshopSelection);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [workshopSessionGroups],
  );

  if (!loaded) {
    return <Loader />;
  }

  return (
    <Box width="100%">
      <Table>
        <TableHead>
          <TableRow className={classes.row}>
            <TableCell>Bijeenkomst</TableCell>
            <TableCell>Begintijd</TableCell>
            <TableCell>Eindtijd</TableCell>
            <TableCell>Locatie</TableCell>
            <TableCell>Onderwerp</TableCell>
            <TableCell className={classes.center} />
            <TableCell className={classes.center}>Deelname</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {sessionItems.map((sessionItem) => {
            const { session } = sessionItem;

            const date = moment(session.date).format('DD-MM-YYYY');
            const startTime = moment(session.startTime).format('HH:mm');
            const endTime = moment(session.endTime).format('HH:mm');

            // determine whether this session must be grouped with the previous session
            const sameGroup =
              sessionItem.indexWithinGroup && sessionItem.indexWithinGroup >= 1;

            let className = sameGroup
              ? `${classes.row} ${classes.rowForSameGroup}`
              : classes.row;

            // when updating workshops (and not registering for an event),
            // we must hide non-workshops and workshops that lie in the past
            if (
              mode === 'update' &&
              (!session.workshop || moment(session.date).format('YYYYMMDD')) <=
                moment(new Date()).format('YYYYMMDD')
            ) {
              className = `${className} ${classes.hide}`;
            }

            const info = makeInfoElement(sessionItem);
            const participation = makeParticipationElement(sessionItem);

            const scheduleItemsAtDate = filterScheduleItemsByDate(
              new Date(session.date),
            );

            const title = (
              <>
                {scheduleItemsAtDate.map((item) => (
                  <Box>{makeItemTitle(item)}</Box>
                ))}
              </>
            );

            return (
              <TableRow key={session.id} className={className}>
                {sameGroup ? (
                  <>
                    <TableCell />
                    <TableCell />
                    <TableCell />
                  </>
                ) : (
                  <>
                    <TableCell>
                      {scheduleItemsAtDate.length >= 1 ? (
                        <Box display="flex">
                          <Box>{date}</Box>
                          <Tooltip title={title}>
                            <Box ml={1}>
                              <FontAwesomeIcon
                                icon={['fal', 'exclamation-circle']}
                                style={{
                                  color: 'red',
                                  width: 20,
                                  height: 20,
                                  padding: 0,
                                }}
                              />
                            </Box>
                          </Tooltip>
                        </Box>
                      ) : (
                        date
                      )}
                    </TableCell>
                    <TableCell>{startTime}</TableCell>
                    <TableCell>{endTime}</TableCell>
                  </>
                )}
                <TableCell>{session.location || '-'}</TableCell>
                <TableCell>{session.title}</TableCell>
                <TableCell className={classes.center}>{info}</TableCell>
                <TableCell className={classes.center}>
                  {participation}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </Box>
  );
};

export default EventSessionListWorkshops;
