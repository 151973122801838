import React, { useCallback, useContext, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { makeStyles } from '@material-ui/styles';
import {
  Box,
  Button,
  Grid,
  IconButton,
  Popover,
  Theme,
  Tooltip,
  Typography,
} from '@material-ui/core';

import { useSelector } from 'react-redux';
import NotificationRepository from './NotificationRepository';
import AppContext from '../../AppContext';
import {
  CompleteUser,
  Notification as NotificationInterface,
} from '../../types';
import Notification from './Notification';
import InfiniteScroll from '../../components/InfiniteScroll';
import StyledBadge from '../../components/StyledBadge';
import { colors } from '../../config/theme';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
  },
  button: {
    color: colors.primary.blue,
  },
  notifications: {
    margin: 0,
    padding: theme.spacing(1),
    maxWidth: 400,
    maxHeight: 500,
    overflowY: 'scroll',
    overflowX: 'hidden',
  },
}));

const NotificationBell = () => {
  const classes = useStyles();
  const account = useSelector(
    (selector: {
      user: {
        account: CompleteUser | null;
      };
    }) => selector.user.account,
  );
  const [notifications, setNotifications] = useState<NotificationInterface[]>(
    [],
  );
  const [totalCount, setTotalCount] = useState<number>(0);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [infiniteScrollState, setInfiniteScrollState] = useState<{
    currentPage: number;
    scrollLock: boolean;
  }>({
    currentPage: 1,
    scrollLock: false,
  });
  const { appState, setAppState } = useContext(AppContext);

  const repository = new NotificationRepository();

  /**
   * Load initial notifications.
   */
  const loadNotifications = () =>
    repository
      .getNotifications(1)
      .then((response) => {
        setNotifications(response.data.items);
      })
      .catch((err) => {
        if (appState && setAppState) {
          setAppState({ ...appState, errorStatusCode: err.response.status });
        }
      });

  /**
   * Open/close the notification popover.
   */
  const handleOpen = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    const target = e.currentTarget;
    loadNotifications().then(() => setAnchorEl(target));
  };
  const handleClose = () => setAnchorEl(null);

  /**
   * Load new notifications page
   */
  const loadNextPage = useCallback(() => {
    const page = infiniteScrollState.currentPage + 1;
    setInfiniteScrollState({ currentPage: page, scrollLock: true });

    repository
      .getNotifications(page)
      .then((response) => {
        setNotifications([...notifications, ...response.data.items]);
        setInfiniteScrollState({
          currentPage: page,
          scrollLock: response.data.items.length === 0,
        });
      })
      .catch((err) => {
        if (appState && setAppState) {
          setAppState({ ...appState, errorStatusCode: err.response.status });
        }
      });
  }, [notifications, infiniteScrollState]);

  /**
   * Triggers when the user clicks on a notification.
   */
  const handleNotificationClick = (
    notification: NotificationInterface,
    url?: string | null,
  ) => {
    if (!notification.read) {
      // Mark notification as read.
      repository.markNotificationAsRead(notification).then(() => {
        const newNotifications = [...notifications];
        newNotifications.find((n) => n.id === notification.id)!.read = true;

        setNotifications(newNotifications);
        setTotalCount(totalCount - 1);
      });
    }

    if (url) {
      handleClose();
    }
  };

  /**
   * Determines if the user has unread notifications.
   */
  const checkUnreadNotifications = () => {
    repository
      .getUnreadNotifications()
      .then((response) => {
        setTotalCount(response.data.totalItems);
      })
      .catch(() => {});
  };

  const markAllAsRead = () => {
    repository
      .markAllAsRead()
      .then(() => {
        loadNotifications();
        checkUnreadNotifications();
      })
      .catch((err) => {
        if (appState && setAppState) {
          setAppState({ ...appState, errorStatusCode: err.response.status });
        }
      });
  };

  /**
   * On load, fetch unread notifications for count.
   */
  useEffect(() => {
    checkUnreadNotifications();
    const checkInterval = setInterval(checkUnreadNotifications, 20000);

    return () => clearInterval(checkInterval);
  }, [account]);

  let markedReadNotifications = false;

  return (
    <div className={classes.root}>
      <Popover
        id="notifications-popover"
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        elevation={2}
      >
        {notifications.length > 0 && (
          <InfiniteScroll
            className={classes.notifications}
            onTrigger={loadNextPage}
            lock={infiniteScrollState.scrollLock}
            windowScroll={false}
          >
            <Grid container spacing={2}>
              {totalCount > 0 && (
                <Grid item md={12}>
                  <Box display="flex" justifyContent="space-between">
                    <Typography variant="h6" component="h2">
                      Ongelezen
                    </Typography>
                    <Button size="small" onClick={markAllAsRead}>
                      Markeer alles als gelezen
                    </Button>
                  </Box>
                </Grid>
              )}
              {notifications.map((notification) => {
                const readSectionStart =
                  notification.read && !markedReadNotifications;
                if (readSectionStart) {
                  markedReadNotifications = true;
                }

                return (
                  <Grid item md={12}>
                    {readSectionStart && (
                      <Box mb={2}>
                        <Typography variant="h6" component="h2">
                          Gelezen
                        </Typography>
                      </Box>
                    )}
                    <Notification
                      notification={notification}
                      onClick={handleNotificationClick}
                    />
                  </Grid>
                );
              })}
            </Grid>
          </InfiniteScroll>
        )}
        {notifications.length === 0 && (
          <Typography variant="body1" className={classes.notifications}>
            Je hebt nog geen notificaties.
          </Typography>
        )}
      </Popover>
      <Tooltip title="Notificaties">
        <StyledBadge badgeContent={totalCount} color="secondary">
          <IconButton
            size="small"
            className={classes.button}
            onClick={handleOpen}
          >
            <FontAwesomeIcon icon="bell" />
          </IconButton>
        </StyledBadge>
      </Tooltip>
    </div>
  );
};

export default NotificationBell;
