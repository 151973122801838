import React, { useContext, useEffect, useState } from 'react';
import { Checkbox, FormControlLabel, Radio, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { FieldProps } from './FieldProps';
import FormContext from '../FormContext';

const useStyles = makeStyles((theme: Theme) => ({
  radio: {
    width: theme.spacing(2),
    height: theme.spacing(2),
  },
  checkbox: {
    width: theme.spacing(2),
    height: theme.spacing(2),
  },
}));

const ChoiceList = (props: FieldProps) => {
  const classes = useStyles();
  const { id, metadata, options, entryField, disabled } = props;
  const multiple = metadata.multiple !== undefined ? metadata.multiple : false;

  const { formData, setFormData } = useContext(FormContext);
  const [dirty, setDirty] = useState<boolean>(false);
  const [initialSelected, setInitialSelected] = useState<{
    [key: string]: boolean;
  }>({});
  const [selected, setSelected] = useState<{ [key: string]: boolean }>({});

  useEffect(() => {
    const initialSelected: { [key: string]: boolean } = {};
    const entryFieldSelected =
      entryField && entryField.options
        ? entryField.options
            .filter((option) => option.selected)
            .map((option) => option.option.id)
        : [];

    options.forEach((option) => {
      initialSelected[option.id] = entryFieldSelected.includes(option.id);
    });

    setInitialSelected(initialSelected);
    setSelected(initialSelected);
  }, []);

  const handleCheckboxChange = (
    event: React.ChangeEvent<{ checked: boolean; name: string }>,
  ) => {
    setSelected({ ...selected, [event.target.name]: event.target.checked });
  };

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelected({ [event.target.value]: true });
  };

  const sortedOptions = options.sort((a, b) => {
    if (a.order === b.order) return 0;

    return a.order > b.order ? 1 : -1;
  });

  useEffect(() => {
    let isDirty = dirty;

    // If the initial selected state is different from the current selected state,
    // then the field is marked as dirty.
    if (
      !isDirty &&
      JSON.stringify(initialSelected) !== JSON.stringify(selected)
    ) {
      isDirty = true;
      setDirty(true);
    }

    if (!isDirty) {
      return;
    }

    const options = Object.entries(selected)
      .filter(([, checked]) => checked)
      .map(([id]) => id);

    setFormData!({
      ...formData,
      [id]: {
        valid: true,
        value: '',
        options,
      },
    });
  }, [selected]);

  return (
    <div>
      {!multiple &&
        sortedOptions.map((option) => {
          const control = (
            <Radio
              disabled={disabled}
              name={option.label}
              value={option.id}
              checked={selected[option.id] || false}
              onChange={handleRadioChange}
              className={classes.radio}
            />
          );

          return (
            <div key={option.id}>
              <FormControlLabel control={control} label={option.label} />
            </div>
          );
        })}
      {multiple &&
        sortedOptions.map((option) => {
          const control = (
            <Checkbox
              name={option.id}
              checked={selected[option.id] || false}
              onChange={handleCheckboxChange}
              disabled={disabled}
              className={classes.checkbox}
            />
          );

          return (
            <div key={option.id}>
              <FormControlLabel
                key={option.id}
                control={control}
                label={option.label}
              />
            </div>
          );
        })}
    </div>
  );
};

export default ChoiceList;
