import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Box, Grid, IconButton, Theme, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { colors } from '../../../config/theme';
import { Assignment } from '../../../types';
import EducationContext from '../EducationContext';
import AssignmentLink from './AssignmentLink';
import AssignmentReviewBadge from './AssignmentReviewBadge';
import AssignmentValuation from './AssignmentValuation';
import DeleteAssignmentDialog from './DeleteAssignmentDialog';
import DebugPopover from '../../../components/DebugPopover';
import AssignmentCollaborationButton from './AssignmentCollaborationButton';

interface ContainedAssignmentRowProps {
  assignment: Assignment;
  onRefresh: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  row: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    padding: theme.spacing(2),
    borderBottom: `1px solid ${colors.primary.grey}`,
  },
  nameColumn: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    maxWidth: `calc(60% - ${theme.spacing(2)}px)`,
    marginRight: theme.spacing(2),
    wordBreak: 'break-word',
  },
  assignmentsColumn: {
    width: '100%',
    maxWidth: `calc(25% - ${theme.spacing(2)}px)`,
    marginRight: theme.spacing(2),
  },
  optionsColumn: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
    maxWidth: `calc(15% - ${theme.spacing(2)}px)`,
  },
  statusColumn: {
    width: 'auto',
  },
}));

const ContainedAssignmentRow = (props: ContainedAssignmentRowProps) => {
  const { assignment, onRefresh } = props;
  const classes = useStyles();
  const history = useHistory();
  const [state, setState] = useState<{
    externalInviteDialogOpen: boolean;
    deleteAssignmentDialogOpen: boolean;
  }>({
    externalInviteDialogOpen: false,
    deleteAssignmentDialogOpen: false,
  });
  const [debugAnchorEl, setDebugAnchorEl] = useState<HTMLButtonElement | null>(
    null,
  );
  const { educationPermissions } = useContext(EducationContext);

  const { deleteAssignmentDialogOpen } = state;

  const handleDeleteAssignmentDialogOpen = () =>
    setState({ ...state, deleteAssignmentDialogOpen: true });
  const handleDeleteAssignmentDialogClose = () =>
    setState({ ...state, deleteAssignmentDialogOpen: false });

  const handleResubmit = () => {
    history.push(`/onderwijs/opdracht/${assignment.id}`);
  };

  const handleDebugOpen = (e: React.MouseEvent<HTMLButtonElement>) =>
    setDebugAnchorEl(e.currentTarget);
  const handleDebugClose = () => setDebugAnchorEl(null);

  const handleDelete = () => {
    handleDeleteAssignmentDialogClose();
    onRefresh();
  };

  return (
    <div className={classes.row}>
      <DebugPopover
        id={assignment.name}
        anchorEl={debugAnchorEl}
        onClose={handleDebugClose}
        properties={[
          { name: 'ID', value: assignment.id },
          { name: 'AFAS ID', value: assignment.afasId },
          { name: 'Inzendingen', value: assignment.entries.length },
          {
            name: 'Template ID',
            value: assignment.form ? assignment.form.id : '-',
          },
        ]}
      />

      <div className={classes.nameColumn}>
        <AssignmentLink assignment={assignment} />

        {assignment.inReview && (
          <Box ml={1}>
            <AssignmentReviewBadge assignment={assignment} />
          </Box>
        )}
      </div>

      <div className={classes.assignmentsColumn}>
        <AssignmentValuation assignment={assignment} />
      </div>

      <div className={classes.optionsColumn}>
        <Grid container spacing={1} justifyContent="flex-end">
          {educationPermissions.imitatingAdmin && (
            <Grid item>
              <IconButton size="small" onClick={handleDebugOpen}>
                <FontAwesomeIcon icon={['fal', 'bug']} />
              </IconButton>
            </Grid>
          )}
          {assignment.rejected && (
            <Grid item>
              <Tooltip title="Opnieuw inleveren">
                <IconButton size="small" onClick={handleResubmit}>
                  <FontAwesomeIcon icon={['fal', 'redo']} />
                </IconButton>
              </Tooltip>
            </Grid>
          )}

          {assignment.form && assignment.form.externalFields && (
            <AssignmentCollaborationButton assignment={assignment} />
          )}
          {educationPermissions.edit &&
            !assignment.finished &&
            assignment.entries.length === 0 && (
              <Grid item>
                <DeleteAssignmentDialog
                  assignment={assignment}
                  open={deleteAssignmentDialogOpen}
                  onClose={handleDeleteAssignmentDialogClose}
                  onDelete={handleDelete}
                />

                <Tooltip title="Opdracht verwijderen">
                  <IconButton
                    size="small"
                    onClick={handleDeleteAssignmentDialogOpen}
                  >
                    <FontAwesomeIcon icon={['fal', 'trash']} />
                  </IconButton>
                </Tooltip>
              </Grid>
            )}
        </Grid>
      </div>
    </div>
  );
};

export default ContainedAssignmentRow;
