import { useCallback, useMemo, useState } from 'react';
import { FormFieldInterface } from '../FormField';
import { FormContextState } from '../Form';

const useExpandableFormFields = ({
  fields,
}: {
  fields: FormFieldInterface[];
}) => {
  const determineExpandedFieldIds = useCallback(
    (formData: FormContextState | undefined = undefined) =>
      fields
        .filter((f: FormFieldInterface) => {
          // trivial: default expanded -> expand
          if (f.defaultExpanded) {
            return true;
          }

          // no form data -> don't expand
          if (!formData) {
            return false;
          }

          // no value provided for field -> don't expand
          const formDataForField = formData[f.id] || undefined;
          if (!formDataForField) {
            return false;
          }

          const hasValue =
            typeof formDataForField.value === 'string' &&
            formDataForField.value.length >= 1;
          const hasOptionValue =
            formDataForField.options !== undefined &&
            formDataForField.options.length >= 1;

          // a non-empty value provided for field -> expand
          return hasValue || hasOptionValue;
        })
        .map((f: FormFieldInterface) => f.id),
    [fields],
  );

  const initialFieldIds = useMemo(
    () => determineExpandedFieldIds(),
    [determineExpandedFieldIds],
  );
  const allFieldIds = useMemo(
    () => fields.map((f: FormFieldInterface) => f.id),
    [fields],
  );
  const [expandedFieldIds, setExpandedFieldIds] =
    useState<string[]>(initialFieldIds);

  const atLeastOneFieldExpanded = expandedFieldIds.length >= 1;

  const toggleExpanded = (fieldId: string) => {
    setExpandedFieldIds((prev) =>
      prev.includes(fieldId)
        ? [...prev].filter((i) => i !== fieldId)
        : [...prev, fieldId],
    );
  };

  const toggleAllExpanded = () => {
    setExpandedFieldIds((prev) => (prev.length >= 1 ? [] : [...allFieldIds]));
  };

  return {
    expandedFieldIds,
    setExpandedFieldIds,
    atLeastOneFieldExpanded,
    determineExpandedFieldIds,
    toggleExpanded,
    toggleAllExpanded,
  };
};

export default useExpandableFormFields;
