import React, { useContext, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { IconButton, Theme } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';

import { Education, EducationMaterial, File } from '../../../types';
import FileIcon from '../../../components/file/FileIcon';
import ApiClient from '../../../api/ApiClient';
import { refreshAccount } from '../../../actions';
import MaterialUploader from './MaterialUploader';
import ProductContext from './ProductContext';
import { getFileURL } from '../../../utils/common';
import ConfirmationDialog from '../../../components/ConfirmationDialog';
import { colors } from '../../../config/theme';

interface EducationMaterialViewerProps {
  education: Education;
  query?: string;
  selectable?: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
  },
  material: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
  },
  materialHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    background: colors.primary.grey,
    '&:hover': {
      '& $removeButton': {
        opacity: 1,
      },
    },
  },
  files: {
    width: '100%',
  },
  file: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    '&:hover': {
      '& $removeButton': {
        opacity: 1,
      },
    },
  },
  fileName: {
    display: 'flex',
    alignItems: 'center',
  },
  fileIcon: {
    marginRight: theme.spacing(2),
  },
  removeButton: {
    opacity: 0,
    transition: theme.transitions.create('opacity'),
  },
  iconButton: {
    width: 18,
    height: 18,
    '&:not(:last-child)': {
      marginRight: theme.spacing(2),
    },
  },
}));

const EducationMaterialViewer = (props: EducationMaterialViewerProps) => {
  const { education, query, selectable } = props;
  const [materials, setMaterials] = useState<EducationMaterial[]>([]);
  const [state, setState] = useState<{
    material: EducationMaterial | null;
    file: File | null;
    removeMaterialDialogOpen: boolean;
    removeFileDialogOpen: boolean;
    materialUploaderOpen: boolean;
  }>({
    material: null,
    file: null,
    removeMaterialDialogOpen: false,
    removeFileDialogOpen: false,
    materialUploaderOpen: false,
  });
  const { productContext, setProductContext } = useContext(ProductContext);
  const { selectedFiles } = productContext;
  const notifications = useSnackbar();
  const dispatch = useDispatch();
  const classes = useStyles();

  const {
    material,
    file,
    removeMaterialDialogOpen,
    removeFileDialogOpen,
    materialUploaderOpen,
  } = state;

  const handleRemoveMaterial = (material: EducationMaterial) => {
    setState({ ...state, material, removeMaterialDialogOpen: true });
  };

  const handleRemoveFile = (material: EducationMaterial, file: File) => {
    setState({ ...state, material, file, removeFileDialogOpen: true });
  };

  const handleClose = () => {
    setState({
      ...state,
      material: null,
      file: null,
      removeFileDialogOpen: false,
      removeMaterialDialogOpen: false,
    });
  };

  const handleOpenUploader = (material: EducationMaterial) => {
    setState({ ...state, material, materialUploaderOpen: true });
  };

  const handleUploaderClose = () => {
    setState({ ...state, material: null, materialUploaderOpen: false });
  };

  const doFileRemove = () => {
    if (!file || !material) {
      return;
    }

    ApiClient.delete(`/api/education-materials/${material.id}/files/${file.id}`)
      .then(() => {
        dispatch(refreshAccount(handleClose));
      })
      .catch(() => {
        notifications.enqueueSnackbar(
          'Er is iets fout gegaan bij het verwijderen van het bestand.',
          { variant: 'error' },
        );
      });
  };

  const doMaterialRemove = () => {
    if (!material) {
      return;
    }

    ApiClient.delete(`/api/education-materials/${material.id}`)
      .then(() => {
        dispatch(refreshAccount(handleClose));
      })
      .catch(() => {
        notifications.enqueueSnackbar(
          'Er is iets fout gegaan bij het verwijderen van het materiaal.',
          { variant: 'error' },
        );
      });
  };

  const handleAddFileToSelection = (file: File) => {
    if (!setProductContext) {
      return;
    }

    selectedFiles.push(file);
    setProductContext({ ...productContext, selectedFiles: [...selectedFiles] });
  };

  const handleRemoveFileFromSelection = (file: File) => {
    if (!setProductContext) {
      return;
    }

    const index = selectedFiles.findIndex((f) => f.id === file.id);
    selectedFiles.splice(index, 1);
    setProductContext({ ...productContext, selectedFiles: [...selectedFiles] });
  };

  useEffect(() => {
    setMaterials(
      education.materials
        .filter((material) => {
          if (!query) {
            return true;
          }

          return material.title.toLowerCase().includes(query.toLowerCase());
        })
        .sort((a, b) => (new Date(a.created) > new Date(b.created) ? -1 : 1)),
    );
  }, [education, query]);

  return (
    <div className={classes.root}>
      {material && (
        <MaterialUploader
          education={education}
          open={materialUploaderOpen}
          onClose={handleUploaderClose}
          onUpload={handleUploaderClose}
          material={material}
        />
      )}

      {material && (
        <ConfirmationDialog
          title="Materiaal verwijderen"
          query={`Weet je zeker dat je het materiaal ${material.title} wilt verwijderen?`}
          isOpen={removeMaterialDialogOpen}
          onClose={handleClose}
          onConfirm={doMaterialRemove}
        />
      )}

      {file && (
        <ConfirmationDialog
          title="Bestand verwijderen"
          query={`Weet je zeker dat je het bestand ${file.name} wilt verwijderen?`}
          isOpen={removeFileDialogOpen}
          onClose={handleClose}
          onConfirm={doFileRemove}
        />
      )}

      {materials.map((material) => (
        <div key={material.id} className={classes.material}>
          <div className={classes.materialHeader}>
            <div>{material.title}</div>
            <div>
              <IconButton
                onClick={() => handleRemoveMaterial(material)}
                className={`${classes.removeButton} ${classes.iconButton}`}
                size="small"
              >
                <FontAwesomeIcon icon={['fal', 'times']} />
              </IconButton>
              <IconButton
                onClick={() => handleOpenUploader(material)}
                size="small"
                className={classes.iconButton}
              >
                <FontAwesomeIcon icon={['fal', 'plus']} />
              </IconButton>
            </div>
          </div>
          <div className={classes.files}>
            {material.files.length === 0 && (
              <div className={classes.file}>
                Dit materiaal heeft geen bestanden.
              </div>
            )}

            {material.files
              .sort((a, b) =>
                new Date(a.created) > new Date(b.created) ? 1 : -1,
              )
              .map((file) => (
                <div key={file.id} className={classes.file}>
                  <div className={classes.fileName}>
                    <FileIcon file={file} className={classes.fileIcon} />
                    {file.name}
                  </div>
                  <div>
                    <IconButton
                      onClick={() => handleRemoveFile(material, file)}
                      className={`${classes.removeButton} ${classes.iconButton}`}
                      size="small"
                    >
                      <FontAwesomeIcon icon={['fal', 'times']} />
                    </IconButton>
                    {selectable &&
                      !selectedFiles.find((f) => f.id === file.id) && (
                        <IconButton
                          onClick={() => handleAddFileToSelection(file)}
                          className={`${classes.iconButton}`}
                          size="small"
                        >
                          <FontAwesomeIcon icon={['fal', 'plus-circle']} />
                        </IconButton>
                      )}
                    {selectable && selectedFiles.find((f) => f.id === file.id) && (
                      <IconButton
                        onClick={() => handleRemoveFileFromSelection(file)}
                        className={`${classes.iconButton}`}
                        size="small"
                      >
                        <FontAwesomeIcon icon={['fal', 'minus-circle']} />
                      </IconButton>
                    )}
                    <IconButton
                      href={getFileURL(file)}
                      target="_blank"
                      rel="noopener noreferrer"
                      size="small"
                      className={classes.iconButton}
                    >
                      <FontAwesomeIcon icon={['fal', 'cloud-download']} />
                    </IconButton>
                  </div>
                </div>
              ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default EducationMaterialViewer;
