import React, { useCallback, useContext } from 'react';
import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { FieldProps } from './FieldProps';
import Products from '../../education/portfolio/Products';
import { Assignment, File } from '../../../types';
import FormContext from '../FormContext';
import EducationContext from '../../education/EducationContext';
import { ProductFormEntryField } from '../types';
import ProductEntryDisplay from '../entry-display/ProductEntryDisplay';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginBottom: theme.spacing(2),
  },
  productSummary: {
    background: theme.palette.common.white,
  },
}));

const Product = (props: FieldProps) => {
  const { id } = props;
  const entryField = props.entryField as ProductFormEntryField | undefined;
  const classes = useStyles();
  const { education } = useContext(EducationContext);
  const { setFormData } = useContext(FormContext);

  const handleChange = useCallback(
    (assignments: Assignment[], files: File[]) => {
      if (!setFormData) {
        return;
      }

      setFormData((prev) => {
        const targetValue = {
          valid: true,
          value: JSON.stringify({
            assignments: assignments.map((assignment) => assignment.id),
            files: files.map((file) => file.id),
          }),
          options: [],
        };
        const currentJson = JSON.stringify(prev[id]);
        const targetJson = JSON.stringify(targetValue);

        if (currentJson === targetJson) {
          return prev;
        }

        return {
          ...prev,
          [id]: targetValue,
        };
      });
    },
    [id, setFormData],
  );

  return (
    <div className={classes.root}>
      {education ? (
        <Products
          education={education}
          selectable
          onSelectChange={handleChange}
          summaryClassName={classes.productSummary}
          selectedAssignments={entryField ? entryField.assignments : []}
          selectedFiles={entryField ? entryField.files : []}
        />
      ) : (
        <>
          {entryField !== undefined && (
            <ProductEntryDisplay
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...entryField}
            />
          )}
        </>
      )}
    </div>
  );
};

export default Product;
