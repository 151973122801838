import React, {
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import { Box, Button, Grid, Theme, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { grey, red } from '@material-ui/core/colors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import debounce from 'lodash/debounce';
import { useHistory } from 'react-router-dom';
import fields from './fields';
import { FieldState } from './fields/FieldProps';
import { CompleteUser, FormDataViolation } from '../../types';
import { FormFieldInterface } from './FormField';
import { Form as FormInterface, FormEntry } from './types';
import FormContext from './FormContext';
import {
  canAccessFormField,
  filterFormData,
  getInitialFormDataForEntry,
} from './helpers';
import FieldStart from './components/FieldStart';
import Loader from '../../components/Loader';
import AppContext from '../../AppContext';
import FormDescription from './FormDescription';
import ViolationsWarning from '../../components/form/ViolationsWarning';
import StyledAccordion from '../../components/StyledAccordion';
import useExpandableFormFields from './hooks/useExpandableFormFields';

interface FormProps {
  form: FormInterface;
  onSubmit: (
    formData: { [key: string]: FieldState },
    files?: FormContextFilesState,
  ) => void;
  onSave?: (
    formData: { [key: string]: FieldState },
    files?: FormContextFilesState,
    notify?: boolean,
    autoSave?: boolean,
  ) => void;
  title?: boolean;
  description?: boolean;
  submitLabel?: string;
  submitColor?: 'primary' | 'secondary';
  submitable?: boolean;
  beforeSubmit?: JSX.Element;
  readOnly?: boolean;
  entry?: FormEntry;
  violations?: FormDataViolation[];
  externalUser?: boolean;
  submitIcon?: React.ReactElement;
  saveIcon?: React.ReactElement;
  preview?: boolean;
  hideSubmit?: boolean;
  autosave?: boolean;
}

export interface FormContextState {
  [key: string]: FieldState;
}

export interface FormContextFilesState {
  [key: string]: { file: File; uploaded: boolean }[];
}

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    fontSize: theme.typography.h5.fontSize,
    fontWeight: theme.typography.fontWeightBold,
  },
  fields: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  field: {
    width: '100%',
  },
  fieldDescription: {
    overflowX: 'auto',
    maxWidth: '100%',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    '& > ul': {
      paddingLeft: theme.spacing(2),
    },
  },
  violationMessage: {
    backgroundColor: red[300],
  },
  fieldDisabled: {
    '& > *': {
      opacity: 0.65,
    },
    '& .MuiFormLabel-root': {
      color: grey[800],
    },
  },
  entryDisplay: {
    opacity: 1,
    marginTop: theme.spacing(3),
    padding: theme.spacing(4),
    background:
      'linear-gradient(340deg, hsl(209deg 40% 96%), hsl(209deg 40% 99%))',
    borderRadius: 12,
  },
}));

const Form = (props: FormProps) => {
  const history = useHistory();
  const classes = useStyles();
  const {
    form,
    onSubmit,
    onSave,
    title,
    description,
    submitLabel,
    submitColor,
    submitable,
    beforeSubmit,
    readOnly = false,
    entry,
    externalUser,
    saveIcon,
    submitIcon,
    preview,
    hideSubmit,
    autosave = true,
  } = props;

  const account = useSelector(
    (selector: {
      user: {
        account: CompleteUser | undefined;
      };
    }) => selector.user.account,
  );
  const [loading, setLoading] = useState<boolean>(true);
  const [formData, setFormData] = useState<FormContextState>({});
  const [violations, setViolations] = useState<FormDataViolation[]>(
    props.violations || [],
  );
  const [files, setFiles] = useState<FormContextFilesState>({});
  const formContextValue = useMemo(
    () => ({ formData, setFormData, files, setFiles }),
    [formData, setFormData, files, setFiles],
  );
  const { roleViewManager } = useContext(AppContext);

  const isSubmitable = submitable === undefined || submitable;

  const {
    expandedFieldIds,
    setExpandedFieldIds,
    atLeastOneFieldExpanded,
    determineExpandedFieldIds,
    toggleExpanded,
    toggleAllExpanded,
  } = useExpandableFormFields(form);

  const refreshPage = () => {
    history.go(0);
  };

  const isFormValid = () => {
    let isValid = true;

    Object.entries(formData).forEach(([, state]) => {
      if (!state.valid) {
        isValid = false;
      }
    });

    return isValid;
  };

  const handleSubmit = () => {
    setViolations([]);
    if (isFormValid()) {
      onSubmit(
        filterFormData(
          form,
          formData,
          account,
          roleViewManager.isParticipantView(),
        ),
        files,
      );
    }
  };

  const handleSave = (notify: boolean) => {
    setViolations([]);

    if (onSave) {
      onSave(
        filterFormData(
          form,
          formData,
          account,
          roleViewManager.isParticipantView(),
        ),
        files,
        notify,
        false,
      );
    }
  };

  useEffect(() => {
    setViolations(props.violations || []);
  }, [props.violations]);

  // Initialize form data from entry.
  useEffect(() => {
    if (!entry) {
      return;
    }

    const formData = getInitialFormDataForEntry(
      entry,
      account,
      roleViewManager.isParticipantView(),
    );
    setFormData(formData);

    setExpandedFieldIds(determineExpandedFieldIds(formData));

    setLoading(false);
  }, [entry, setExpandedFieldIds, determineExpandedFieldIds]);

  // Assume that there is no existing form entry if it wasn't loaded within 3 seconds
  // Without this workaround, the effect above was sometimes executed before the entry was loaded,
  // which prevented the initial form data from being set correctly
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 3000);
  }, []);

  const handleAutoSave = useCallback(
    debounce(
      (
        form: FormInterface,
        formData: FormContextState,
        files?: FormContextFilesState,
      ) => {
        if (onSave) {
          onSave(
            filterFormData(
              form,
              formData,
              account,
              roleViewManager.isParticipantView(),
            ),
            files,
            false,
            true,
          );
        }
      },
      1000,
    ),
    [account, roleViewManager],
  );

  useEffect(() => {
    if (autosave) {
      handleAutoSave(form, formData, files);
    }
  }, [formData, files]);

  if (loading) {
    return <Loader inline />;
  }

  const refreshIcon = <FontAwesomeIcon icon={['fal', 'sync']} />;

  const chevronIcon = (
    <FontAwesomeIcon
      icon={['fal', atLeastOneFieldExpanded ? 'chevron-up' : 'chevron-down']}
    />
  );

  return (
    <FormContext.Provider value={formContextValue}>
      {(title === undefined || title) && (
        <Typography variant="h4" className={classes.title} component="h1">
          {form.title}
        </Typography>
      )}
      {description && <FormDescription form={form} />}
      <Box mb={2} display="flex">
        <Button
          onClick={toggleAllExpanded}
          size="small"
          startIcon={chevronIcon}
        >
          {atLeastOneFieldExpanded
            ? 'Alle velden inklappen'
            : 'Alle velden uitklappen'}
        </Button>
        <Button onClick={refreshPage} size="small" startIcon={refreshIcon}>
          Ga terug naar de standaardinstelling
        </Button>
      </Box>
      <div className={classes.fields}>
        {form.fields.map((formField: FormFieldInterface) => {
          const field = fields.find((f) => f.type === formField.type);

          if (!field || !field.field) {
            return <div key={formField.id} />;
          }

          const Field = field.field;
          const entryField =
            entry &&
            entry.fields?.find(
              (formEntryField) => formEntryField.field.id === formField.id,
            );

          // Classes for the field wrapper.
          const fieldClasses = [classes.field, `form-field-type-${field.type}`];

          // The field should be visible when it's intended for the participant.
          // Or when the user has the roles needed to fill in the field.
          const showField =
            !readOnly &&
            canAccessFormField(
              formField,
              account,
              roleViewManager.isParticipantView(),
            );

          if (!showField) {
            fieldClasses.push(classes.fieldDisabled);
          }

          const violation = violations.find(
            (violation) => violation.parameters.fieldId === formField.id,
          );

          const fieldLabel = `${formField.label}${
            formField.required ? '*' : ''
          }`;

          let fieldDisplay: ReactNode;

          // If the user has no permissions to enter the field and
          // if there is a value in the latest entry, we show that value.
          if (!showField && entryField) {
            const Display = field.entryDisplay;

            fieldDisplay = entryField ? (
              <div className={classes.entryDisplay}>
                {Display !== undefined && (
                  <Display
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...entryField}
                  />
                )}
                {Display === undefined && entryField.value}
              </div>
            ) : null;
          } else {
            fieldDisplay = (
              <Field
                id={formField.id}
                label={formField.label}
                description={formField.description}
                options={formField.options || []}
                metadata={formField.metadata}
                entryField={entryField}
                disabled={!showField || preview}
              />
            );
          }

          return (
            <StyledAccordion
              id={`accordion-${formField.id}`}
              summary={<Box width="100%">{fieldLabel}</Box>}
              summaryClassName={
                violation ? classes.violationMessage : undefined
              }
              expanded={expandedFieldIds.includes(formField.id)}
              expandable
              onChange={() => toggleExpanded(formField.id)}
              chevron
              size="sm"
            >
              <div key={formField.id} className={fieldClasses.join(' ')}>
                <FieldStart formField={formField} violation={violation} />
                {fieldDisplay}
              </div>
            </StyledAccordion>
          );
        })}
      </div>
      {beforeSubmit !== undefined && beforeSubmit}
      {violations.length > 0 && <ViolationsWarning />}
      {!hideSubmit && (
        <Grid container spacing={2}>
          {!externalUser && !preview && (
            <Grid item>
              <Button
                variant="contained"
                color={submitColor || 'secondary'}
                type="submit"
                disabled={!isSubmitable}
                onClick={handleSubmit}
                startIcon={
                  submitIcon || (
                    <FontAwesomeIcon icon={['fal', 'paper-plane']} />
                  )
                }
              >
                {submitLabel || 'Inleveren'}
              </Button>
            </Grid>
          )}
          {externalUser && !preview && (
            <Grid item>
              <Button
                variant="contained"
                color="secondary"
                type="submit"
                disabled={!isSubmitable}
                onClick={() => handleSave(true)}
                startIcon={
                  saveIcon || <FontAwesomeIcon icon={['fad', 'save']} />
                }
              >
                Opslaan en gereed
              </Button>
            </Grid>
          )}
          {!preview && (
            <Grid item>
              <Button
                variant="outlined"
                color="default"
                type="submit"
                disabled={!isSubmitable}
                onClick={() => handleSave(false)}
                startIcon={
                  saveIcon || <FontAwesomeIcon icon={['fad', 'save']} />
                }
              >
                {roleViewManager.isParticipantView() &&
                  !externalUser &&
                  'Opslaan, later inleveren'}
                {roleViewManager.isEducatorView() &&
                  !externalUser &&
                  'Opslaan, later beoordelen'}
                {(externalUser ||
                  (!roleViewManager.isEducatorView() &&
                    !roleViewManager.isParticipantView())) &&
                  'Opslaan, later verder'}
              </Button>
            </Grid>
          )}
          {!externalUser && !preview && (
            <Box mt={2}>
              <Typography variant="body1">
                <em>
                  Nadat je de opdracht hebt ingeleverd kunnen er geen
                  wijzigingen meer doorgevoerd worden.
                </em>
              </Typography>
            </Box>
          )}
        </Grid>
      )}
    </FormContext.Provider>
  );
};

export default Form;
