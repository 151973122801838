import React, { useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import { Container, Grid, Theme } from '@material-ui/core';
import { orange } from '@material-ui/core/colors';
import { CompleteUser } from '../../types';
import EducationCard from './EducationCard';
import TextMediaHeader from '../../components/pageheader/TextMedia';
import AppContext from '../../AppContext';

const useStyles = makeStyles((theme: Theme) => ({
  grid: {
    marginTop: theme.spacing(3),
    textAlign: 'center',
  },
  introText: {
    maxWidth: '80%',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  container: {
    display: 'flex',
    marginTop: theme.spacing(5),
  },
  educationGrid: {
    maxWidth: '75%',
    [theme.breakpoints.down('md')]: {
      maxWidth: '100%',
    },
  },
  noEducation: {
    width: '100%',
    color: orange[600],
    textAlign: 'center',
  },
}));

const EducationOverview = () => {
  const history = useHistory();
  const classes = useStyles();
  const account = useSelector(
    (selector: {
      user: {
        account: CompleteUser;
      };
    }) => selector.user.account,
  );
  const { roleViewManager } = useContext(AppContext);

  const redirectUser = async () => {
    await roleViewManager.loadViewFromLocalStore();

    // ugly solution to send non-participant users to the right starting page
    if (!roleViewManager.isParticipantView()) {
      history.push(roleViewManager.getInitialRoute());
      return;
    }

    if (!account.educations) {
      return;
    }

    if (
      account.educations.length === 1 &&
      roleViewManager.isParticipantView()
    ) {
      history.push(`/onderwijs/${account.educations[0].id}`);
    }
  };

  useEffect(() => {
    redirectUser();
  }, [account, history]);

  return (
    <div>
      <TextMediaHeader name="Onderwijs">
        <p className={classes.introText}>
          Jij als deelnemer volgt of hebt meerdere opleidingen gevolgd. Dit
          scherm verschijnt alleen de eerste keer dat je inlogt. Door nu voor
          een opleiding te kiezen kun je alle volgende keren vanuit de onderwijs
          pulldown in de bovenste bar een opleiding selecteren. Zo kun je ook
          makkelijk switchen van opleiding.
        </p>
      </TextMediaHeader>
      <Container className={classes.container}>
        <Grid container className={classes.educationGrid} spacing={2}>
          {account.educations &&
            account.educations
              .filter((e) => !e.furtherEducation)
              .map((education) => (
                <Grid key={education.id} item md={4}>
                  <EducationCard education={education} />
                </Grid>
              ))}

          {(!account.educations || account.educations.length === 0) && (
            <div className={classes.noEducation}>
              Je staat momenteel (nog) niet ingeschreven voor onderwijs.
            </div>
          )}
        </Grid>
      </Container>
    </div>
  );
};

export default EducationOverview;
