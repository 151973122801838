import React, { ReactNode, useEffect } from 'react';
import { Box, Container, Grid, Theme, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import background from './assets/images/login-background.png';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: 'relative',
    width: '100%',
    minHeight: '100%',
    background: `url(${background})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    '&::before': {
      content: '""',
      position: 'absolute',
      width: '100%',
      height: '100%',
      opacity: 0.5,
      background: theme.palette.primary.main,
    },
  },
  container: {
    position: 'relative',
    zIndex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: 160,
    paddingBottom: theme.spacing(4),
    color: theme.palette.common.white,
  },
  content: {
    maxWidth: 600,
  },
  title: {
    fontWeight: 600,
    fontSize: 40,
    fontFamily: theme.typography.body1.fontFamily,
    marginBottom: theme.spacing(4),
  },
  description: {
    fontSize: 17,
  },
  form: {
    overflow: 'hidden',
    width: '100%',
    maxWidth: 375,
    minWidth: 375,
    borderRadius: 4,
    padding: theme.spacing(3),
    boxShadow: '1px 1px 2px 1px rgba(0, 0, 0, 0.09)',
    background: theme.palette.common.white,
  },
  formTitle: {
    display: 'flex',
    alignItems: 'center',
    height: 30,
    margin: -theme.spacing(3),
    marginBottom: theme.spacing(2),
    padding: `${theme.spacing(4)}px ${theme.spacing(3)}px`,
    fontWeight: 600,
    color: theme.palette.primary.contrastText,
    background: theme.palette.primary.main,
  },
}));

interface HomeProps {
  title: string;
  children: ReactNode;
}

const Home = (props: HomeProps) => {
  const classes = useStyles();

  useEffect(() => {
    document.body.classList.add('footer-no-margin');

    return () => document.body.classList.remove('footer-no-margin');
  }, []);

  return (
    <div className={classes.root}>
      <Container className={classes.container}>
        <Grid container justifyContent="center" spacing={5}>
          <Grid item xs={12} md={6} className={classes.content}>
            <Box height="100%" display="flex" alignItems="center">
              <Typography variant="h1" component="h1" className={classes.title}>
                Welkom bij de
                <br />
                leeromgeving MijnNSPOH
              </Typography>
            </Box>
          </Grid>

          <Grid item>
            <Box className={classes.form}>
              <Typography variant="h6" className={classes.formTitle}>
                {props.title}
              </Typography>
              <Box>{props.children}</Box>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default Home;
