import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import { ROUTE_INFO } from '../index';
import PageContainer from '../../../components/PageContainer';
import Loader from '../../../components/Loader';
import NewsRepository from './NewsRepository';
import NewsForm from './NewsForm';
import { NewsItemProps } from './NewsItem';

type Filter = {
  afasId: number;
  moodleCourseId?: number | null;
  name: string;
  participantAfasId?: number | null;
  practicalTrainerAfasId?: number | null;
  startDate?: string | null;
};

interface Filters {
  events: Filter[];
  educations: Filter[];
  roles: [];
}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    background: theme.palette.common.white,
    borderRadius: 4,

    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing(5),
      paddingBottom: theme.spacing(5),
      paddingRight: 120,
      paddingLeft: 120,
    },
  },
}));

const NewsAddView = () => {
  const notifications = useSnackbar();
  const history = useHistory();
  const classes = useStyles();

  const [filters, setFilters] = useState<Filters | null>(null);
  const [image, setFile] = useState<File>();
  const [files, setFiles] = useState<File[]>();
  const [loading, setLoading] = useState<boolean>(false);

  const news = new NewsRepository();

  /**
   * Fetch data from backend.
   */
  const fetch = async () => {
    setLoading(true);

    await news
      .getFilters()
      .then(({ data }) => {
        setFilters(data);
      })
      .catch(() => {
        notifications.enqueueSnackbar(
          'Er ging iets mis bij het ophalen van de filters',
          {
            variant: 'error',
          },
        );
      });

    setLoading(false);
  };

  useEffect(() => {
    fetch();
  }, []);

  /**
   * Handle file change.
   */
  const handleFileChange = (file: File) => {
    setFile(file);
  };

  /**
   * Handle file change.
   */
  const handleFilesChange = (files: File[]) => {
    setFiles(files);
  };

  /**
   * Handle image file upload.
   */
  const handleImageUpload = (id: string, file: File | undefined) => {
    if (!file) {
      return null;
    }

    const data = new FormData();
    data.append('image', file);

    return news.uploadImage(id, data).catch(() => {
      notifications.enqueueSnackbar(
        'Er ging iets mis bij het uploaden van de afbeelding naar het nieuwsbericht',
        {
          variant: 'error',
        },
      );
    });
  };

  /**
   * Handle dropzone files upload.
   */
  const handleFilesUpload = (id: string, files: File[] | undefined) => {
    if (!files) {
      return null;
    }

    const data = new FormData();
    files.forEach((file, index) => {
      data.append(`files[${index}]`, files[index]);
    });

    return news.uploadFiles(id, data).catch(() => {
      notifications.enqueueSnackbar(
        'Er ging iets mis bij het uploaden van de bestanden',
        {
          variant: 'error',
        },
      );
    });
  };

  /**
   * Handle form submit.
   */
  const handleSubmit = (data: NewsItemProps) => {
    news
      .create(data)
      .then(({ data }) => {
        notifications.enqueueSnackbar('Succesvol opgeslagen/gepubliceerd', {
          variant: 'success',
        });

        const imageUpload = handleImageUpload(data.id, image);
        const filesUpload = handleFilesUpload(data.id, files);

        return Promise.all([imageUpload, filesUpload]);
      })
      .then(() => {
        history.push(`${ROUTE_INFO}`);
      })
      .catch(() => {
        notifications.enqueueSnackbar(
          'Er ging iets mis bij het aanmaken van het nieuwsartikel',
          {
            variant: 'error',
          },
        );
      });
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <PageContainer
      containerClassName={classes.container}
      marginBottom
      title="Nieuws toevoegen"
    >
      <NewsForm
        filters={filters}
        onChangeFile={handleFileChange}
        onSubmit={handleSubmit}
        onUploadFiles={handleFilesChange}
      />
    </PageContainer>
  );
};

export default NewsAddView;
