import React, { useContext, useEffect, useMemo, useState } from 'react';
import {
  Box,
  Button,
  Collapse,
  Container,
  Typography,
} from '@material-ui/core';

import moment from 'moment/moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { makeStyles } from '@material-ui/styles';
import { green, orange } from '@material-ui/core/colors';
import { Link } from 'react-router-dom';
import AppContext from '../../AppContext';
import DataTable, { Column } from '../../components/DataTable';
import SearchContext from '../../components/search/SearchContext';
import SearchInput from '../../components/search/SearchInput';
import TextMedia from '../../components/pageheader/TextMedia';
import { getReadableAccessTokenStatus } from '../education/assignment/helpers';
import { AssignmentAccessTokenWithAssignment } from '../../types';
import ContributionRequestsRepository from './ContributionRequestsRepository';

const useStyles = makeStyles(() => ({
  bold: {
    fontWeight: 600,
  },
  icon: {
    fontSize: 20,
  },
  success: {
    color: green[300],
  },
  waiting: {
    color: orange[200],
  },
}));

const MyContributionRequestsOverview = () => {
  const classes = useStyles();

  const { localStore } = useContext(AppContext);

  const [query, setQuery] = useState<string>('');
  const [showMoreInfo, setShowMoreInfo] = useState<boolean>(true);

  const toggleMoreInfo = () => setShowMoreInfo((prev) => !prev);

  const repository = useMemo(() => new ContributionRequestsRepository(), []);

  const columns: Column[] = [
    {
      name: 'Datum',
      field: 'date',
      sortable: true,
      render: (token: AssignmentAccessTokenWithAssignment) =>
        moment(token.created).format('YYYY-MM-DD HH:mm'),
    },
    {
      name: 'Opdracht',
      field: 'assignment',
      sortable: true,
      render: (token: AssignmentAccessTokenWithAssignment) => (
        <Link
          to={`/onderwijs/opdracht/${token.assignment.id}?token=${token.token}`}
        >
          {token.assignment.name}
        </Link>
      ),
    },
    {
      name: 'Deelnemer',
      field: 'participant',
      sortable: true,
      render: (token: AssignmentAccessTokenWithAssignment) =>
        token.assignment.user.fullName,
    },
    {
      name: 'Status',
      field: 'status',
      sortable: true,
      render: (token: AssignmentAccessTokenWithAssignment) =>
        getReadableAccessTokenStatus(token),
    },
  ];

  const searchContextValue = useMemo(
    () => ({ query, setQuery }),
    [query, setQuery],
  );

  const chevronIcon = (
    <FontAwesomeIcon
      icon={['fal', showMoreInfo ? 'chevron-up' : 'chevron-down']}
    />
  );

  const description = (
    <>
      <Button onClick={toggleMoreInfo} size="small" startIcon={chevronIcon}>
        {showMoreInfo ? 'Beschrijving verbergen' : 'Beschrijving tonen'}
      </Button>
      <Collapse in={showMoreInfo} timeout="auto" unmountOnExit>
        <Box py={2}>
          <Typography>
            Wanneer jij op het mailadres waarmee je ook inlogt op MijnNSPOH een
            verzoek tot bijdrage hebt ontvangen, kun je dit verzoek openen
            vanuit de mail of door in dit overzicht op het betreffende verzoek
            te klikken. In sommige gevallen kun je als opleider bevraagd worden
            door middel van een verzoek tot bijdrage en krijg je op een later
            moment de opdracht via het menu Beoordelingen in MijnNSPOH.
          </Typography>
          <br />
          <Typography className={classes.bold}>
            Tot wanneer kan ik feedback geven?
          </Typography>
          <Typography>
            Zolang de deelnemer de opdracht niet heeft ingeleverd ter
            beoordeling kan er feedback gegeven worden. Is de opdracht
            ingeleverd en klik je op de link vanuit de mail, dan verschijnt een
            melding dat er geen wijzigingen meer gedaan kunnen worden. De regel
            in dit overzicht verdwijnt.
          </Typography>
          <br />
          <Typography className={classes.bold}>
            Kan ik als ontvanger de mail doorsturen naar een collega als dat
            beter uitkomt?
          </Typography>
          <Typography>
            Ja, een ieder die de mail ontvangt kan via de link de opdracht
            openen en bewerken. Dit werkt niet wanneer je de feedback levert
            vanuit MijnNSPOH. In de notificatie die naar de deelnemer gaat als
            een bijdrage geleverd is blijft wel de naam zichtbaar van degene aan
            wie het verzoek initieel gestuurd is. Wil je deze doorsturen naar
            een collega? Forward dan de mail.
          </Typography>
          <br />
          <Typography className={classes.bold}>Feedback geleverd?</Typography>
          <Typography>
            Ben je klaar met het leveren van de bijdrage dan klik je op
            &lsquo;Opslaan en gereed&rsquo;. Dit zorgt ervoor dat de deelnemer
            een notificatie krijgt dat een bijdrage is geleverd. Als je op een
            later moment verder wil dan klik je op &lsquo;Opslaan, later
            verder&rsquo;. Wel is het zo dat de feedback die al gegeven is
            direct zichtbaar is voor een deelnemer. De deelnemer ontvangt
            hiervan geen notificatie.
          </Typography>
        </Box>
      </Collapse>
    </>
  );

  useEffect(() => {
    localStore
      .getItem('my-contributions-show-info')
      .then((value) => setShowMoreInfo(value as boolean));
  }, [localStore]);

  useEffect(() => {
    localStore.setItem('my-contributions-show-info', showMoreInfo);
  }, [localStore, showMoreInfo]);

  return (
    <SearchContext.Provider value={searchContextValue}>
      <TextMedia
        name="Mijn bijdrageverzoeken"
        description={description}
        descriptionSpacing={0}
      />
      <Container>
        <Box display="flex" justifyContent="flex-end" mb={2}>
          <SearchInput
            id="my-contributions-query"
            placeholder="Zoek een opdracht..."
            persistQuery
          />
        </Box>
        <DataTable
          id="my-contributions"
          repository={repository}
          columns={columns}
          persistFilters
        />
      </Container>
    </SearchContext.Provider>
  );
};

export default MyContributionRequestsOverview;
