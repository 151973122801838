import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import {
  Box,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Theme,
  Tooltip,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { orange } from '@material-ui/core/colors';

import DataTable from '../../../components/DataTable';
import { Education, RequestAssignment, RequestModule } from '../../../types';
import RequestAssignmentRepository from '../assignment/repository/RequestAssignmentRepository';
import EducationContext from '../EducationContext';
import AssignmentStatusColumn from '../assignment/AssignmentStatusColumn';
import AssignmentLink from '../assignment/AssignmentLink';
import { FilterColumnOption } from '../../../components/filter-column/types';
import Loader from '../../../components/Loader';
import ExternalInviteDialog from '../assignment/ExternalInviteDialog';
import AssignmentCollaborationButton from '../assignment/AssignmentCollaborationButton';
import EducationRepository from '../EducationRepository';

interface MyRequestsOverviewProps {
  education: Education;
  onOpenForm: (module: RequestModule) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  warningIcon: {
    marginRight: theme.spacing(1),
    color: orange[400],
  },
  descriptionColumn: {
    minWidth: 300,
  },
}));

const MyRequestsOverview = (props: MyRequestsOverviewProps) => {
  const history = useHistory();
  const classes = useStyles();
  const { education, onOpenForm } = props;

  const [modules, setModules] = useState<RequestModule[] | undefined>(
    undefined,
  );

  const [statusOptions, setStatusOptions] = useState<
    FilterColumnOption[] | undefined
  >(undefined);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [externalInviteAssignment, setExternalInviteAssignment] =
    useState<RequestAssignment | null>(null);
  const { educationPermissions } = useContext(EducationContext);

  const handleExternalInviteDialogClose = () =>
    setExternalInviteAssignment(null);

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleMenuOpen = (e: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(e.currentTarget);
  };

  const requestAssignmentRepository = useMemo(
    () => new RequestAssignmentRepository(modules || []),
    [modules],
  );

  const columns = [
    {
      name: 'Omschrijving',
      field: 'name',
      render: (assignment: RequestAssignment) => (
        <AssignmentStatusColumn
          assignment={assignment}
          label={<AssignmentLink assignment={assignment} />}
          className={classes.descriptionColumn}
        />
      ),
    },
    {
      name: 'Type',
      field: 'module',
      filter: {
        type: 'checkbox' as const,
        options: (modules || []).map((module) => ({
          label: module.publishedName,
          value: module.id,
        })),
      },
      render: (assignment: RequestAssignment) =>
        assignment.module ? assignment.module.publishedName : '',
    },
    {
      name: 'Datum aanvraag',
      field: 'date',
      sortable: true,
      render: (assignment: RequestAssignment) =>
        moment(assignment.created).format('DD-MM-YYYY'),
    },
    {
      name: 'Status',
      field: 'status',
      filter: {
        type: 'checkbox' as const,
        options: statusOptions,
      },
      render: (assignment: RequestAssignment) => {
        let statusLabel;

        if (assignment.finished) {
          statusLabel = assignment.valuation;
        } else if (assignment.rejected) {
          statusLabel = (
            <div>
              <FontAwesomeIcon
                icon={['fal', 'exclamation-circle']}
                className={classes.warningIcon}
              />
              Opnieuw inleveren
            </div>
          );
        } else if (assignment.draft) {
          statusLabel = <i>Concept</i>;
        } else {
          statusLabel = 'Aangevraagd';
        }

        return statusLabel;
      },
    },
  ];

  const handleResubmit = (assignment: RequestAssignment) => {
    history.push(`/onderwijs/opdracht/${assignment.id}`);
  };

  const handleClickModule = (module: RequestModule) => {
    setAnchorEl(null);
    onOpenForm(module);
  };

  useEffect(() => {
    new EducationRepository()
      .getModules(education.id, ['AANVRAAG'])
      .then((response) => setModules(response.data as RequestModule[]));
  }, [education.id]);

  useEffect(() => {
    if (!modules) {
      return;
    }

    const options: FilterColumnOption[] = [
      { label: 'Aangevraagd', value: 'requested' },
      { label: 'Concept', value: 'concept' },
      { label: 'Opnieuw inleveren', value: 'rejected' },
    ];

    (modules || []).forEach((module) => {
      module.assignments.forEach((assignment) => {
        if (
          assignment.valuation &&
          !options.find((o) => o.value === assignment.valuation)
        ) {
          options.push({
            value: assignment.valuation,
            label: assignment.valuation,
          });
        }
      });
    });

    setStatusOptions(options);
  }, [modules]);

  if (!modules || !statusOptions) {
    return <Loader />;
  }

  return (
    <div>
      <Box display="flex" justifyContent="flex-end" width="100%">
        {educationPermissions.edit && (
          <Button
            variant="contained"
            color="secondary"
            startIcon={<FontAwesomeIcon icon={['fal', 'plus']} />}
            onClick={handleMenuOpen}
          >
            Nieuwe aanvraag
          </Button>
        )}
        <Menu
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          id="create-request-menu"
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={Boolean(anchorEl)}
          style={{ zIndex: 1401, top: 40 }}
          onClose={handleMenuClose}
          elevation={1}
        >
          {modules
            .sort((a, b) => a.publishedName.localeCompare(b.publishedName))
            .map((module) => (
              <MenuItem onClick={() => handleClickModule(module)}>
                {module.publishedName}
              </MenuItem>
            ))}
        </Menu>
      </Box>
      <Box p={1} pr={2}>
        {externalInviteAssignment && (
          <ExternalInviteDialog
            assignment={externalInviteAssignment}
            onClose={handleExternalInviteDialogClose}
            open
          />
        )}

        <DataTable
          id="my-requests"
          repository={requestAssignmentRepository}
          columns={columns}
          contained={false}
          defaultFilters={{
            query: '',
            filters: {},
            order: [
              {
                field: 'date',
                order: 'asc',
              },
            ],
          }}
          actions={(assignment: RequestAssignment, className: string) => (
            <>
              {assignment.rejected ? (
                <Tooltip title="Opnieuw aanvragen">
                  <IconButton
                    size="small"
                    onClick={() => handleResubmit(assignment)}
                    className={className}
                  >
                    <FontAwesomeIcon icon={['fal', 'redo']} />
                  </IconButton>
                </Tooltip>
              ) : null}
              {assignment.draft ? (
                <Tooltip title="Aanvraag bewerken">
                  <IconButton
                    size="small"
                    onClick={() => handleResubmit(assignment)}
                    className={className}
                  >
                    <FontAwesomeIcon icon={['fal', 'edit']} />
                  </IconButton>
                </Tooltip>
              ) : null}
              {(assignment.draft || assignment.rejected) &&
                assignment.form &&
                assignment.form.externalFields && (
                  <AssignmentCollaborationButton assignment={assignment} />
                )}
            </>
          )}
        />
      </Box>
    </div>
  );
};

export default MyRequestsOverview;
