import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import {
  Box,
  Button,
  Container,
  Tab,
  Tabs,
  Theme,
  Grid,
} from '@material-ui/core';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import educationReducer from '../educationReducer';
import { CompleteUser } from '../../../types';
import TabPanel from '../TabPanel';
import Loader from '../../../components/Loader';
import TextMediaHeader from '../../../components/pageheader/TextMedia';
import Portfolio from '../portfolio/Portfolio';
import URLHashParameters from '../../../utils/URLHashParameters';
import EducationRepository from '../EducationRepository';
import EducationContext from '../EducationContext';
import Downloads from '../education-information/Downloads';
import EducationInformation from '../education-information/EducationInformation';
import AppContext from '../../../AppContext';
import EducationEducatorContext from '../EducationEducatorContext';
import InstituteEducation from './InstituteEducation';
import PracticalEducation from './PracticalEducation';

interface ModuleOverviewProps {
  readOnly?: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  tabs: {
    '& button': {
      padding: `0 0 ${theme.spacing(1)}px 0`,
      marginRight: theme.spacing(2),
      fontWeight: theme.typography.fontWeightBold,
      fontSize: '1.05rem',
      textTransform: 'none',
      borderBottom: `2px solid ${theme.palette.grey[400]}`,
      '&:hover': {
        outline: 'none',
      },
    },
    '& .MuiTabs-indicator': {
      display: 'block',
    },
  },
  tab: {
    background: 'none',
    '&[aria-selected="true"]': {
      boxShadow: 'none',
      background: 'none',
    },
  },
}));

const ModuleOverview = (props: ModuleOverviewProps) => {
  const { id } = useParams<{ id: string }>();
  const classes = useStyles();
  const readOnly = props.readOnly !== undefined ? props.readOnly : false;
  const account = useSelector(
    (selector: {
      user: {
        account: CompleteUser;
      };
    }) => selector.user.account,
  );

  const [state, dispatch] = useReducer(educationReducer, {
    education: null,
    educationPermissions: {
      view: true,
      edit: false,
    },
  });
  const { localStore } = useContext(AppContext);
  const { setEducatorEducation } = useContext(EducationEducatorContext);

  const { education, educationPermissions } = state;

  const [activeTab, setActiveTab] = useState(
    URLHashParameters.get('tab', 'education') as string,
  );

  const educationContextValue = useMemo(
    () => ({
      education,
      educationPermissions,
    }),
    [education, educationPermissions],
  );

  const onChangeTab = (tab: string) => {
    setActiveTab(tab);
    URLHashParameters.set('tab', tab);
  };

  const fetchEducation = async () => {
    const response = await new EducationRepository().find(id);
    const { education, _permissions } = response.data;

    if (education.furtherEducation) {
      URLHashParameters.remove('tab');
      setTimeout(() => {
        setActiveTab('education');
      });
    }

    dispatch({
      type: 'SET_EDUCATION',
      payload: { education },
    });

    if (setEducatorEducation) {
      setEducatorEducation(education);
    }

    dispatch({
      type: 'SET_EDUCATION_PERMISSIONS',
      payload: {
        _permissions: {
          edit: readOnly ? false : _permissions.edit,
          view: _permissions.view,
          imitatingAdmin: Boolean(_permissions.imitatingAdmin),
        },
      },
    });

    const pageYOffset = await localStore.getItem<number>(
      `${education.id})_y_offset`,
    );

    if (pageYOffset) {
      window.scrollTo({
        top: pageYOffset,
        left: 0,
        behavior: 'smooth',
      });
    }
  };

  const persistScrollPosition = useCallback(() => {
    if (!education) {
      return;
    }

    localStore.setItem(`${education.id})_y_offset`, window.pageYOffset);
  }, [education, localStore]);

  useEffect(() => {
    window.addEventListener('scroll', persistScrollPosition, true);

    return () =>
      window.removeEventListener('scroll', persistScrollPosition, true);
  }, [persistScrollPosition]);

  /**
   * On load of Module overview.
   */
  useEffect(() => {
    fetchEducation();
  }, [id, account]);

  if (!education) {
    return <Loader />;
  }

  const description = (
    <Grid container spacing={3}>
      {education.furtherEducation ? (
        <Grid item>
          {education.user.id === account.id && (
            <Button
              variant="outlined"
              startIcon={<FontAwesomeIcon icon={['fal', 'award']} />}
              component={Link}
              to="/account#tab=certificaten"
            >
              Certificaten bekijken
            </Button>
          )}
        </Grid>
      ) : undefined}
      {!education.furtherEducation && (
        <Grid item>
          <Button
            startIcon={<FontAwesomeIcon icon={['fal', 'books']} />}
            component={Link}
            to={`/onderwijs/${education.id}/leermateriaal`}
            target="_blank"
            size="small"
            rel="noreferrer noopener"
          >
            Bekijk studiegids
          </Button>
        </Grid>
      )}
    </Grid>
  );

  return (
    <EducationContext.Provider value={educationContextValue}>
      <Box mb={2}>
        <TextMediaHeader
          name={
            education.furtherEducation ? 'Bij- en nascholing' : education.name
          }
          description={description}
          descriptionSpacing={0}
        >
          {!education.furtherEducation && (
            <Box mt={5}>
              <Tabs
                value={activeTab}
                onChange={(event, value: string) => onChangeTab(value)}
                className={classes.tabs}
              >
                <Tab
                  value="education"
                  label="Instituutsopleiding"
                  className={classes.tab}
                />
                {education.practicalModules && (
                  <Tab
                    value="praktijk"
                    label="Praktijkopleiding"
                    className={classes.tab}
                  />
                )}
                <Tab
                  value="portfolio"
                  label="Portfolio"
                  className={classes.tab}
                />
                <Tab
                  value="opleidingsgegevens"
                  label="Opleidingsgegevens"
                  className={classes.tab}
                />
                <Tab
                  value="downloads"
                  label="Downloads"
                  className={classes.tab}
                />
              </Tabs>
            </Box>
          )}
        </TextMediaHeader>
      </Box>
      <Container>
        <TabPanel value={activeTab} index="education">
          <InstituteEducation education={education} />
        </TabPanel>
        {!education.furtherEducation && (
          <TabPanel value={activeTab} index="praktijk">
            <PracticalEducation education={education} />
          </TabPanel>
        )}
        {!education.furtherEducation && (
          <TabPanel value={activeTab} index="portfolio">
            <Portfolio education={education} />
          </TabPanel>
        )}
        {!education.furtherEducation && (
          <TabPanel value={activeTab} index="opleidingsgegevens">
            <EducationInformation education={education} />
          </TabPanel>
        )}
        <TabPanel value={activeTab} index="downloads">
          <Downloads education={education} />
        </TabPanel>
      </Container>
    </EducationContext.Provider>
  );
};

export default ModuleOverview;
