import React, { useContext, useState } from 'react';
import { Box, IconButton, Theme, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import ProfessionalActivityLevels from './ProfessionalActivityLevels';
import StyledAccordion from '../../../components/StyledAccordion';
import { EPAAssignment, EPAModule } from '../../../types';
import { colors } from '../../../config/theme';
import EPAAssignmentRow from '../assignment/EPAAssignmentRow';
import EducationContext from '../EducationContext';
import FormPreviewIcon from '../../forms/FormPreviewIcon';
import DebugPopover from '../../../components/DebugPopover';

interface ProfessionalActivityPanelProps {
  module: EPAModule;
  setFormActivity: (form: any) => void;
  expanded: boolean;
  onChange?: (module: EPAModule, expanded: boolean) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  summary: {
    '&[aria-expanded="true"]': {
      '& $finishedIcon': {
        color: theme.palette.primary.contrastText,
      },
      '& $levelBarContainer': {
        bottom: -20,
      },
      '& $levelBar': {
        background: theme.palette.common.white,
      },
      '& $icon': {
        color: theme.palette.primary.contrastText,
      },
    },
  },
  levelBarContainer: {},
  levelBar: {},
  nameColumn: {
    width: '100%',
    maxWidth: 'calc(100% - 245px - 110px)',
  },
  levelColumn: {
    position: 'relative',
    display: 'flex',
    width: 250,
    minWidth: 250,
  },
  awardColumn: {
    width: 30,
    fontSize: 20,
    marginBottom: -5,
  },
  optionsColumn: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    width: 110,
  },
  icon: {
    color: theme.palette.primary.dark,
    marginLeft: theme.spacing(1),
  },
  finishedIcon: {
    color: colors.secondary.green,
  },
  assignments: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
  },
}));

const ProfessionalActivityPanel = (props: ProfessionalActivityPanelProps) => {
  const { module, setFormActivity, onChange } = props;
  const classes = useStyles();
  const [debugEl, setDebugEl] = useState<HTMLButtonElement | null>(null);
  const { educationPermissions } = useContext(EducationContext);

  const handleRequestClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    e.stopPropagation();
    setFormActivity(module);
  };

  let doing = module.currentLevel;

  for (let i = module.currentLevel; i < module.maxLevel; ++i) {
    module.assignments.forEach((assignment: EPAAssignment) => {
      if (
        assignment.assignedLevel === i + 1 &&
        !assignment.finished &&
        !assignment.notAssigned
      ) {
        doing = i;
      }
    });
  }

  const handleDebugOpen = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setDebugEl(e.currentTarget);
  };

  const handleDebugClose = () => setDebugEl(null);

  const summary = (
    <>
      <DebugPopover
        id={module.id}
        anchorEl={debugEl}
        onClose={handleDebugClose}
        properties={[
          { name: 'ID', value: module.id },
          { name: 'AFAS ID', value: module.afasId },
          { name: 'Module ID', value: module.moduleId },
          { name: 'Type', value: module.type },
        ]}
      />
      <div className={classes.nameColumn}>{module.publishedName}</div>
      <div className={classes.levelColumn}>
        <ProfessionalActivityLevels
          module={module}
          levelBarClassName={classes.levelBar}
          levelBarContainerClassName={classes.levelBarContainer}
        />
      </div>
      <div className={classes.awardColumn}>
        {module.currentLevel >= module.minLevel && (
          <FontAwesomeIcon
            icon={['fas', 'award']}
            className={`${classes.icon} ${classes.finishedIcon}`}
          />
        )}
      </div>
      <div className={classes.optionsColumn}>
        {educationPermissions.imitatingAdmin && (
          <Box mr={1}>
            <IconButton
              size="small"
              onClick={handleDebugOpen}
              className={classes.icon}
            >
              <FontAwesomeIcon icon={['fal', 'bug']} />
            </IconButton>
          </Box>
        )}
        {module.form && (
          <div>
            <FormPreviewIcon formId={module.form.id} />
          </div>
        )}
        <Tooltip title="Aanvragen">
          <IconButton
            size="small"
            onClick={handleRequestClick}
            disabled={!educationPermissions.edit || doing === module.maxLevel}
            className={classes.icon}
          >
            <FontAwesomeIcon icon={['fal', 'plus-circle']} />
          </IconButton>
        </Tooltip>
      </div>
    </>
  );

  return (
    <StyledAccordion
      id="professional-activity-panel-content"
      summary={summary}
      summaryClassName={classes.summary}
      expandable={module.assignments.length > 0}
      expanded={props.expanded}
      onChange={(e, expanded) => {
        if (onChange) {
          onChange(module, expanded);
        }
      }}
    >
      <div className={classes.assignments}>
        {module.assignments
          .sort((a, b) => (a.created > b.created ? 1 : -1))
          .map((assignment) => (
            <EPAAssignmentRow key={assignment.id} assignment={assignment} />
          ))}
      </div>
    </StyledAccordion>
  );
};

export default ProfessionalActivityPanel;
