import React, { useCallback, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { makeStyles } from '@material-ui/styles';
import { IconButton, Tooltip } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

import ContributionRequestsRepository from './ContributionRequestsRepository';
import { colors } from '../../config/theme';
import StyledBadge from '../../components/StyledBadge';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
  },
  button: {
    color: colors.primary.blue,
  },
}));

const MyContributionRequestsIcon = () => {
  const classes = useStyles();
  const history = useHistory();
  const [totalCount, setTotalCount] = useState<number>(0);

  const checkContributionRequests = useCallback(() => {
    new ContributionRequestsRepository()
      .findBy({
        filters: {
          statuses: 'new,used',
        },
      })
      .then((response) => {
        setTotalCount(response.data.totalItems);
      })
      .catch(() => {});
  }, []);

  useEffect(() => {
    checkContributionRequests();
    const checkInterval = setInterval(checkContributionRequests, 5 * 60 * 1000);

    return () => clearInterval(checkInterval);
  }, [checkContributionRequests]);

  return (
    <div className={classes.root}>
      <StyledBadge badgeContent={totalCount} color="secondary">
        <Tooltip title="Verzoek tot bijdrage">
          <IconButton
            size="small"
            className={classes.button}
            onClick={() => history.push('/mijn-bijdrageverzoeken')}
          >
            <FontAwesomeIcon icon={['fad', 'hands-helping']} />
          </IconButton>
        </Tooltip>
      </StyledBadge>
    </div>
  );
};

export default MyContributionRequestsIcon;
