import React, { useContext, useState } from 'react';
import moment from 'moment';
import { Box, Chip, Grid, IconButton, Theme, Tooltip } from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Assignment } from '../../../types';
import { colors } from '../../../config/theme';
import columnConfig from '../columnConfig';
import AssignmentReviewBadge from './AssignmentReviewBadge';
import AssignmentLink from './AssignmentLink';
import EducationContext from '../EducationContext';
import AssignmentValuation from './AssignmentValuation';
import DeleteAssignmentDialog from './DeleteAssignmentDialog';
import AssignmentCollaborationButton from './AssignmentCollaborationButton';

interface AssignmentRowProps {
  assignment: Assignment;
  variant: 'education' | 'further-education' | 'guidance';
  onRefresh?: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  row: {
    display: 'flex',
    width: '100%',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    borderBottom: `1px solid ${colors.primary.grey}`,
  },
  rowNoPadding: {
    paddingLeft: '0 !important',
  },
  content: {
    display: 'flex',
    width: '100%',
  },
  flagIcon: {
    float: 'left',
    marginRight: theme.spacing(1),
  },
  flagIconGreen: {
    color: green[200],
  },
  nameColumn: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    maxWidth: `calc(100% 
                      - 20%
                      - ${columnConfig.assignmentsColumn.minWidth}px
                      - ${columnConfig.statusColumn.width}px
                      - ${columnConfig.startDateColumn.minWidth}px
                      + 40px
               )`,
    wordBreak: 'break-word',
  },
  furtherEducationNameColumn: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    maxWidth: `calc(100% 
                      - 20%
                      - ${columnConfig.assignmentsColumn.minWidth}px
                      - ${columnConfig.furtherEducation.pointsColumn.minWidth}px
                      - ${columnConfig.statusColumn.width}px
                      - ${columnConfig.startDateColumn.minWidth}px
                      + 40px
               )`,
    wordBreak: 'break-word',
  },
  statusColumn: {
    width:
      columnConfig.statusColumn.width + columnConfig.assignmentsColumn.minWidth,
  },
  furtherEducationStatusColumn: {
    width:
      columnConfig.statusColumn.width +
      columnConfig.assignmentsColumn.minWidth +
      columnConfig.furtherEducation.pointsColumn.minWidth,
  },
  startDateColumn: columnConfig.startDateColumn,
  furtherEducationPointsColumn: columnConfig.furtherEducation.pointsColumn,
  options: {
    marginLeft: 'auto',
  },
}));

const AssignmentRow = (props: AssignmentRowProps) => {
  const { assignment, variant, onRefresh } = props;

  const classes = useStyles();
  const [state, setState] = useState<{
    externalInviteDialogOpen: boolean;
    deleteAssignmentDialogOpen: boolean;
  }>({
    externalInviteDialogOpen: false,
    deleteAssignmentDialogOpen: false,
  });
  const { educationPermissions } = useContext(EducationContext);

  const { deleteAssignmentDialogOpen } = state;

  const handleDeleteAssignmentDialogOpen = () =>
    setState({ ...state, deleteAssignmentDialogOpen: true });
  const handleDeleteAssignmentDialogClose = () =>
    setState({ ...state, deleteAssignmentDialogOpen: false });

  const handleDelete = () => {
    handleDeleteAssignmentDialogClose();
    if (onRefresh) {
      onRefresh();
    }
  };

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      className={`${classes.row} ${
        variant === 'further-education' || variant === 'guidance'
          ? classes.rowNoPadding
          : ''
      }`}
    >
      <div className={classes.content}>
        <div
          className={
            variant === 'further-education'
              ? classes.furtherEducationNameColumn
              : classes.nameColumn
          }
        >
          <AssignmentLink assignment={assignment} />

          {assignment.inReview && (
            <Box ml={1}>
              <AssignmentReviewBadge assignment={assignment} />
            </Box>
          )}
        </div>

        <div
          className={
            variant === 'further-education'
              ? classes.furtherEducationStatusColumn
              : classes.statusColumn
          }
        >
          <AssignmentValuation assignment={assignment} />
        </div>

        <div className={classes.startDateColumn}>
          {assignment.endDate && !assignment.finished && (
            <Chip
              label={`Deadline ${moment(assignment.endDate).format(
                'DD-MM-YYYY',
              )}`}
              color="secondary"
            />
          )}
        </div>
        <div className={classes.options}>
          <Grid container spacing={1} justifyContent="flex-end">
            {assignment.form && assignment.form.externalFields && (
              <AssignmentCollaborationButton assignment={assignment} />
            )}
            {variant === 'guidance' &&
              educationPermissions.edit &&
              !assignment.finished &&
              assignment.entries.length === 0 && (
                <Grid item>
                  <DeleteAssignmentDialog
                    assignment={assignment}
                    open={deleteAssignmentDialogOpen}
                    onClose={handleDeleteAssignmentDialogClose}
                    onDelete={handleDelete}
                  />

                  <Tooltip title="Opdracht verwijderen">
                    <IconButton
                      size="small"
                      onClick={handleDeleteAssignmentDialogOpen}
                    >
                      <FontAwesomeIcon icon={['fal', 'trash']} />
                    </IconButton>
                  </Tooltip>
                </Grid>
              )}
          </Grid>
        </div>
      </div>
    </Box>
  );
};

export default AssignmentRow;
