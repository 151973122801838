import React, { useEffect, useMemo, useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles, ThemeProvider } from '@material-ui/styles';
import { useSnackbar } from 'notistack';

import './App.scss';
import './config/icons';
import localforage from 'localforage';
import { AxiosResponse } from 'axios';
import { Box } from '@material-ui/core';
import theme, { colors } from './config/theme';

import Navigation from './Navigation';
import { Route, Router } from './routing';
import { Account, CompleteUser } from './types';
import Error404 from './components/error/Error404';

// Modules
import './modules/afas';
import './modules/agenda';
import './modules/api-key';
import './modules/education';
import './modules/educator-events';
import './modules/evaluation';
import './modules/forms';
import './modules/my-contribution-requests';
import './modules/my-participants';
import './modules/notifications';
import './modules/users';
import './modules/info';

import Login from './Login';
import UserRepository from './modules/users/UserRepository';
import { setAccount, updateEducation } from './actions';
import { UserState } from './reducers/user';
import EducationOverview from './modules/education/EducationOverview';
import Loader from './components/Loader';
import AppContext from './AppContext';
import Footer from './Footer';
import RoleViewManager from './RoleViewManager';
import NullUser from './modules/users/NullUser';
import UserImitator from './modules/users/UserImitator';
import ErrorHandler from './ErrorHandler';
import TwoFactorWall from './modules/users/TwoFactorWall';

// Router
Router.setNotFoundComponent(<Error404 />);
Router.addRoute(new Route('Login', '/', <Login />).anonymous());
Router.addRoute(
  new Route('Opleiding', '/', <EducationOverview />).requireLogin(),
);

export interface AppState {
  user: CompleteUser | null;
  loaded: boolean;
  errorStatusCode: number | null;
  errorDetails: React.ReactElement | null;
}

const useStyles = makeStyles(() => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    // Always show sidebar to prevent jumps
    minHeight: 'calc(100vh + 1px)',
    position: 'relative',
  },
  content: {
    color: colors.primary.blue,
    display: 'flex',
    flexWrap: 'wrap',
    flex: '1 0 auto',
  },
  yellowArc: {
    backgroundColor: colors.secondary.yellow,
    position: 'absolute',
    top: '70px',
    right: '0px',
    width: '200px',
    height: '160px',
    borderBottomLeftRadius: '100%',
  },
}));

function App() {
  const classes = useStyles();
  const { isLoggedIn, isImitating, account, requestTwoFactorConfiguration } =
    useSelector((selector: { user: UserState }) => ({
      isLoggedIn: selector.user.isLoggedIn,
      isImitating: selector.user.account && selector.user.account.isImitating,
      account: selector.user.account,
      requestTwoFactorConfiguration:
        selector.user.requestTwoFactorConfiguration,
    }));
  const notifications = useSnackbar();
  const dispatch = useDispatch();

  const [state, setState] = useState<AppState>({
    user: null,
    loaded: false,
    errorStatusCode: null,
    errorDetails: null,
  });

  const localStore = useMemo(
    () => localforage.createInstance({ name: 'mijn-nspoh' }),
    [],
  );
  const roleViewManager = useMemo(
    () => new RoleViewManager(account || NullUser, undefined, localStore),
    [account],
  );

  /**
   * Attempt to get account data from API.
   */
  const fetchAccount = async () => {
    const repository = new UserRepository();

    if (!account) {
      let accountResponse: AxiosResponse<Account>;

      try {
        accountResponse = await repository.getAccount();
      } catch (e) {
        notifications.enqueueSnackbar(
          'Er is iets fout gegaan bij het inloggen!',
          { variant: 'error' },
        );
        return;
      }

      dispatch(setAccount(accountResponse.data));
      setState({
        ...state,
        user: accountResponse.data,
      });
    }

    const imitator = new UserImitator(
      dispatch,
      roleViewManager,
      localStore,
      account?.isImitating || false,
    );
    await imitator.isImitating();
  };

  /**
   * Trigger when isLoggedIn or isImitating changes.
   */
  useEffect(() => {
    if (!isLoggedIn) {
      setState({
        ...state,
        user: null,
        loaded: true,
      });
      return;
    }

    fetchAccount();
  }, [isLoggedIn, isImitating]);

  useEffect(() => {
    // Load prefered view from local store.
    roleViewManager.loadViewFromLocalStore(() => {
      setState({ ...state, loaded: true });
    });

    // Set the prefered education.
    if (account && account.educations !== undefined) {
      localStore.getItem('education').then((value) => {
        if (!value) {
          return;
        }

        dispatch(
          updateEducation(
            account.educations!.find((e) => e.id === value) || null,
            localStore,
          ),
        );
      });
    }
  }, [account, isLoggedIn]);

  const routes = useMemo(() => {
    if (account && requestTwoFactorConfiguration) {
      return <TwoFactorWall account={account} />;
    }

    return (
      <BrowserRouter>
        <Navigation />
        <ErrorHandler />
        {state.errorStatusCode === null && Router.getSwitch(account)}
      </BrowserRouter>
    );
  }, [account, state.errorStatusCode, requestTwoFactorConfiguration]);

  const appContextValue = useMemo(
    () => ({
      appState: state,
      setAppState: setState,
      roleViewManager,
      localStore,
    }),
    [state, roleViewManager, localStore],
  );

  if (!state.loaded) {
    return <Loader />;
  }

  return (
    <AppContext.Provider value={appContextValue}>
      <ThemeProvider theme={theme}>
        <div className={classes.wrapper}>
          <Box className={classes.content} paddingBottom={isLoggedIn ? 6 : 0}>
            <Box width="100%">{routes}</Box>
          </Box>
          {isLoggedIn && <div className={classes.yellowArc} />}
          <Footer />
        </div>
      </ThemeProvider>
    </AppContext.Provider>
  );
}

export default App;
