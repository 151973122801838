import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';
import { Flow as IFlow } from 'flowjs';
// @ts-ignore
import Flow from '@flowjs/flow.js';
import {
  Box,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Theme,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import { uniqueId } from 'lodash';
import Form, { FormContextFilesState } from '../../yard-forms/Form';
import { Form as FormInterface } from '../../yard-forms/types';
import FormRepository from '../../forms/FormRepository';
import { FieldState } from '../../yard-forms/fields/FieldProps';
import { EPAAssignment, EPAModule, FormDataViolation } from '../../../types';
import ModuleRepository from '../module/ModuleRepository';
import { refreshAccount } from '../../../actions';
import AssignmentDelivery, {
  AssignmentEducatorOption,
} from '../assignment/AssignmentDelivery';
import EducationContext from '../EducationContext';
import AssignmentDialog from '../assignment/AssignmentDialog';
import { handleFlowFileUploadForAssignmentForm } from '../assignment/helpers';
import FlowProgress from '../../../components/file/FlowProgress';
import { DialogMessage } from '../assignment/AssignmentDialogMessage';
import AppContext from '../../../AppContext';
import AssignmentSubmitMessageWarningDialog from '../assignment/AssignmentSubmitMessageWarningDialog';

interface ProfessionalActivityFormProps {
  module: EPAModule;
  onSubmit?: () => void;
}

type EpaAction = 'submit' | 'save';

const useStyles = makeStyles((theme: Theme) => ({
  loader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: 200,
  },
  formContainer: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1),
    padding: theme.spacing(2),
    background: theme.palette.common.white,
  },
  levelControl: {
    marginTop: theme.spacing(1),
  },
}));

const ProfessionalActivityForm = (props: ProfessionalActivityFormProps) => {
  const { module, onSubmit } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const notifications = useSnackbar();
  const [form, setForm] = useState<FormInterface | null>(null);

  const availableLevels: number[] = useMemo(() => {
    // consider a level previously requested if an assignment exists for it,
    // except when such an assignment has been rejected,
    // which means the participant can request that level again
    const previouslyRequestedLevels = module.assignments
      .filter((assignment: EPAAssignment) => !assignment.notAssigned)
      .map((assignment: EPAAssignment) => assignment.assignedLevel);

    const levels: number[] = [];
    for (let i = module.currentLevel + 1; i <= module.maxLevel; ++i) {
      if (!previouslyRequestedLevels.includes(i)) {
        levels.push(i);
      }
    }
    return levels;
  }, [module.currentLevel, module.maxLevel, module.assignments]);

  const [level, setLevel] = useState<string>(
    `level-${availableLevels.length >= 1 ? availableLevels[0] : 0}`,
  );
  const [messages, setMessages] = useState<DialogMessage[]>([]);
  const formRepository = new FormRepository();
  const [delivery, setDelivery] = useState<AssignmentEducatorOption[]>([]);
  const [violations, setViolations] = useState<FormDataViolation[]>([]);
  const [saving, setSaving] = useState<boolean>(false);
  const [messageState, setMessageState] = useState<{
    message: string;
    dirty: boolean;
    warningDialogOpen: boolean;
    storedAction: EpaAction | null;
    storedArguments: IArguments | null;
  }>({
    message: '',
    dirty: false,
    warningDialogOpen: false,
    storedAction: null,
    storedArguments: null,
  });
  const [proceed, setProceed] = useState<boolean>(false);
  const { roleViewManager } = useContext(AppContext);

  const { education } = useContext(EducationContext);

  const flow: IFlow = new Flow({
    target: `${process.env.REACT_APP_API_URL}/api/file`,
    withCredentials: true,
  });

  const handleLevelChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLevel((event.target as HTMLInputElement).value);
  };

  const handleDeliveryChange = (educators: AssignmentEducatorOption[]) =>
    setDelivery(educators);

  const handleSubmitOrSaveAssignment = (
    formData: { [key: string]: FieldState },
    submit: boolean,
    files?: FormContextFilesState,
  ) => {
    if (!education) {
      return;
    }

    const parsedLevel = parseInt(level.replace('level-', ''), 10);

    setSaving(true);

    const doCreate = () => {
      new ModuleRepository()
        .createEPAAssignment(
          module,
          parsedLevel,
          submit,
          formData,
          delivery.map((e) => e.id),
          messages.map((m) => m.content),
        )
        .then(() => {
          notifications.enqueueSnackbar(
            'De aanvraag is succesvol aangemaakt!',
            {
              variant: 'success',
            },
          );
          dispatch(refreshAccount());
          if (onSubmit) {
            onSubmit();
          }
        })
        .catch((err) => {
          notifications.enqueueSnackbar(
            'Er is iets fout gegaan bij het aanmaken van de opdracht!',
            { variant: 'error' },
          );

          if (err.response.data.detail === 'validation_failed') {
            setViolations(err.response.data.violations);
          } else if (onSubmit) {
            onSubmit();
          }
        })
        .finally(() => setSaving(false));
    };

    if (files) {
      handleFlowFileUploadForAssignmentForm(flow, formData, files, doCreate);
    } else {
      doCreate();
    }
  };

  function handleSubmit(
    formData: { [key: string]: FieldState },
    files?: FormContextFilesState,
    dirty?: boolean,
  ) {
    if (dirty === undefined ? messageState.dirty : dirty) {
      setMessageState({
        ...messageState,
        warningDialogOpen: true,
        storedAction: 'submit',
        // eslint-disable-next-line prefer-rest-params
        storedArguments: arguments,
      });

      return;
    }

    handleSubmitOrSaveAssignment(formData, true, files);
  }

  function handleSave(
    formData: { [key: string]: FieldState },
    files?: FormContextFilesState,
    notify?: boolean,
    dirty?: boolean,
  ) {
    if (dirty === undefined ? messageState.dirty : dirty) {
      setMessageState({
        ...messageState,
        warningDialogOpen: true,
        storedAction: 'save',
        // eslint-disable-next-line prefer-rest-params
        storedArguments: arguments,
      });

      return;
    }

    handleSubmitOrSaveAssignment(formData, false, files);
  }

  const handleNewMessage = (message: string) => {
    setMessageState({ ...messageState, dirty: false, message: '' });
    setMessages([
      ...messages,
      {
        id: uniqueId(),
        content: message,
        created: new Date().toISOString(),
        user: roleViewManager.getUser(),
      },
    ]);
  };

  const handleEditMessage = (message: DialogMessage, newContent: string) => {
    const index = messages.findIndex((m) => m.id === message.id);

    if (index === undefined) {
      return;
    }

    const newMessages = [...messages];
    newMessages[index].content = newContent;

    setMessages(newMessages);
  };

  const handleDeleteMessage = (message: DialogMessage) => {
    const index = messages.findIndex((m) => m.id === message.id);

    if (index === undefined) {
      return;
    }

    const newMessages = [...messages];
    newMessages.splice(index, 1);

    setMessages(newMessages);
  };

  const handleChangeMessage = (message: string) => {
    setMessageState({ ...messageState, message, dirty: true });
  };

  const handleSubmitWithoutMessage = () => {
    setProceed(true);
  };

  const handleSubmitWithMessage = () => {
    handleNewMessage(messageState.message);
    setMessageState({
      ...messageState,
      warningDialogOpen: false,
      message: '',
      dirty: false,
    });
    setProceed(true);
  };

  useEffect(() => {
    if (!proceed) {
      return;
    }

    const { storedArguments, storedAction } = messageState;

    if (!storedArguments) {
      return;
    }

    switch (storedAction) {
      case 'save':
        // @ts-ignore
        handleSave(...storedArguments, false);
        break;
      case 'submit':
        // @ts-ignore
        handleSubmit(...storedArguments, false);
        break;
      default:
        break;
    }

    setProceed(false);
  }, [proceed]);

  useEffect(() => {
    if (!module.form) {
      return;
    }

    formRepository
      .find(module.form.id)
      .then((response) => setForm(response.data));
  }, [module]);

  const beforeSubmit = (
    <Box mt={2} mb={2}>
      <AssignmentDialog
        messages={messages}
        onNewMessage={handleNewMessage}
        onEdit={handleEditMessage}
        onDelete={handleDeleteMessage}
        onChange={handleChangeMessage}
      />
      <AssignmentSubmitMessageWarningDialog
        open={messageState.warningDialogOpen}
        message={messageState.message}
        onSubmit={handleSubmitWithoutMessage}
        onSubmitWithMessage={handleSubmitWithMessage}
      />
      <Box mt={2}>
        <AssignmentDelivery onChange={handleDeliveryChange} po io />
      </Box>
      <FlowProgress flow={flow} />
    </Box>
  );

  return (
    <div>
      {!form && (
        <div className={classes.loader}>
          <CircularProgress />
        </div>
      )}
      {form && (
        <>
          <Typography variant="h6" component="h2">
            Aanvragen
          </Typography>
          <FormControl component="fieldset" className={classes.levelControl}>
            <FormLabel component="legend">Vraag een niveau aan:</FormLabel>
            <RadioGroup
              row
              aria-label="niveau"
              onChange={handleLevelChange}
              value={level}
            >
              {availableLevels.map((level: number) => (
                <FormControlLabel
                  key={level}
                  value={`level-${level}`}
                  control={<Radio color="default" />}
                  label={level}
                />
              ))}
            </RadioGroup>
          </FormControl>
          <Form
            form={form}
            title={false}
            onSubmit={handleSubmit}
            onSave={handleSave}
            submitable={!saving}
            beforeSubmit={beforeSubmit}
            submitLabel="Dien aanvraag in"
            submitColor="secondary"
            violations={violations}
            description
            autosave={false}
          />
        </>
      )}
    </div>
  );
};

export default ProfessionalActivityForm;
