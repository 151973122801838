import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core';
import { MinimalUser, User } from '../../types';

interface AvatarProps {
  user: MinimalUser | User;
  imitating?: boolean;
  className?: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  avatarImage: {
    position: 'relative',
    display: 'flex',
    overflow: 'hidden',
    width: 36,
    height: 36,
    borderRadius: '50%',
    '& > img': {
      objectFit: 'cover',
      objectPosition: 'center',
      width: '100%',
      height: '100%',
    },
  },
  imitatorGhost: {
    position: 'absolute',
    zIndex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    top: 4,
    left: 32,
    width: 8,
    height: 8,
    padding: theme.spacing(1),
    borderRadius: '50%',
    background: theme.palette.common.white,
    color: theme.palette.common.black,
    border: `1px solid ${theme.palette.common.black}`,
  },
}));

const Avatar = (props: AvatarProps) => {
  const { user, imitating, className } = props;
  const classes = useStyles();

  const classNames = [className];

  return (
    <div className={classNames.join(' ')}>
      {imitating && (
        <div className={classes.imitatorGhost}>
          <FontAwesomeIcon
            icon={['fal', 'ghost']}
            style={{ width: 14, height: 14 }}
          />
        </div>
      )}
      {user.avatar && (
        <div className={classes.avatarImage}>
          <img src={user.avatar} alt="" />
        </div>
      )}
      {!user.avatar && (
        <FontAwesomeIcon
          icon={['fal', 'user-circle']}
          style={{ width: 36, height: 36 }}
        />
      )}
    </div>
  );
};

export default Avatar;
