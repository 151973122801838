import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, Link } from 'react-router-dom';
import {
  Box,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  IconButton,
  Tooltip,
} from '@material-ui/core';
import moment, { Moment } from 'moment';
import { useSnackbar } from 'notistack';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DataTable, { Column } from '../../components/DataTable';
import Loader from '../../components/Loader';
import EvaluationRepository from './EvaluationRepository';
import {
  ApiFilterCriteria,
  Assignment,
  CompleteUser,
  Filters,
} from '../../types';
import AppContext from '../../AppContext';
import TextMedia from '../../components/pageheader/TextMedia';
import EvaluatorEditor from './EvaluatorEditor';
import AssignmentStatusColumn from '../education/assignment/AssignmentStatusColumn';
import { FilterColumnOption } from '../../components/filter-column/types';
import SearchContext from '../../components/search/SearchContext';
import SearchInput from '../../components/search/SearchInput';
import AssignmentStates from '../education/assignment/AssignmentStates';

const EvaluationOverview = () => {
  const history = useHistory();
  const { roleViewManager } = useContext(AppContext);
  const { account } = useSelector(
    (selector: { user: { account: CompleteUser } }) => ({
      account: selector.user.account,
    }),
  );
  const notifications = useSnackbar();

  const [startDateMoment, setStartDateMoment] = useState<Moment | null>(null);

  const [query, setQuery] = useState<string>('');
  const [resetDialogState, setResetDialogState] = useState<{
    open: boolean;
    assignment: Assignment | null;
  }>({
    open: false,
    assignment: null,
  });

  const repository = useMemo(() => new EvaluationRepository(), []);

  const [valuationOptions, setValuationOptions] = useState<
    FilterColumnOption[] | undefined
  >(undefined);

  const refreshPage = () => {
    history.go(0);
  };

  const searchContextValue = useMemo(
    () => ({ query, setQuery }),
    [query, setQuery],
  );

  const handleFiltersChange = (criteria: ApiFilterCriteria) => {
    const filterValue = criteria.filters ? criteria.filters.startDate : null;

    setStartDateMoment(
      filterValue && typeof filterValue === 'string'
        ? moment(filterValue)
        : null,
    );
  };

  const handleResetEvaluation = () => {
    if (!resetDialogState.assignment) {
      return;
    }

    repository
      .deleteEvaluation(resetDialogState.assignment)
      .then(() => {
        refreshPage();
        notifications.enqueueSnackbar('De opdracht is heropend.', {
          variant: 'success',
        });
      })
      .catch(() => {
        notifications.enqueueSnackbar('Fout bij heropenen van de opdracht.', {
          variant: 'error',
        });
      })
      .finally(() => setResetDialogState({ open: false, assignment: null }));
  };

  const handleCloseResetEvaluationDialog = () =>
    setResetDialogState({ ...resetDialogState, open: false });
  const handleOpenResetEvaluationDialog = (assignment: Assignment) =>
    setResetDialogState({
      assignment,
      open: true,
    });

  const columns: Column[] = [
    {
      name: 'Status',
      field: 'status',
      sortable: true,
      filter: {
        type: 'checkbox' as const,
        options: Object.entries(AssignmentStates).map(([state, label]) => ({
          label,
          value: state,
        })),
      },
      render: (assignment: Assignment) => (
        <AssignmentStatusColumn assignment={assignment} />
      ),
    },
    {
      name: 'Opdracht',
      field: 'name',
      sortable: true,
      render: (assignment: Assignment) => {
        if (assignment.form) {
          return (
            <Link to={`/beoordelingen/${assignment.id}`}>
              {assignment.name}
            </Link>
          );
        }

        return assignment.name;
      },
    },
    {
      name: 'Ingeleverd',
      field: 'date',
      sortable: true,
      render: (assignment: Assignment) => {
        return assignment.deliveryDate
          ? moment(assignment.deliveryDate).format('DD-MM-YYYY')
          : '-';
      },
    },
    {
      name: 'Startdatum',
      field: 'startDate',
      sortable: true,
      filter: {
        type: 'datepicker' as const,
      },
      render: (assignment: Assignment) => {
        if (startDateMoment && startDateMoment.isValid()) {
          return startDateMoment.format('DD-MM-YYYY');
        }

        if (
          !assignment.module ||
          !assignment.module.events ||
          assignment.module.events.length === 0
        ) {
          return '-';
        }

        const earliestEvent = assignment.module.events.sort((a, b) =>
          new Date(a.startDate) > new Date(b.startDate) ? 1 : -1,
        )[0];

        return moment(earliestEvent.startDate).format('DD-MM-YYYY');
      },
    },
    {
      name: 'Module',
      field: 'module',
      sortable: true,
      filter: {
        type: 'autocomplete' as const,
        config: {
          autoCompletePlaceholder: 'typ minimaal drie tekens',
        },
        searchOptions: async (query: string) => {
          if (query.length < 3) {
            return [];
          }

          const response = await repository.getModules(query);

          return response.data;
        },
      },
      render: (assignment: Assignment) => {
        if (assignment.module) {
          return assignment.module.name;
        }

        if (
          assignment.assignmentContainer &&
          assignment.assignmentContainer.module
        ) {
          return assignment.assignmentContainer.module.name;
        }

        return '-';
      },
    },
    {
      name: 'Deelnemer',
      field: 'participant',
      sortable: true,
      filter: {
        type: 'autocomplete' as const,
        config: {
          autoCompletePlaceholder: 'typ minimaal drie tekens',
        },
        searchOptions: async (query: string) => {
          if (query.length < 3) {
            return [];
          }

          const response = await repository.getParticipants(query);

          return response.data;
        },
      },
      render: (assignment: Assignment) => assignment.user.fullNameLastNameFirst,
    },
    {
      name: 'Beoordeling',
      field: 'valuation',
      sortable: true,
      filter: {
        type: 'checkbox' as const,
        options: async () => {
          const response = await repository.getOptions();

          return response.data
            .sort((a, b) => a.label.localeCompare(b.label))
            .map((option) => ({
              value: option.label,
              label: option.label,
            }));
        },
      },
      render: (assignment: Assignment) => {
        if (assignment.valuation && assignment.grade) {
          return `${assignment.valuation} (${assignment.grade})`;
        }

        if (assignment.valuation) {
          return assignment.valuation;
        }

        return assignment.grade;
      },
    },
  ];

  if (roleViewManager.hasPermission('UPDATE_EVALUATORS')) {
    columns.push({
      name: 'Beoordelaar',
      field: 'evaluator',
      sortable: false,
      filter: {
        type: 'autocomplete' as const,
        options: async () => {
          const response = await repository.getEvaluators();

          return response.data.map((evaluator) => ({
            value: evaluator.id!,
            label: evaluator.fullNameLastNameFirst!,
            metadata: `${evaluator.fullName};${evaluator.afasId}`,
          }));
        },
      },
      render: (assignment: Assignment) => {
        if (
          !assignment.assignedEvaluators ||
          assignment.assignedEvaluators.length === 0
        ) {
          return '-';
        }

        return assignment.assignedEvaluators
          .map((e) => e.evaluator.fullNameLastNameFirst)
          .join(', ');
      },
    });
  }

  const itemActions = (assignment: Assignment, className: string) => (
    <>
      {roleViewManager.isEducatorView() &&
        assignment.permissions.includes('UPDATE_EVALUATORS') && (
          <EvaluatorEditor
            assignment={assignment}
            onSave={refreshPage}
            className={className}
          />
        )}
      {assignment.finished &&
        assignment.permissions.includes('RESET_EVALUATION') && (
          <Tooltip title="Opdracht heropenen">
            <IconButton
              size="small"
              onClick={() => handleOpenResetEvaluationDialog(assignment)}
              className={className}
            >
              <FontAwesomeIcon icon={['fal', 'undo']} />
            </IconButton>
          </Tooltip>
        )}
    </>
  );

  useEffect(() => {
    repository.getOptions().then((response) =>
      setValuationOptions(
        response.data
          .sort((a, b) => a.label.localeCompare(b.label))
          .map((option) => ({
            value: option.label,
            label: option.label,
          })),
      ),
    );
  }, [repository]);

  const initialFilters: Filters = useMemo(() => {
    const result: Filters = {
      status: [
        {
          label: AssignmentStates.in_review,
          value: 'in_review',
        } as FilterColumnOption,
      ],
    };

    if (
      roleViewManager.isEducatorView() &&
      roleViewManager.hasPermission('UPDATE_EVALUATORS')
    ) {
      result.evaluator = [
        {
          label: account.fullName,
          value: account.id,
        } as FilterColumnOption,
      ];
    }

    return result;
  }, [roleViewManager, account]);

  if (!valuationOptions) {
    return <Loader />;
  }

  return (
    <div>
      <TextMedia
        name="Beoordelingen"
        description="Beoordelen van ingeleverde opdrachten of zoeken met behulp van de filters.<br>Alles gemakkelijk binnen handbereik."
        descriptionSpacing={0}
      />
      <Container>
        <SearchContext.Provider value={searchContextValue}>
          <Box display="flex" justifyContent="flex-end" mb={2}>
            <SearchInput
              id="evaluations"
              placeholder="Zoek een opdracht..."
              persistQuery
            />
          </Box>

          <Dialog
            open={resetDialogState.open}
            onClose={handleCloseResetEvaluationDialog}
            aria-describedby="reset-evaluation-dialog-description"
          >
            <DialogContent>
              <DialogContentText id="reset-evaluation-dialog-description">
                Weet je zeker dat je de opdracht wilt heropenen?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={handleCloseResetEvaluationDialog}
                color="default"
                autoFocus
              >
                Annuleren
              </Button>
              <Button onClick={handleResetEvaluation} color="secondary">
                Heropenen
              </Button>
            </DialogActions>
          </Dialog>

          <DataTable
            id="evaluation-overview"
            repository={repository}
            columns={columns}
            defaultFilters={{
              query: '',
              order: [{ field: 'status', order: 'asc' }],
              filters: initialFilters,
            }}
            onFiltersChange={handleFiltersChange}
            actions={itemActions}
            persistFilters
          />
        </SearchContext.Provider>
      </Container>
    </div>
  );
};

export default EvaluationOverview;
