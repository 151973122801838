import React, { useCallback, useContext, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { makeStyles } from '@material-ui/styles';
import { IconButton, Tooltip } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

import NewsRepository from './news/NewsRepository';
import AppContext from '../../AppContext';
import { RoleInterface } from '../users/Roles';
import { ROUTE_INFO } from './index';
import { colors } from '../../config/theme';
import StyledBadge from '../../components/StyledBadge';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
  },
  button: {
    color: colors.primary.blue,
  },
}));

const InfoBell = () => {
  const classes = useStyles();
  const history = useHistory();
  const [totalCount, setTotalCount] = useState<number>(0);
  const { roleViewManager } = useContext(AppContext);
  const privilegedUsers: (keyof RoleInterface)[] = [
    'ROLE_ADMIN',
    'ROLE_PROGRAM_ASSISTANT',
  ];

  /**
   * Determines if the user has unread news.
   */
  const checkUnreadNews = useCallback(() => {
    new NewsRepository()
      .getUnreadNews()
      .then((response) => {
        setTotalCount(response.data.totalItems);
      })
      .catch(() => {});
  }, []);

  /**
   * On load, fetch unread news for count.
   */
  useEffect(() => {
    checkUnreadNews();
    const checkInterval = setInterval(checkUnreadNews, 5 * 60 * 1000);

    return () => clearInterval(checkInterval);
  }, [checkUnreadNews]);

  const iconButton = (
    <Tooltip title="Nieuws">
      <IconButton
        size="small"
        className={classes.button}
        onClick={() => history.push(ROUTE_INFO)}
      >
        <FontAwesomeIcon icon="info" />
      </IconButton>
    </Tooltip>
  );

  return (
    <div className={classes.root}>
      {roleViewManager.hasRole(privilegedUsers) ? (
        iconButton
      ) : (
        <StyledBadge badgeContent={totalCount} color="secondary">
          {iconButton}
        </StyledBadge>
      )}
    </div>
  );
};

export default InfoBell;
