import React, { useContext, useEffect, useMemo, useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  IconButton,
  InputLabel,
  ListItemIcon,
  MenuItem,
  Select,
  TextField,
  Theme,
  Typography,
} from '@material-ui/core';
import validator from 'validator';
import { makeStyles } from '@material-ui/styles';
import { SortableHandle } from 'react-sortable-hoc';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { grey } from '@material-ui/core/colors';

import fields from './fields';
import Roles, { RoleInterface } from '../users/Roles';
import SafeHtml from '../../components/SafeHtml';
import HtmlEditor from '../../components/HtmlEditor';
import AppContext from '../../AppContext';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  details: {
    flexWrap: 'wrap',
    background: theme.palette.common.white,
  },
  tabsContainer: {
    width: '100%',
  },
  fieldSettings: {
    width: '100%',
  },
  fieldSetting: {
    width: '100%',
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
  summary: {
    padding: 0,
    margin: 0,
    display: 'flex',
    background: theme.palette.common.white,
    transition: theme.transitions.create(['color', 'background']),
    '& .MuiAccordionSummary-content': {
      position: 'relative',
      maxWidth: '100%',
      overflowX: 'auto',
      flexWrap: 'wrap',
      margin: 0,
    },
  },
  summaryContent: {
    padding: theme.spacing(2),
    paddingRight: 80,
    width: '100%',
  },
  summaryHeader: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    padding: `16px ${theme.spacing(2)}px`,
    background: grey[100],
    '& .MuiFormLabel-root': {
      margin: 0,
    },
  },
  summaryActive: {
    '& $summaryHeader': {
      background: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
    '& $deleteButton': {
      color: theme.palette.primary.contrastText,
    },
    '& .drag-handle': {
      color: theme.palette.primary.contrastText,
    },
  },
  dragHandle: {
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    top: theme.spacing(2),
    right: theme.spacing(2),
    width: 28,
    height: 28,
    borderRadius: '50%',
    transition: theme.transitions.create('all'),
  },
  typeLabel: {
    display: 'inline-flex',
  },
  deleteButton: {
    position: 'absolute',
    top: 17,
    right: theme.spacing(2) + 35,
  },
}));

interface FormFieldProps {
  id: string;
  label: string;
  description: string;
  type: string;
  options: { id: string; label: string; order: number }[];
  metadata: { [key: string]: any };
  roles: (keyof RoleInterface)[];
  required: boolean;
  // whether the field is expanded by default when someone starts filling out the form
  defaultExpanded: boolean;
  // whether the field is initially expanded in this form builder
  initialExpanded?: boolean;
  onChange: (config: any) => void;
  onDelete: () => void;
}

export interface FormFieldInterface {
  id: string;
  label: string;
  description: string;
  metadata: { [key: string]: any };
  type: string;
  required: boolean;
  defaultExpanded: boolean;
  options?: { id: string; label: string; order: number }[];
  roles?: (keyof RoleInterface)[];
}

const FormField = (props: FormFieldProps) => {
  const classes = useStyles();
  const { onChange, onDelete } = props;
  const [state, setState] = useState<FormFieldInterface>({
    id: props.id,
    label: props.label,
    description: props.description,
    metadata: props.metadata,
    type: props.type,
    required: props.required,
    defaultExpanded: props.defaultExpanded,
    options: props.options,
    roles: props.roles || ['ROLE_PARTICIPANT'],
  });
  const { roleViewManager } = useContext(AppContext);
  const [expanded, setExpanded] = useState<boolean>(
    props.initialExpanded || false,
  );
  const [preview, setPreview] = useState<React.ReactNode | null>(null);

  const { label, description, type, required, defaultExpanded, roles } = state;

  const field = useMemo(() => fields.find((f) => f.type === type), [type]);

  // If the ID is a valid UUID string it already exists in the database.
  const fieldExists = validator.isUUID(state.id, 4);

  const handleChangeState = (
    event: React.ChangeEvent<{ name?: string; value: unknown }>,
  ) => {
    const { name, value } = event.target;

    setState({ ...state, [name!]: value });
  };

  const handleDescriptionChange = (event: any, editor: any) => {
    setState({ ...state, description: editor.getData() });
  };

  const handleRequiredChange = (
    event: React.ChangeEvent<{ checked: boolean }>,
  ) => {
    setState({ ...state, required: event.target.checked });
  };

  const handleDefaultExpandedChange = (
    event: React.ChangeEvent<{ checked: boolean }>,
  ) => {
    setState({ ...state, defaultExpanded: event.target.checked });
  };

  const handleRoleChange = (
    event: React.ChangeEvent<{ checked: boolean; name: string }>,
  ) => {
    if (!roles) {
      return;
    }

    let newRoles = [...roles];

    if (event.target.checked) {
      newRoles.push(event.target.name as keyof RoleInterface);
    } else {
      newRoles = newRoles.filter((role) => role !== event.target.name);
    }

    setState({ ...state, roles: newRoles });
  };

  const DragHandle = SortableHandle(() => (
    <div className={`${classes.dragHandle} drag-handle`}>
      <FontAwesomeIcon icon={['fal', 'grip-vertical']} />
    </div>
  ));

  useEffect(() => {
    onChange(state);
  }, [state]);

  return (
    <Accordion
      expanded={expanded}
      onChange={(event, newExpanded) => setExpanded(newExpanded)}
    >
      <AccordionSummary
        className={`${classes.summary} ${
          expanded ? classes.summaryActive : ''
        }`}
      >
        <div className={classes.summaryHeader}>
          {field && field.icon && (
            <Box mr={2}>
              <FontAwesomeIcon icon={['fal', field.icon]} />
            </Box>
          )}
          <div>
            {state.label}
            {state.required && '*'}
          </div>
        </div>
        {field && (field.hasDescription || preview) && (
          <div className={classes.summaryContent}>
            {(field.hasDescription !== undefined ? field.hasLabel : true) && (
              <SafeHtml html={description} />
            )}
            {preview}
          </div>
        )}
        {roleViewManager.hasPermission('SAVE_FORM') && (
          <>
            <DragHandle />
            <IconButton
              aria-label="toggle password visibility"
              onClick={onDelete}
              className={classes.deleteButton}
              size="small"
            >
              <FontAwesomeIcon icon={['fal', 'trash']} />
            </IconButton>
          </>
        )}
      </AccordionSummary>
      <AccordionDetails className={classes.details}>
        <div className={classes.fieldSettings}>
          <TextField
            label="Label"
            name="label"
            value={label}
            onChange={handleChangeState}
            className={classes.fieldSetting}
          />

          {!fieldExists && (
            <FormControl component="fieldset" className={classes.fieldSetting}>
              <InputLabel id="form-field-type-label">Type</InputLabel>
              <Select
                labelId="form-field-type-label"
                id="form-field-type"
                value={type}
                name="type"
                onChange={handleChangeState}
              >
                {fields.map((field) => (
                  <MenuItem key={`select-${field.type}`} value={field.type}>
                    <ListItemIcon>
                      <FontAwesomeIcon icon={['fal', field.icon]} />
                    </ListItemIcon>
                    <Typography variant="body1" className={classes.typeLabel}>
                      {field.label}
                    </Typography>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}

          <div className={classes.fieldSetting}>
            <HtmlEditor
              data={props.description}
              onChange={handleDescriptionChange}
            />
          </div>

          <FormControlLabel
            className={classes.fieldSetting}
            control={
              <Checkbox checked={required} onChange={handleRequiredChange} />
            }
            label="Verplicht"
          />

          <FormControlLabel
            className={classes.fieldSetting}
            control={
              <Checkbox
                checked={defaultExpanded}
                onChange={handleDefaultExpandedChange}
              />
            }
            label="Opengeklapt laden"
          />

          <FormControl component="fieldset">
            <FormLabel component="legend">Rollen</FormLabel>
            <FormGroup row>
              {Object.entries(Roles).map(([key, label]) => {
                const roleControl = (
                  <Checkbox
                    name={key}
                    checked={
                      roles && roles.includes(key as keyof RoleInterface)
                    }
                    onChange={handleRoleChange}
                  />
                );

                return <FormControlLabel control={roleControl} label={label} />;
              })}
            </FormGroup>
          </FormControl>
        </div>
        <div className={classes.fieldSetting}>
          {fields.map((field) => {
            if (type !== field.type || !field.editor) {
              return null;
            }

            const Editor = field.editor;

            return (
              <Editor
                key={`editor-${field.type}`}
                field={state}
                onChange={onChange}
                setPreview={setPreview}
              />
            );
          })}
        </div>
      </AccordionDetails>
    </Accordion>
  );
};

export default FormField;
