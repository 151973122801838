import React, { useState } from 'react';
import {
  IconButton,
  Input,
  InputLabel,
  Theme,
  Tooltip,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useSnackbar } from 'notistack';
import { grey } from '@material-ui/core/colors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch, useSelector } from 'react-redux';
import ApiClient from '../../api/ApiClient';
import { CompleteUser } from '../../types';
import { updateAvatar } from '../../actions';
import ConfirmationDialog from '../../components/ConfirmationDialog';

const useStyles = makeStyles((theme: Theme) => ({
  avatarContainer: {
    position: 'relative',
    width: '100px',
    height: '100px',
    marginRight: theme.spacing(3),
  },
  avatar: {
    position: 'absolute',
    overflow: 'hidden',
    borderRadius: '50%',
    width: '100%',
    height: '100%',
    background: grey[100],
    '&:hover > .avatar-overlay': {
      opacity: 1,
    },
  },
  avatarOverlay: {
    position: 'absolute',
    display: 'flex',
    zIndex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    opacity: 0,
    width: '100%',
    height: '100%',
    background: 'rgba(255, 255, 255, 0.75)',
    transition: `all ${theme.transitions.duration.standard}ms ${theme.transitions.easing.easeInOut}`,
  },
  avatarUpload: {
    position: 'absolute',
    visibility: 'hidden',
  },
  avatarUploadLabel: {
    position: 'absolute',
    zIndex: 2,
    width: '100%',
    height: '100%',
    opacity: 0,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  avatarImage: {
    position: 'absolute',
    display: 'flex',
    zIndex: 0,
    overflow: 'hidden',
    width: '100%',
    height: '100%',
    borderRadius: '50%',
    '& > img': {
      objectFit: 'cover',
      objectPosition: 'center',
      width: '100%',
      height: '100%',
    },
  },
  deleteButton: {
    position: 'absolute',
    zIndex: 10,
    overflow: 'hidden',
    marginLeft: `calc(100px - ${theme.spacing(4)}px)`,
    width: theme.spacing(4),
    height: theme.spacing(4),
    lineHeight: `${theme.spacing(4)}px`,
    borderRadius: '50%',
    background: 'white',
    color: grey[600],
    textAlign: 'center',
    '&:hover': {
      background: theme.palette.secondary.main,
      color: 'white',
    },
  },
}));

interface AvatarFormProps {
  user: CompleteUser;
}

const AvatarForm = (props: AvatarFormProps) => {
  const { user } = props;
  const [avatarUrl, setAvatarUrl] = useState<string | null | undefined>(
    user ? user.avatar : null,
  );

  const account: CompleteUser = useSelector(
    (selector: { user: { account: CompleteUser } }) => selector.user.account,
  );

  const [dialogOpen, setDialogOpen] = useState<boolean>(false);

  const notifications = useSnackbar();
  const classes = useStyles();
  const dispatch = useDispatch();

  const handleAvatarUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files!.length === 0) {
      return;
    }

    const data = new FormData();
    data.append('file', e.target.files![0]);
    ApiClient.post(`/api/users/${user.id}/avatar`, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
      .then((response) => {
        setAvatarUrl(response.data.url);

        if (user.id === account.id) {
          dispatch(updateAvatar(response.data.url));
        }
      })
      .catch(() => {
        notifications.enqueueSnackbar('Wijzigen profielfoto mislukt!', {
          variant: 'error',
        });
      });
  };

  const handleDialogOpen = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleRemoveConfirmation = () => {
    ApiClient.delete(`/api/users/${user.id}/avatar`)
      .then(() => {
        setAvatarUrl(null);

        if (user.id === account.id) {
          dispatch(updateAvatar(null));
        }
      })
      .catch(() => {
        notifications.enqueueSnackbar('Verwijderen profielfoto mislukt!', {
          variant: 'error',
        });
      });

    handleDialogClose();
  };

  return (
    <div className={classes.avatarContainer}>
      <div className={classes.avatar}>
        <InputLabel
          htmlFor="avatar-upload"
          className={classes.avatarUploadLabel}
        >
          Upload foto
        </InputLabel>
        <Input
          id="avatar-upload"
          type="file"
          className={classes.avatarUpload}
          onChange={handleAvatarUpload}
        />
        <div className={['avatar-overlay', classes.avatarOverlay].join(' ')}>
          <FontAwesomeIcon
            icon={['fal', 'camera']}
            style={{ width: '40px', height: '40px', opacity: 0.5 }}
          />
        </div>
        {avatarUrl ? (
          <div className={classes.avatarImage}>
            <img src={avatarUrl} alt="" />
          </div>
        ) : (
          <FontAwesomeIcon
            icon={['fal', 'user-circle']}
            style={{ width: '100px', height: '100px', opacity: 0.05 }}
          />
        )}
      </div>

      {avatarUrl && (
        <>
          <Tooltip title="Verwijderen">
            <IconButton
              size="small"
              onClick={handleDialogOpen}
              className={classes.deleteButton}
            >
              <FontAwesomeIcon icon={['fal', 'trash']} />
            </IconButton>
          </Tooltip>
          <ConfirmationDialog
            title="Profielfoto verwijderen"
            query="Weet je zeker dat je de profielfoto wilt verwijderen?"
            isOpen={dialogOpen}
            onClose={handleDialogClose}
            onConfirm={handleRemoveConfirmation}
          />
        </>
      )}
    </div>
  );
};

export default AvatarForm;
