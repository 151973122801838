import React, { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import {
  Box,
  Container,
  Grid,
  Tab,
  Tabs,
  Theme,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { grey } from '@material-ui/core/colors';
import UserRepository from './UserRepository';
import { CompleteUser } from '../../types';
import CompleteUserForm from './CompleteUserForm';
import TabPanel from '../../components/TabPanel';
import settings from '../../config/settings';
import ChangePasswordForm from './ChangePasswordForm';
import UserRolesForm from './UserRolesForm';
import AvatarForm from './AvatarForm';
import TwoFactorConfiguration from './TwoFactorConfiguration';

const useStyles = makeStyles((theme: Theme) => ({
  header: {
    position: 'relative',
    paddingTop: settings.header.height + theme.spacing(3),
    paddingBottom: 80,
    width: '100%',
    background: theme.palette.common.white,
  },
  headerInner: {
    display: 'flex',
    alignItems: 'center',
  },
  content: {
    position: 'relative',
    marginTop: -48,
  },
  tab: {
    marginRight: theme.spacing(),
    background: grey[300],
    transition: 'all 0.3s ease',
    '&[aria-selected="true"]': {
      background: theme.palette.common.white,
      boxShadow: '1px 1px 3px 1px rgba(0, 0, 0, 0.09)',
    },
    textTransform: 'none',
    fontWeight: 500,
  },
  tabpanel: {
    boxShadow: '1px 1px 3px 1px rgba(0, 0, 0, 0.09)',
  },
  container: {
    marginTop: theme.spacing(2),
  },
  section: {
    marginBottom: theme.spacing(6),
  },
  sectionHeading: {
    marginBottom: theme.spacing(3),
  },
}));

const EditUser = () => {
  const repository = new UserRepository();
  const notifications = useSnackbar();
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const [user, setUser] = useState<CompleteUser | null>(null);
  const classes = useStyles();

  useEffect(() => {
    repository
      .getCompleteUser(id)
      .then((res) => setUser(res.data))
      .catch(() => history.push('/gebruikers'));
  }, []);

  const submit = (e: FormEvent, user: CompleteUser) => {
    e.preventDefault();

    if (!user) {
      return;
    }

    repository
      .updateCompleteUser(user)
      .then(() => {
        history.push('/gebruikers');
        notifications.enqueueSnackbar('Gebruiker is succesvol gewijzigd!', {
          variant: 'success',
        });
      })
      .catch(() =>
        notifications.enqueueSnackbar(
          'Er is iets fout gegaan bij het wijzigen van de gebruiker!',
          { variant: 'error' },
        ),
      );
  };

  const [tab, setTab] = useState<number>(0);

  const changeTab = (event: ChangeEvent<{}>, newValue: number) => {
    setTab(newValue);
  };

  return (
    <div>
      <div className={classes.header}>
        {user && (
          <Container className={classes.headerInner}>
            <>
              <AvatarForm user={user} />
              <Typography component="h1" variant="h6">
                {user.fullName}
              </Typography>
            </>
          </Container>
        )}
      </div>

      <Container className={classes.content}>
        <Tabs value={tab} onChange={changeTab} aria-label="profile tabs">
          <Tab
            label="Profiel"
            id="profile-tab-0"
            aria-controls="profile-tabpanel-0"
            className={classes.tab}
          />
          <Tab
            label="Instellingen"
            id="profile-tab-1"
            aria-controls="profile-tabpanel-1"
            className={classes.tab}
          />
        </Tabs>

        <TabPanel value={tab} index={0} className={classes.tabpanel}>
          {user && <CompleteUserForm user={user} submit={submit} />}
        </TabPanel>
        <TabPanel value={tab} index={1} className={classes.tabpanel}>
          <Grid container className={classes.container} spacing={4}>
            <Grid item container xs={12} md={6}>
              <Grid item xs={12} className={classes.section}>
                <Typography
                  component="h2"
                  variant="h6"
                  className={classes.sectionHeading}
                >
                  Meervoudige authenticatie
                </Typography>
                {user && (
                  <Box mb={4}>
                    <TwoFactorConfiguration user={user} context="profile" />
                  </Box>
                )}
              </Grid>
              <Grid item xs={12} className={classes.section}>
                <Typography
                  component="h2"
                  variant="h6"
                  className={classes.sectionHeading}
                >
                  Wachtwoord wijzigen
                </Typography>
                {user && <ChangePasswordForm user={user} />}
              </Grid>

              <Grid item xs={12} className={classes.section}>
                <Typography
                  component="h2"
                  variant="h6"
                  className={classes.sectionHeading}
                >
                  Rollen
                </Typography>
                {user && <UserRolesForm user={user} />}
              </Grid>
            </Grid>
          </Grid>
        </TabPanel>
      </Container>
    </div>
  );
};

export default EditUser;
