import React, { ChangeEvent, FormEvent, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  Box,
  Button,
  FormLabel,
  Grid,
  TextField,
  Theme,
} from '@material-ui/core';
import validator from 'validator';
import { useSnackbar } from 'notistack';
import { makeStyles } from '@material-ui/styles';
import { Alert } from '@material-ui/lab';

import UserAuthenticator from './api/UserAuthenticator';
import Home from './Home';
import PasswordInput from './components/password/PasswordInput';
import ApiClient from './api/ApiClient';
import { UserState } from './reducers/user';

type LoginErrorCode = 'inactive_user' | '2fa_limit_exceeded' | '2fa_failed';

type LoginState = {
  email: string;
  password: string;
  errors: {
    email?: boolean | undefined;
    password?: boolean | undefined;
    twoFactorCode?: boolean | undefined;
  };
  errorCode: LoginErrorCode | null;
  errorMessage: string;
  twoFactorCode: string;
};

export const useStyles = makeStyles((theme: Theme) => ({
  form: {
    overflow: 'hidden',
    width: '100%',
    maxWidth: 375,
    minWidth: 375,
    borderRadius: 4,
    padding: theme.spacing(3),
    boxShadow: '1px 1px 2px 1px rgba(0, 0, 0, 0.09)',
    background: theme.palette.common.white,
  },
  formTitle: {
    display: 'flex',
    alignItems: 'center',
    height: 30,
    margin: -theme.spacing(3),
    marginBottom: theme.spacing(2),
    padding: `${theme.spacing(4)}px ${theme.spacing(3)}px`,
    fontWeight: 600,
    color: theme.palette.primary.contrastText,
    background: theme.palette.primary.main,
  },
  error: {
    marginBottom: theme.spacing(2),
  },
  col1: {
    textAlign: 'left',
  },
  col2: {
    textAlign: 'right',
  },
}));

const Login = () => {
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();
  const notifications = useSnackbar();
  const { twoFactorMethod, requestTwoFactorCode, twoFactorEmail } = useSelector(
    (selector: { user: UserState }) => selector.user,
  );
  const [state, setState] = useState<LoginState>({
    email: '',
    password: '',
    errors: {},
    errorCode: null,
    errorMessage: '',
    twoFactorCode: '',
  });

  const getErrorCode = (err: any): LoginErrorCode => {
    return err.response !== undefined &&
      ['inactive_user', '2fa_limit_exceeded', '2fa_failed'].includes(
        err.response.data.error,
      )
      ? err.response.data.error
      : null;
  };

  const getErrorMessage = (
    errorCode: LoginErrorCode,
    attemptsLeft?: number,
  ): string => {
    if (errorCode === 'inactive_user') {
      return 'Je account is nog niet geactiveerd.';
    }
    if (attemptsLeft !== undefined) {
      if (
        errorCode === '2fa_limit_exceeded' ||
        (errorCode === '2fa_failed' && attemptsLeft === 0)
      ) {
        return 'Je hebt teveel foute pogingen gedaan. Probeer het over enkele minuten nog eens.';
      }
      if (errorCode === '2fa_failed' && attemptsLeft >= 1) {
        return `Je hebt nog ${attemptsLeft} poging${
          attemptsLeft >= 2 ? 'en' : ''
        } over.`;
      }
    }

    return '';
  };

  const handleFailure = (err: any) => {
    const errorCode = getErrorCode(err);

    setState({
      ...state,
      errors: {
        ...state.errors,
        email: true,
        password: true,
      },
      errorCode,
      errorMessage: getErrorMessage(errorCode),
    });
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const authenticator = new UserAuthenticator(dispatch);

    if (requestTwoFactorCode) {
      authenticator.checkTwoFactorCode(state.twoFactorCode, (err: any) => {
        const errorCode = getErrorCode(err);

        let attemptsLeft = null;
        if (
          errorCode === '2fa_failed' &&
          err.response !== undefined &&
          err.response.data.remaining !== undefined
        ) {
          attemptsLeft = err.response.data.remaining;
        }

        setState({
          ...state,
          errors: {
            ...state.errors,
            twoFactorCode: true,
          },
          errorCode,
          errorMessage: getErrorMessage(errorCode, attemptsLeft),
        });
      });

      return;
    }

    const emailIsValid = validator.isEmail(state.email);
    const passwordIsValid = !validator.isEmpty(state.password);

    setState({
      ...state,
      errors: {
        ...state.errors,
        email: !emailIsValid,
        password: !passwordIsValid,
      },
    });

    if (!emailIsValid || !passwordIsValid) {
      return;
    }

    authenticator.login(state.email, state.password, handleFailure);
  };

  const changeEmail = (e: ChangeEvent<HTMLInputElement>) => {
    setState({ ...state, email: e.target.value });
  };

  const changePassword = (e: ChangeEvent<HTMLInputElement>) => {
    setState({ ...state, password: e.target.value });
  };

  const changeTwoFactorCode = (e: ChangeEvent<HTMLInputElement>) => {
    setState({ ...state, twoFactorCode: e.target.value });
  };

  const handleCancelTwoFactorRequest = () => {
    new UserAuthenticator(dispatch).logout();
  };

  const resetTwoFactorMethodRequest = (e: React.MouseEvent) => {
    e.preventDefault();
    ApiClient.resetTwoFactorMethodRequest()
      .then(() => {
        handleCancelTwoFactorRequest();
        notifications.enqueueSnackbar(
          'De mail voor het wijzigen of opnieuw instellen van de verificatiemethode is verzonden.',
          { variant: 'success' },
        );
      })
      .catch(() => {
        notifications.enqueueSnackbar(
          'Er is iets fout gegaan bij het versturen van de mail voor het wijzigen of opnieuw instellen van de verificatiemethode.',
          { variant: 'error' },
        );
      });
  };

  const resendActivationMail = (e: React.MouseEvent) => {
    e.preventDefault();
    ApiClient.sendActivationMail(state.email)
      .then(() => {
        notifications.enqueueSnackbar(
          'De activatie mail is opnieuw verzonden.',
          { variant: 'success' },
        );
      })
      .catch(() => {
        notifications.enqueueSnackbar(
          'Er is iets fout gegaan bij het versturen van de activate mail.',
          { variant: 'error' },
        );
      });
  };

  return (
    <Home title="Inloggen">
      <form onSubmit={handleSubmit}>
        {state.errorCode !== null && (
          <Alert severity="error" className={classes.error}>
            {state.errorMessage}
            {state.errorCode === 'inactive_user' && (
              <Button onClick={resendActivationMail}>Opnieuw versturen.</Button>
            )}
          </Alert>
        )}
        {requestTwoFactorCode && (
          <>
            <Box mb={3}>
              {twoFactorMethod === 'email' && (
                <Box mb={3}>
                  <Alert variant="outlined" color="info">
                    {`Er is een verificatiecode gestuurd naar ${twoFactorEmail}.`}
                  </Alert>
                </Box>
              )}
              {twoFactorMethod === 'authenticator' && (
                <Box mb={3}>
                  <Alert variant="outlined" color="info">
                    De verificatiecode staat in de authenticator app.
                  </Alert>
                </Box>
              )}
              <FormLabel>Voer hier de verificatiecode in:</FormLabel>
              <TextField
                type="text"
                value={state.twoFactorCode || ''}
                onChange={changeTwoFactorCode}
                error={state.errors.twoFactorCode}
                fullWidth
              />
            </Box>
            <Box mb={2}>
              <Button
                variant="text"
                size="small"
                onClick={resetTwoFactorMethodRequest}
              >
                Verificatiemethode wijzigen of opnieuw instellen?
              </Button>
            </Box>
          </>
        )}
        {!requestTwoFactorCode && (
          <>
            <TextField
              type="email"
              data-testid="email"
              value={state.email || ''}
              onChange={changeEmail}
              error={state.errors.email}
              label="Gebruikersnaam"
              fullWidth
            />

            <Box mt={2} mb={3}>
              <PasswordInput
                id="password"
                data-testid="password"
                onChange={changePassword}
                error={state.errors.password}
              />
            </Box>
            <Box mb={2}>
              <Button
                variant="text"
                size="small"
                onClick={() => history.push('/wachtwoord-vergeten')}
              >
                Wachtwoord vergeten?
              </Button>
            </Box>
          </>
        )}
        <Grid container spacing={2}>
          <Grid item xs={6} className={classes.col1}>
            <Button
              variant="contained"
              color="secondary"
              type="submit"
              data-testid="submit"
            >
              Inloggen
            </Button>
          </Grid>
          {requestTwoFactorCode ? (
            <Grid item xs={6} className={classes.col2}>
              <Button variant="text" onClick={handleCancelTwoFactorRequest}>
                Annuleren
              </Button>
            </Grid>
          ) : (
            <Grid item xs={6} className={classes.col2}>
              <Button
                variant="contained"
                color="primary"
                onClick={(e: React.MouseEvent<HTMLElement>) => {
                  e.preventDefault();
                  window.location.href =
                    'https://www.nspoh.nl/registratie-gratis-elearnings';
                }}
              >
                Account maken
              </Button>
            </Grid>
          )}
        </Grid>
      </form>
    </Home>
  );
};

export default Login;
