import React, { useRef, useState } from 'react';
import { Calendar as BigCalendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core';
import { ScheduleItem } from '../../types';

import '@fortawesome/fontawesome-pro/css/light.css';
import './react-big-calender.css';
import settings from '../../config/settings';
import AgendaEvent from './components/AgendaEvent';
import EventPopover from './components/EventPopover';

const localizer = momentLocalizer(moment);

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    background: '#EFF2F9',
    '& .rbc-events-container': {
      marginRight: 0,
    },
    '& .rbc-toolbar': {
      position: 'sticky',
      zIndex: 6,
      top: settings.header.height,
      color: theme.palette.common.white,
      background: '#274f75',
      padding: theme.spacing(3),
      marginBottom: theme.spacing(0),
    },
    '& .rbc-toolbar-label': {
      fontSize: '1.3rem',
    },
    '& .rbc-btn-group': {
      background: theme.palette.common.white,
      '& button': {
        minWidth: 64,
        padding: '6px 16px',
        '&:not(:last-child)': {
          borderRight: `1px solid ${theme.palette.grey[300]}`,
        },
      },
      '& .rbc-active': {
        background: theme.palette.grey[300],
      },
    },
    '& .rbc-timeslot-group': {
      minHeight: 50,
    },
    '& .rbc-current-time-indicator': {
      background: theme.palette.secondary.main,
      '&:before': {
        background: theme.palette.secondary.main,
      },
      '&:after': {
        background: theme.palette.secondary.main,
      },
    },
    '& .rbc-event': {
      color: theme.palette.text.primary,
      background: theme.palette.common.white,
      borderColor: theme.palette.common.white,
      borderRadius: 0,
      '&.rbc-event-allday': {
        background: theme.palette.secondary.light,
      },
    },
  },
}));

type Calendar = {
  events: ScheduleItem[];
};

const Calendar = ({ events = [] }: Calendar) => {
  const classes = useStyles();
  const calendarRef = useRef<HTMLDivElement>(null);
  const [popoverState, setPopoverState] = useState<{
    event: ScheduleItem | null;
    anchorEl: Element | null;
  }>({
    event: null,
    anchorEl: null,
  });

  /**
   * Triggered whenever a user clicks on an event in the calendar.
   * @param event
   * @param e
   */
  const handleEventClick = (event: ScheduleItem, e: React.SyntheticEvent) => {
    setPopoverState({ event, anchorEl: e.currentTarget });
  };

  const handlePopoverClose = () => {
    setPopoverState({ event: null, anchorEl: null });
  };

  return (
    <div ref={calendarRef}>
      {popoverState.event && (
        <EventPopover
          event={popoverState.event}
          anchorEl={popoverState.anchorEl}
          onClose={handlePopoverClose}
        />
      )}
      <BigCalendar
        views={['week', 'work_week', 'month']}
        step={30}
        className={classes.root}
        defaultView="work_week"
        events={events}
        localizer={localizer}
        onSelectEvent={handleEventClick}
        messages={{
          today: 'Vandaag',
          next: '',
          previous: '',
          week: 'Week',
          work_week: 'Werkweek',
          month: 'Maand',
        }}
        min={new Date(0, 0, 0, 8, 0, 0)}
        max={new Date(0, 0, 0, 18, 0, 0)}
        components={{
          event: AgendaEvent as any,
        }}
      />
    </div>
  );
};

export default Calendar;
