import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import _uniqueId from 'lodash/uniqueId';
import {
  Box,
  Button,
  FormLabel,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Paper,
  TextField,
  Theme,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import arrayMove from 'array-move';

import _ from 'lodash';
import FormField from './FormField';
import EvaluationTypeSelect, { EvaluationTypes } from './EvaluationTypeSelect';
import Form from './Form';
import { Form as FormInterface, FormCategory } from './types';
import { Account, File as FileInterface } from '../../types';
import FormCategorySelect from './FormCategorySelect';
import StatusIndicator from '../../components/StatusIndicator';
import CopyId from './CopyId';
import HtmlEditor from '../../components/HtmlEditor';
import { RoleInterface } from '../users/Roles';
import FileUpload from '../../components/file/FileUpload';
import FileIcon from '../../components/file/FileIcon';
import { getFileURL } from '../../utils/common';

let updateField: (id: string, config: any) => void;
let deleteField: (id: string) => void;

export interface FormBuilderState {
  id: string;
  title: string;
  description: string | null;
  fields: FormField[];
  evaluationType: EvaluationTypes;
  evaluationOptions: string[];
  categories: FormCategory[];
  attachments: FileInterface[];
}

interface FormBuilderProps {
  onSave: (form: FormBuilderState, files: File[]) => void;
  form?: FormInterface;
  attachmentProgress?: React.ReactElement;
}

interface FormField {
  id: string;
  label: string;
  description: string;
  metadata: { [key: string]: any };
  type: string;
  roles: (keyof RoleInterface)[];
  required: boolean;
  defaultExpanded: boolean;
  initialExpanded?: boolean;
  options?: FormFieldOption[];
}

interface FormFieldOption {
  id: string;
  label: string;
  order: number;
}

const useStyles = makeStyles((theme: Theme) => ({
  titleField: {
    padding: theme.spacing(2),
    background: theme.palette.common.white,
  },
  fields: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  sidebar: {
    marginTop: 0,
    margin: theme.spacing(2),
  },
  sidebarBlock: {
    background: theme.palette.common.white,
  },
  categorySelect: {
    marginTop: 0,
    marginBottom: theme.spacing(3),
  },
  sortableField: {
    zIndex: 10,
    boxShadow: theme.shadows[2],
    '& .drag-handle': {
      color: theme.palette.secondary.contrastText,
      background: theme.palette.secondary.main,
    },
  },
  statusRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  fileUpload: {
    minWidth: 'auto',
  },
}));

const SortableItem = SortableElement(({ value }: { value: FormField }) => (
  <FormField
    id={value.id}
    label={value.label}
    type={value.type}
    description={value.description}
    options={value.options || []}
    metadata={value.metadata || {}}
    roles={value.roles || []}
    defaultExpanded={value.defaultExpanded}
    initialExpanded={value.initialExpanded}
    required={value.required}
    onChange={(config: any) => updateField(value.id, config)}
    onDelete={() => deleteField(value.id)}
  />
));

const SortableFields = SortableContainer((component: any) => (
  <div>
    {component.items.map((field: any, index: any) => (
      <SortableItem key={field.id} index={index} value={field} />
    ))}
  </div>
));

const FormBuilder = (props: FormBuilderProps) => {
  const classes = useStyles();
  const account = useSelector(
    (selector: { user: { account: Account } }) => selector.user.account,
  );
  const readOnly = !account.globalPermissions.includes('SAVE_FORM');
  const { attachmentProgress } = props;
  const [files, setFiles] = useState<File[]>([]);
  const [state, setState] = useState<FormBuilderState>({
    id: props.form ? props.form.id : '',
    title: props.form ? props.form.title : '',
    description: props.form ? props.form.description : '',
    fields: props.form ? props.form.fields : [],
    evaluationType: {
      valuation: props.form ? props.form.valuation : false,
      grade: props.form ? props.form.grade : false,
    },
    evaluationOptions: props.form ? props.form.evaluationOptions : [],
    categories: props.form ? props.form.categories : [],
    attachments: props.form ? props.form.attachments : [],
  });
  const [preview, setPreview] = useState<boolean>(false);

  const { id, title, description, fields, attachments } = state;

  updateField = _.debounce((id: string, updatedField: FormField) => {
    const index = fields.findIndex((field: FormField) => field.id === id);

    if (JSON.stringify(fields[index]) !== JSON.stringify(updatedField)) {
      fields[index] = updatedField;
      fields[index].initialExpanded = true;
      setState({ ...state, fields });
    }
  }, 300);

  deleteField = (id: string) => {
    const index = fields.findIndex((field: FormField) => field.id === id);
    fields.splice(index, 1);
    setState({ ...state, fields });
  };

  const handleChangeState = (
    event: React.ChangeEvent<{ name?: string; value: unknown }>,
  ) => {
    const { name, value } = event.target;

    setState({ ...state, [name!]: value });
  };

  const handleEvaluationTypeChange = (
    types: EvaluationTypes,
    options: string[],
  ) => {
    setState({ ...state, evaluationType: types, evaluationOptions: options });
  };

  const handleChangeDescription = _.debounce((event: any, editor: any) => {
    setState({ ...state, description: editor.getData() });
  }, 300);

  const handleCategorySelectChange = (selected: FormCategory[]) => {
    setState({ ...state, categories: selected });
  };

  const addField = () => {
    fields.push({
      id: `new-field-${_uniqueId()}`,
      label: '',
      description: '',
      metadata: {},
      roles: [],
      type: 'text',
      required: false,
      defaultExpanded: true,
      initialExpanded: true,
    });

    setState({ ...state, fields });
  };

  const onSortEnd = ({
    oldIndex,
    newIndex,
  }: {
    oldIndex: number;
    newIndex: number;
  }) => {
    setState({ ...state, fields: arrayMove(fields, oldIndex, newIndex) });
  };

  const handleSubmit = () => {
    const data = { ...state };

    data.fields = data.fields.map((f) => {
      const field = f;
      delete field.initialExpanded;

      return field;
    });

    props.onSave(data, files);
  };

  const togglePreview = () => setPreview(!preview);

  const handleUploadChange = (files: File[]) => setFiles(files);

  const deleteAttachment = (index: number) => {
    const newState = { ...state };
    newState.attachments.splice(index, 1);
    setState(newState);
  };

  const previewIcon = (
    <FontAwesomeIcon icon={['fal', preview ? 'tools' : 'eye']} />
  );

  return (
    <Grid container>
      <Grid item md={8}>
        {props.form && (
          <div style={{ display: preview || readOnly ? 'block' : 'none' }}>
            <Form
              form={props.form}
              onSubmit={() => {}}
              submitable={false}
              description
              autosave={false}
            />
          </div>
        )}

        {!readOnly && (
          <div style={{ display: preview ? 'none' : 'block' }}>
            <Paper className={classes.titleField}>
              <TextField
                label="Titel"
                name="title"
                value={title}
                onChange={handleChangeState}
                variant="outlined"
                fullWidth
              />

              {id && <CopyId id={id} />}

              <Box mt={2}>
                <FormLabel>Beschrijving</FormLabel>
                <HtmlEditor
                  data={description || ''}
                  onChange={handleChangeDescription}
                />
              </Box>
            </Paper>

            <div className={classes.fields}>
              <SortableFields
                items={fields}
                onSortEnd={onSortEnd}
                useDragHandle
                useWindowAsScrollContainer
                helperClass={classes.sortableField}
              />
            </div>

            {account && account.globalPermissions.includes('SAVE_FORM') && (
              <Button
                variant="contained"
                onClick={addField}
                className={classes.fields}
              >
                Veld toevoegen
              </Button>
            )}
          </div>
        )}
      </Grid>
      <Grid item md={4}>
        <div className={classes.sidebar}>
          <Paper className={classes.sidebarBlock}>
            <Box p={2}>
              {props.form && (
                <div className={classes.statusRow}>
                  <StatusIndicator
                    indicator={props.form.inUse ? 'green' : 'red'}
                    variant="circle"
                  />
                  {props.form.inUse && <span>Gekoppeld</span>}
                  {!props.form.inUse && <span>Niet gekoppeld</span>}
                </div>
              )}

              {props.form && props.form.entryCount !== undefined && (
                <div className={classes.statusRow}>
                  <StatusIndicator
                    indicator={props.form.entryCount ? 'orange' : 'green'}
                    variant="circle"
                  />
                  {props.form.entryCount > 0 && (
                    <span>{`Inzendingen (${props.form.entryCount})`}</span>
                  )}
                  {props.form.entryCount === 0 && <span>Geen inzendingen</span>}
                </div>
              )}

              <EvaluationTypeSelect
                onChange={handleEvaluationTypeChange}
                className={classes.fields}
                valuation={props.form ? props.form.valuation : false}
                grade={props.form ? props.form.grade : false}
                enabledOptions={props.form ? props.form.evaluationOptions : []}
                readOnly={readOnly}
              />

              <FormCategorySelect
                className={classes.categorySelect}
                initialSelected={props.form ? props.form.categories : []}
                onChange={handleCategorySelectChange}
                readOnly={readOnly}
              />

              <Grid container spacing={2}>
                {account && account.globalPermissions.includes('SAVE_FORM') && (
                  <Grid item>
                    <Button
                      variant="contained"
                      color="secondary"
                      startIcon={<FontAwesomeIcon icon={['fad', 'save']} />}
                      onClick={handleSubmit}
                    >
                      Opslaan
                    </Button>
                  </Grid>
                )}
                {!readOnly && (
                  <Grid item>
                    <Button startIcon={previewIcon} onClick={togglePreview}>
                      {preview ? 'Bouwer' : 'Voorbeeld'}
                    </Button>
                  </Grid>
                )}
              </Grid>
            </Box>
          </Paper>
          {!readOnly && (
            <Box mt={2}>
              <Paper className={classes.sidebarBlock}>
                <Box p={2}>
                  <FormLabel>Bijlagen</FormLabel>
                  {attachments.length > 0 && (
                    <List>
                      {attachments.map((attachment, i) => (
                        <ListItem>
                          <ListItemIcon>
                            <FileIcon file={attachment} />
                          </ListItemIcon>
                          <ListItemText>
                            <a
                              href={getFileURL(attachment)}
                              target="_blank"
                              rel="noreferrer noopener"
                            >
                              {attachment.name}
                            </a>
                          </ListItemText>
                          <ListItemSecondaryAction>
                            <IconButton
                              edge="end"
                              aria-label="delete"
                              onClick={() => deleteAttachment(i)}
                            >
                              <FontAwesomeIcon icon={['fal', 'times']} />
                            </IconButton>
                          </ListItemSecondaryAction>
                        </ListItem>
                      ))}
                    </List>
                  )}
                  <Box mt={1}>
                    {attachmentProgress}
                    <FileUpload
                      onChange={handleUploadChange}
                      documents
                      video
                      audio
                      images
                      className={classes.fileUpload}
                    />
                  </Box>
                </Box>
              </Paper>
            </Box>
          )}
        </div>
      </Grid>
    </Grid>
  );
};

export default FormBuilder;
