import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Theme, Tooltip, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import { colors } from '../../../config/theme';
import { EPAAssignment, EPAModule } from '../../../types';

interface ProfessionalActivityLevelsProps {
  module: EPAModule;
  levelBarContainerClassName?: string;
  levelBarClassName?: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: 'relative',
    display: 'flex',
    width: 250,
    minWidth: 250,
  },
  level: {
    '&:not(:last-child)': {
      marginRight: theme.spacing(2),
    },
  },
  levelBarContainer: {
    position: 'absolute',
    bottom: -12,
    left: 0,
    height: 4,
    width: 'calc(100% - 5px)',
    background: colors.secondary.grey,
  },
  levelBar: {
    height: 4,
    width: 0,
    background: colors.secondary.orange,
  },
  levelContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  levelName: {
    fontWeight: 500,
    fontSize: 15,
  },
  icon: {
    fontSize: 19,
    marginLeft: 5,
  },
  doingIcon: {
    color: colors.secondary.yellow,
  },
  finishedIcon: {
    color: colors.secondary.green,
  },
}));

const ProfessionalActivityLevels = (props: ProfessionalActivityLevelsProps) => {
  const classes = useStyles();
  const { module, levelBarClassName, levelBarContainerClassName } = props;

  const levels: {
    name: number;
    finished: boolean;
    filled: boolean;
    doing: boolean;
  }[] = [];

  for (let i = 1; i <= module.maxLevel; i++) {
    const finished = i <= module.currentLevel;
    let doing = false;

    module.assignments.forEach((assignment: EPAAssignment) => {
      if (
        assignment.assignedLevel === i &&
        !assignment.finished &&
        !assignment.notAssigned
      ) {
        doing = true;
      }
    });

    levels.push({
      name: i,
      finished,
      doing,
      filled: finished || doing,
    });
  }

  return (
    <div className={classes.root}>
      {levels.map((level) => {
        const content = (
          <div className={classes.levelContent}>
            <Typography variant="body1" className={classes.levelName}>
              {level.name}
            </Typography>
            <FontAwesomeIcon
              icon={[level.filled ? 'fas' : 'fal', 'flag']}
              className={`${classes.icon} ${
                level.finished ? classes.finishedIcon : ''
              } ${level.doing ? classes.doingIcon : ''}`}
            />
          </div>
        );

        return (
          <div key={level.name} className={classes.level}>
            {level.doing && !level.finished && (
              <Tooltip title="In afwachting">{content}</Tooltip>
            )}
            {level.finished && <Tooltip title="Behaald">{content}</Tooltip>}
            {!level.doing && !level.finished && content}
          </div>
        );
      })}
      <div
        className={`${classes.levelBarContainer} ${
          levelBarContainerClassName || ''
        }`}
      >
        <div
          className={`${classes.levelBar} ${levelBarClassName || ''}`}
          style={{
            width: `${(module.minLevel / module.maxLevel) * 100}%`,
          }}
        />
      </div>
    </div>
  );
};

export default ProfessionalActivityLevels;
