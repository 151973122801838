import React, { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { Box, Container, IconButton, Tooltip } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import ParticipantsRepository from './ParticipantsRepository';
import DataTable, { Column } from '../../components/DataTable';
import { ApiFilterCriteria, Education } from '../../types';
import NotesDialog from '../notes/NotesDialog';
import TextMedia from '../../components/pageheader/TextMedia';
import SearchContext from '../../components/search/SearchContext';
import SearchInput from '../../components/search/SearchInput';

interface MyParticipantsOverviewProps {
  educatorId?: string;
}

const defaultFilters: ApiFilterCriteria = {
  order: [{ field: 'status', order: 'desc' }],
  filters: {},
};

const MyParticipantsOverview = (props: MyParticipantsOverviewProps) => {
  const { educatorId } = props;
  const [educationNote, setEducationNote] = useState<Education | null>();
  const [query, setQuery] = useState<string>('');

  const repository = useMemo(
    () => new ParticipantsRepository(educatorId),
    [educatorId],
  );

  const columns: Column[] = [
    {
      name: 'Naam',
      field: 'user',
      sortable: true,
      filter: {
        type: 'autocomplete' as const,
        options: async () => {
          const response = await new ParticipantsRepository(
            educatorId,
          ).getStudents();

          return response.data.map((student) => ({
            value: student.id!,
            label: student.fullNameLastNameFirst!,
          }));
        },
      },
      render: (education: Education) => {
        if (education.permissions.includes('VIEW_EDUCATION')) {
          return (
            <Link
              to={
                educatorId
                  ? `/gebruikers/onderwijs/${education.id}`
                  : `/mijn-deelnemers/${education.id}`
              }
            >
              {education.user.fullNameLastNameFirst}
            </Link>
          );
        }

        return education.user.fullNameLastNameFirst;
      },
    },
    {
      name: 'Cohort',
      field: 'education',
      sortable: true,
      filter: {
        type: 'autocomplete' as const,
        options: async () => {
          const response = await new ParticipantsRepository(
            educatorId,
          ).getEducations();

          return response.data;
        },
      },
      render: (education: Education) => {
        if (!education.name) {
          return '-';
        }

        return <div>{education.name}</div>;
      },
    },
    {
      name: 'Startdatum',
      field: 'date',
      sortable: true,
      render: (education: Education) => {
        if (education.startDate == null) {
          return '-';
        }

        return <div>{moment(education.startDate).format('DD-MM-YYYY')}</div>;
      },
    },
    {
      name: 'Status',
      field: 'status',
      sortable: true,
      filter: {
        type: 'checkbox' as const,
        options: [
          { label: 'Definitief geplaatst', value: 'Definitief geplaatst' },
          { label: 'Onderbreking', value: 'Onderbreking' },
          { label: 'Afgerond', value: 'Afgerond' },
        ],
      },
      render: (education: Education) => <div>{education.status}</div>,
    },
  ];

  const handleOpenNotes = (education: Education) => {
    setEducationNote(education);
  };

  const handleNotesClose = () => setEducationNote(null);

  const itemActions = (education: Education, className: string) => {
    const openPresenceOverview = () =>
      window.open(
        `${process.env.REACT_APP_API_URL}/api/education/${
          education !== undefined ? education.id : null
        }/presence-overview`,
        '_blank',
      );

    const openEducationOverview = () =>
      window.open(
        `${process.env.REACT_APP_API_URL}/api/education/${
          education !== undefined ? education.id : null
        }/overview`,
        '_blank',
      );

    return education && education.permissions.includes('VIEW_EDUCATION') ? (
      <>
        <Tooltip title="Presentieoverzicht">
          <IconButton
            size="small"
            onClick={openPresenceOverview}
            className={className}
          >
            <FontAwesomeIcon icon={['fal', 'th-list']} />
          </IconButton>
        </Tooltip>
        <Tooltip title="Opleidingsoverzicht">
          <IconButton
            size="small"
            onClick={openEducationOverview}
            className={className}
          >
            <FontAwesomeIcon icon={['fal', 'cloud-download']} />
          </IconButton>
        </Tooltip>
        <Tooltip title="Notities">
          <IconButton
            size="small"
            onClick={() => handleOpenNotes(education)}
            className={className}
          >
            <FontAwesomeIcon icon={['fal', 'sticky-note']} />
          </IconButton>
        </Tooltip>
      </>
    ) : undefined;
  };

  const searchContextValue = useMemo(
    () => ({ query, setQuery }),
    [query, setQuery],
  );

  return (
    <div>
      {educationNote && (
        <NotesDialog
          education={educationNote}
          onClose={handleNotesClose}
          educationUser={false}
          open
        />
      )}
      <TextMedia
        name={educatorId ? 'Deelnemers' : 'Mijn deelnemers'}
        description="In één oogopslag een overzicht van deelnemers waar jij vanuit je rol als praktijkopleider of
        NSPOH opleider aan bent gekoppeld. Gemakkelijk inzicht in het portfolio of een notitie maken. Je regelt het hier!"
        descriptionSpacing={0}
      />
      <Container>
        <SearchContext.Provider value={searchContextValue}>
          <Box display="flex" justifyContent="flex-end" mb={2}>
            <SearchInput id="my-participants-query" persistQuery />
          </Box>
          <DataTable
            id="my-participants"
            repository={repository}
            columns={columns}
            defaultFilters={defaultFilters}
            actions={itemActions}
            persistFilters
          />
        </SearchContext.Provider>
      </Container>
    </div>
  );
};

export default MyParticipantsOverview;
