import React from 'react';
import { Box, Chip, Grid, Theme, Tooltip } from '@material-ui/core';

import { makeStyles } from '@material-ui/styles';
import { FormFieldInterface } from '../FormField';
import Roles, { educatorRoles } from '../../users/Roles';
import ConditionalWrapper from '../../../components/ConditionalWrapper';
import { colors } from '../../../config/theme';

interface FormFieldRolesProps {
  field: FormFieldInterface;
}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'inline-flex',
    width: 'auto',
  },
  gridItem: {
    display: 'inline-block',
    height: 32,
    lineHeight: '32px',
    marginRight: '8px',
  },
  chip: {
    fontSize: 13,
    color: theme.palette.primary.main,
    background: theme.palette.common.white,
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: 20,
  },
  educatorChip: {
    color: theme.palette.secondary.main,
    border: `1px solid ${theme.palette.secondary.main}`,
  },
  externalChip: {
    color: colors.secondary.green,
    border: `1px solid ${colors.secondary.green}`,
  },
}));

const FormFieldRoles = ({ field }: FormFieldRolesProps) => {
  const classes = useStyles();

  if (!field.roles) {
    return null;
  }

  return (
    <Box mb={3}>
      <Grid container className={classes.container}>
        {field.roles
          .filter((role) => role !== 'ROLE_ADMIN')
          .sort((a, b) => {
            const educatorRoleA = educatorRoles.includes(a);
            const educatorRoleB = educatorRoles.includes(b);

            if (educatorRoleA && !educatorRoleB) return 1;
            if (!educatorRoleA && educatorRoleB) return -1;
            return b.localeCompare(a);
          })
          .map((role) => {
            const chipClassNames = [classes.chip];
            if (educatorRoles.includes(role)) {
              chipClassNames.push(classes.educatorChip);
            } else if (role === 'ROLE_EXTERNAL') {
              chipClassNames.push(classes.externalChip);
            }

            return (
              <Grid item className={classes.gridItem} key={role}>
                <ConditionalWrapper
                  condition={educatorRoles.includes(role)}
                  wrapper={(children) => (
                    <Tooltip title="Beoordelaar">{children}</Tooltip>
                  )}
                >
                  <Chip
                    label={Roles[role]}
                    className={chipClassNames.join(' ')}
                  />
                </ConditionalWrapper>
              </Grid>
            );
          })}
      </Grid>
    </Box>
  );
};

export default FormFieldRoles;
