import React from 'react';
import moment from 'moment';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';

import { Assignment } from '../../types';

interface AssignmentEvaluationProps {
  assignment: Assignment;
}

const useStyles = makeStyles((theme: Theme) => ({
  evaluation: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2),
    background: grey[100],
  },
  mostRecentEvaluation: {
    background: theme.palette.common.white,
  },
}));

const AssignmentEvaluations = (props: AssignmentEvaluationProps) => {
  const classes = useStyles();
  const { evaluations } = props.assignment;

  return (
    <div>
      {evaluations
        .sort((a, b) => {
          return new Date(a.created) > new Date(b.created) ? 1 : -1;
        })
        .map((evaluation, index) => (
          <div
            className={`${classes.evaluation} ${
              index === evaluations.length - 1
                ? classes.mostRecentEvaluation
                : ''
            }`}
          >
            {`Opdracht beoordeeld op ${moment(evaluation.created).format(
              'DD-MM-YYYY HH:mm',
            )} door ${evaluation.teacher.fullName}.`}
            {evaluation.grade && (
              <div>
                <strong>Cijfer: </strong>
                {evaluation.grade}
              </div>
            )}
            {evaluation.valuation && (
              <div>
                <strong>Beoordeling: </strong>
                {evaluation.valuation}
              </div>
            )}
          </div>
        ))}
    </div>
  );
};

export default AssignmentEvaluations;
